import React, {
  useMemo,
  FunctionComponent,
  useState,
  useCallback,
  Fragment,
} from 'react';
import { SimpleSidePanel, SidePanelContentWrapper } from 'UI/Layout/Side panel';
import { Body2 } from 'UI/Elements/Typography';
import styles from './style.module.css';
import Card from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';
import { ZoneDevice, Person, Mac } from 'Consts/types';
import AlertModal from 'UI/Elements/Modals/Alerts';
import Alert from 'UI/Components/Alert';
import { BUTTON_THEMES } from 'UI/Elements/Button';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from 'State/store';
import { useDispatch } from 'react-redux';
import * as actions from 'State/actions';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import DeviceListItem from 'UI/Components/Lists/List device';
import AddEmployeeSidePanel from 'UI/Reusable/AddEmployee/AddEmployee';
import Divider from 'UI/Elements/Divider';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { getConfigurationFromDomain } from 'subDomainConfiguration';

const BELL = 'bell';

type AssignPrimaryDeviceSidePanelProps = {
  devices: ZoneDevice[] | null;
  employee: Person;
};

const AssignPrimaryDevice: FunctionComponent<
  AssignPrimaryDeviceSidePanelProps
> = ({ devices, employee }) => {
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const { closeSidepanel, setContent } = useSidepanel();
  const [unassignModalOpen, setUnAssignModalOpen] = useState<boolean>(false);
  const [noPrimaryDeviceModalOpen, setNoPrimaryDeviceModalOpen] =
    useState<boolean>(false);
  let primaryDeviceMac: Mac;
  const dispatch = useDispatch<AppDispatch>();
  const environment = getConfigurationFromDomain();

  const employeeDevices = useMemo(() => {
    return devices?.filter(
      ({ personId }: ZoneDevice) => personId === employee.id
    );
  }, [devices, employee.id]);

  const primaryDevice = useMemo(() => {
    return (
      devices?.filter((device) => device.mac === employee.primaryDevice) || null
    );
  }, [devices, employee]);

  if (primaryDevice) {
    primaryDeviceMac = primaryDevice[0]?.mac;
  }

  const handleOpenEditProfileSidePanel = useCallback(() => {
    if (!employee || !devices) {
      return;
    }
    setContent(<AddEmployeeSidePanel person={employee} />);
  }, [setContent, employee]);

  const handleNoAssignedDevicesModalShow = useCallback(() => {
    setUnAssignModalOpen(true);
  }, [employeeDevices]);

  const handleNoAssignedDevicesModalHide = useCallback(() => {
    setUnAssignModalOpen(false);
  }, []);

  const handleNoPrimaryDeviceModalShow = useCallback(() => {
    setNoPrimaryDeviceModalOpen(true);
  }, [primaryDevice]);

  const handleNoPrimaryDeviceModalHide = useCallback(() => {
    setNoPrimaryDeviceModalOpen(false);
  }, []);

  const handleSidePanelClose = () => {
    if (!employeeDevices?.length) {
      handleNoAssignedDevicesModalShow();
    } else {
      if (!primaryDevice?.length) {
        handleNoPrimaryDeviceModalShow();
      } else {
        closeSidepanel();
      }
    }
  };

  const assignDeviceAcknowledge = () => {
    setUnAssignModalOpen(false);
    closeSidepanel();
  };

  const assignPrimarydeviceAcknowledge = () => {
    setNoPrimaryDeviceModalOpen(false);
    closeSidepanel();
  };

  const assignPrimarydevice = useCallback(
    (device: ZoneDevice) => {
      if (!employee || !device) {
        return;
      }

      if (primaryDeviceMac === device.mac) {
        closeSidepanel();
        return;
      }

      dispatch(
        actions.employee.assignPrimaryDeviceToEmployee(employee.id, device.mac)
      );
      closeSidepanel();
    },
    [primaryDevice]
  );

  const checkListOnIcon =
    environment.id === BELL ? IconNames.CheckListOnBell : IconNames.CheckListOn;

  const checkListOffIcon =
    environment.id === BELL
      ? IconNames.CheckListOffBell
      : IconNames.CheckListOff;

  return (
    <SimpleSidePanel
      onClose={handleSidePanelClose}
      title={t('assignPrimeDevice.title')}
    >
      <SidePanelContentWrapper>
        {employeeDevices?.length === 0 ? (
          <Body2 className={styles.description}>
            <span>{`${t('assignPrimeDevice.emptyParagraph1')} `}</span>
            <span
              className={styles.emptyParagraph}
              onClick={handleOpenEditProfileSidePanel}
            >
              {t('employeeZone.editProfile')}
            </span>
            <span>
              {' '}
              {t('assignPrimeDevice.emptyParagraph3', {
                ns: namespace,
                name: employee.nickname,
              })}{' '}
            </span>
          </Body2>
        ) : (
          <Body2 className={styles.description}>
            {t('assignPrimeDevice.paragraph', {
              ns: namespace,
              name: employee.nickname,
            })}
          </Body2>
        )}

        <Card noBottomPadding>
          {employeeDevices?.map((device: ZoneDevice, i) => (
            <Fragment key={`${device.mac}_${i}`}>
              {i !== 0 && <Divider />}
              <DeviceListItem
                key={i}
                L1Props={{
                  mediumDeviceIconProps: {
                    fileName: device.icon,
                    size: 'small',
                  },
                  className: styles.deviceWithNoPersonIcons,
                }}
                L2Props={{
                  label: device.nickname || device.name,
                  paragraphList:
                    primaryDeviceMac === device.mac
                      ? [{ label: t('homepage.primaryDevice') }]
                      : undefined,
                }}
                RProps={{
                  iconProps: {
                    name:
                      primaryDeviceMac === device.mac
                        ? checkListOnIcon
                        : checkListOffIcon,
                    onClick: () => assignPrimarydevice(device),
                  },
                }}
                onClick={() => assignPrimarydevice(device)}
              />
            </Fragment>
          ))}
        </Card>
        <AlertModal
          isOpen={unassignModalOpen}
          onClose={handleNoAssignedDevicesModalHide}
        >
          <Alert
            topProps={{
              label: t('assignPrimeDevice.unAssignModal.label'),
              paragraph:
                t('assignPrimeDevice.unAssignModal.paragraph') ||
                'Without this you won’t be able to use all the diagnostic tools about your employee',
              className: styles.modalHeader,
            }}
            bottomProps={{
              button1Props: {
                label: t('devices.modal.assignDeviceModal.iUnderstand'),
                onClick: assignDeviceAcknowledge,
              },
              button2Props: {
                label: t('common.cancel'),
                onClick: handleNoAssignedDevicesModalHide,
                theme: BUTTON_THEMES.white,
              },
            }}
          />
        </AlertModal>
        <AlertModal
          isOpen={noPrimaryDeviceModalOpen}
          onClose={handleNoPrimaryDeviceModalHide}
        >
          <Alert
            topProps={{
              label: t('assignPrimeDevice.unAssignPrimaryDevice.label'),
              paragraph:
                t('assignPrimeDevice.unAssignPrimaryDevice.paragraph') ||
                'Without this you won’t be able to use all the diagnostic tools about your employee',
              className: styles.modalHeader,
            }}
            bottomProps={{
              button1Props: {
                label:
                  t('devices.modal.assignDeviceModal.iUnderstand') ||
                  'I understand',
                onClick: assignPrimarydeviceAcknowledge,
              },
              button2Props: {
                label: t('common.cancel'),
                onClick: handleNoPrimaryDeviceModalHide,
                theme: BUTTON_THEMES.white,
              },
            }}
          />
        </AlertModal>
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default AssignPrimaryDevice;
