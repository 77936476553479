import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'Consts/routes';

import StandardListItem from 'UI/Components/Lists/List standard';

import Icon, { IconNames } from 'UI/Elements/Icon';
import ActionSheet from 'UI/Elements/Modals/Action sheet';
import Card from 'UI/Elements/Card';

import useNavigateToSettings from 'Utils/hooks/useNavigateToSettings';

import styles from './style.module.css';
import { Nullable } from '../../../Consts/types';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import { useDispatch } from 'react-redux';
import * as actions from 'State/actions';
import { AppDispatch } from 'State/store';
import useIsMobile from 'Utils/hooks/useIsMobile';

type QuestionButtonProps = JSX.IntrinsicElements['div'];

const SUPPORT_LINK_URL = 'https://support.plume.com';

const QuestionButton: FunctionComponent<QuestionButtonProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef<Nullable<Element>>(null);
  const navigateToSettings = useNavigateToSettings();
  const isMobile = useIsMobile();

  const handleOpen = useCallback((ev: React.MouseEvent<HTMLElement>) => {
    parentRef.current = ev.currentTarget;
    setIsOpen(true);
  }, []);

  const handleHide = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSupportClick = useCallback(() => {
    navigateToSettings(ROUTES.settings.support);
    dispatch(actions.ui.page.setSidepanelOpen(false));
    handleHide();
  }, [handleHide, navigateToSettings]);

  const handleFAQClick = useCallback(() => {
    window.open(
      customerSupportConfigurations?.customerConfigurations?.faqUrl ||
        SUPPORT_LINK_URL
    );
    handleHide();
  }, [handleHide, customerSupportConfigurations]);

  return isMobile ? null : (
    <div className={className}>
      <div
        className={styles.button}
        onClick={handleOpen}
        data-testid={'questionButton'}
        role="button"
        aria-label={t('common.supportButton')}
      >
        <Icon name={IconNames.QuestionMark} />
      </div>

      <ActionSheet
        isOpen={isOpen}
        onClose={handleHide}
        parent={parentRef.current}
      >
        <Card noBottomPadding>
          {customerSupportConfigurations?.customerFeatureEnablement
            ?.faqEnabled && (
            <StandardListItem
              onClick={handleFAQClick}
              L2Props={{
                label: t('settings.faq'),
              }}
              RProps={{ icon1Props: { name: IconNames.CircleQuestionMark } }}
              ariaLabel={t('settings.faq')}
            />
          )}
          <StandardListItem
            onClick={handleSupportClick}
            L2Props={{ label: t('settings.support') }}
            RProps={{ icon1Props: { name: IconNames.CircleInfo } }}
            ariaLabel={t('settings.support')}
          />
        </Card>
      </ActionSheet>
    </div>
  );
};

export default QuestionButton;
