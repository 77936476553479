import React from 'react';
import cn from 'classnames';
import Icon, { IconName } from 'UI/Elements/Icon';
import { Body1 } from 'UI/Elements/Typography';
import styles from './style.module.css';

export type PillXLargeProps = {
  leftIconName?: IconName;
  label?: string;
  rightIconName?: IconName;
  className?: string;
  onClick?: React.MouseEventHandler;
};

const PillXLarge: React.FunctionComponent<PillXLargeProps> = ({
  leftIconName,
  label,
  rightIconName,
  className,
  onClick,
}) => {
  const componentClasses = cn(
    styles.pillLarge,
    {
      [styles.interactive]: !!onClick,
      [styles.label]: !leftIconName && label && !rightIconName,
      [styles.iconLabel]: leftIconName && label && !rightIconName,
      [styles.labelIcon]: !leftIconName && label && rightIconName,
      [styles.iconLabelIcon]: leftIconName && label && rightIconName,
      [styles.icon]: leftIconName && !label && !rightIconName,
    },
    className
  );

  if (!leftIconName && !label && !rightIconName) {
    return null;
  }

  return (
    <div className={componentClasses} onClick={onClick}>
      {!!leftIconName && <Icon name={leftIconName} />}
      {!!label && <Body1>{label}</Body1>}
      {!!rightIconName && <Icon name={rightIconName} />}
    </div>
  );
};

export default PillXLarge;
