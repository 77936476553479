export const TWO_COLUMNS_MIN_WIDTH = 752;
export const THREE_COLUMNS_MIN_WIDTH = 1120;
export const FOUR_COLUMNS_MIN_WIDTH = 1488;

export const ITEM_LIMIT_PER_CARD_COLUMN = 4;

export const SHIELD_EVENTS_CARD_LIMIT = 200;
export const SHIELD_EVENTS_PER_EVENTS_CARD_LIMIT = 3;

export const MIXPANEL_ID =
  process.env.REACT_APP_MIXPANEL_ID || '965d1f6ea58fcaa503f98e11b05de113';
