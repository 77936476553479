import subHours from 'date-fns/subHours';

import { GROUPING, NETWORK_IDS, PERIODS } from 'Consts/defintions';
import type {
  Customer,
  Location,
  CustomerId,
  Person,
  SpeedTestResults,
  FullDevice,
  DeviceGroup,
  Mac,
  NetworkType,
  NetworkAccessData,
  WifiNetwork,
  FronthaulNetwork,
  CaptivePortalNetwork,
  LoginWithPasswordResponse,
  BandwidthLimit,
  RawPod,
  AutoRun,
  GuestNetworkUsage,
  Period,
  DataUsageCategoriesDaily,
  DataUsageCategoriesMonthly,
  SecurityPolicy,
  RecursivePartial,
  Time,
  SecurityEvent,
  CloudBackend,
  Guest,
  GroupingTypes,
  TimeCategoriesDaily,
  TimeCategoriesMonthly,
  Topology,
  CustomerSupportConfiguration,
  GuestEmailCollectionInfo,
  LoginWithSSOResponse,
  DeviceSecurityPolicy,
  SSOCallbackResponse,
  OnlineTimeAppDaily,
  OnlineTimeAppMonthly,
  PubnubSubscribe,
  OnlineTimeAppYearly,
  Website,
  EntitledAccess,
  CoAdminAccessTokenResponse,
  LocationId,
  LocationAppTime,
  atWorkEvent,
  AppFacadeCustomerAndNodeList,
  EmployeeLoginData,
  Mode,
  ConfigAndStateType,
  BlockedEmployeeData,
} from 'Consts/types';

import {
  ENDPOINTS,
  Method,
  METHODS,
  callApi,
  CustomerAndLocationIds,
} from './';
import {
  GlobalAuthCustomer,
  GlobalAuthLoginOptionsData,
} from 'Consts/globalAuthTypes';
import { environments } from 'Consts/environments';

type TokenArg = { token: string };

export type CloudArg = { cloud: CloudBackend };

type TypicalArgs = TokenArg & CloudArg & CustomerAndLocationIds;

export const getCustomer = ({
  token,
  cloud,
  ...args
}: TokenArg & CloudArg & { customerId: CustomerId }) => {
  return callApi<Customer>({ url: ENDPOINTS.customers(args), token, cloud });
};

/* locations */
export const getLocations = ({
  token,
  cloud,
  ...args
}: TokenArg & CloudArg & { customerId: CustomerId }) => {
  return callApi<Location[]>({
    url: ENDPOINTS.locations(args) + '?include=summary',
    token,
    cloud,
  });
};

export const getLocation = ({
  token,
  cloud,
  ...args
}: TokenArg &
  CloudArg & { customerId: CustomerId; locationId: LocationId }) => {
  return callApi<Location>({
    url: ENDPOINTS.locations(args) + '?include=summary',
    token,
    cloud,
  });
};

/* co-Admin entitledAccess */
export const getEntitledAccess = ({
  token,
  cloud,
  ...args
}: TokenArg & CloudArg & { customerId: CustomerId }) => {
  return callApi<EntitledAccess[]>({
    url: ENDPOINTS.entitledAccess(args),
    token,
    cloud,
  });
};

export const refreshAccessTokens = ({
  cloud,
  partnerId,
  params,
}: CloudArg & {
  partnerId: string;
  params: { refreshToken: string; appId?: string };
}) => {
  return callApi<SSOCallbackResponse>({
    url: ENDPOINTS.partnerSSORefresh(partnerId, params),
    method: METHODS.POST,
    cloud,
    repoBaseUrl: 'provisioning',
  });
};

export const getTokenForCoAdminLocation = ({
  token,
  cloud,
  ...args
}: TokenArg & CloudArg & { customerId: CustomerId; accessId: string }) => {
  return callApi<CoAdminAccessTokenResponse>({
    url: ENDPOINTS.coAdminAccessTokens(args),
    method: METHODS.POST,
    token,
    cloud,
  });
};

export const renameLocation = ({
  token,
  data,
  cloud,
  ...args
}: TypicalArgs & { data: { name: string } }) => {
  return callApi<Location[]>({
    url: ENDPOINTS.locations(args),
    method: METHODS.PUT,
    data,
    token,
    cloud,
  });
};

export const createLocation = ({
  token,
  cloud,
  data,
  ...args
}: TokenArg &
  CloudArg & { customerId: CustomerId; data: { name: string } }) => {
  return callApi<Location[]>({
    url: ENDPOINTS.locations(args),
    method: METHODS.POST,
    data: { ...data, profile: 'smallbusiness' },
    token,
    cloud,
  });
};

export const getLocationAtWorkEvents = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<atWorkEvent[]>({
    url: ENDPOINTS.locationAtWorkEvents(args),
    token,
    cloud,
  });
};

/* employees */
export const getEmployees = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<Person[]>({ url: ENDPOINTS.persons(args), token, cloud });
};

export const addEmployee = ({
  token,
  cloud,
  nickname,
  imageId,
  assignedDevices,
  primaryDevice,
  ...args
}: TypicalArgs & {
  nickname: string;
  imageId: string;
  assignedDevices?: Mac[];
  primaryDevice?: Mac;
}) => {
  return callApi({
    url: ENDPOINTS.addEmployee(args),
    method: METHODS.POST,
    data: {
      nickname,
      imageId,
      assignedDevices,
      profile: { type: 'employee' },
      primaryDevice: primaryDevice,
    },
    token,
    cloud,
  });
};

export const getEmployee = ({
  token,
  cloud,
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return callApi<Person>({
    url: ENDPOINTS.persons({ personId, ...args }),
    method: METHODS.GET,
    token,
    cloud,
  });
};

export const updateEmployee = ({
  token,
  cloud,
  personId,
  newNickname,
  imageId,
  assignedDevices,
  primaryDevice,
  ...args
}: TypicalArgs & {
  personId: string;
  newNickname: string;
  imageId?: string;
  assignedDevices?: Mac[];
  primaryDevice?: Mac;
}) => {
  return callApi({
    url: ENDPOINTS.persons({ personId, ...args }),
    method: METHODS.PATCH,
    data: {
      nickname: newNickname,
      imageId: imageId,
      assignedDevices: assignedDevices,
      primaryDevice: primaryDevice,
    },
    token,
    cloud,
  });
};

export const removeEmployee = ({
  token,
  cloud,
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return callApi<void>({
    url: ENDPOINTS.persons({ personId, ...args }),
    method: METHODS.DELETE,
    data: {},
    token,
    cloud,
  });
};

export const assignDeviceToEmployee = ({
  token,
  cloud,
  personId,
  assignedDevices,
  ...args
}: TypicalArgs & { personId: string; assignedDevices: Mac[] }) => {
  return callApi<Person[]>({
    url: ENDPOINTS.persons({ personId, ...args }),
    method: METHODS.PATCH,
    data: { assignedDevices },
    token,
    cloud,
  });
};

export const assignPrimaryDeviceToEmployee = ({
  token,
  cloud,
  personId,
  primaryDevice,
  ...args
}: TypicalArgs & { personId: string; primaryDevice: Mac }) => {
  return callApi<Person[]>({
    url: ENDPOINTS.persons({ personId, ...args }),
    method: METHODS.PATCH,
    data: { primaryDevice },
    token,
    cloud,
  });
};

/* person app time*/
export const updatePersonAppTime = ({
  token,
  cloud,
  data,
  personId,
  ...args
}: TypicalArgs & {
  data: { enable: boolean };
  personId: string;
}) => {
  return callApi<{ enable: boolean; updatedAt: Time }>({
    url: ENDPOINTS.personAppTime({ personId, ...args }),
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

export const getPersonAppTime = ({
  token,
  cloud,
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return callApi<{ enable: boolean; updatedAt: Time }>({
    url: ENDPOINTS.personAppTime({ personId, ...args }),
    method: METHODS.GET,
    token,
    cloud,
  });
};

/* speed test */
export const getIspSpeedTest = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<SpeedTestResults>({
    url: ENDPOINTS.dashboard(args),
    token,
    cloud,
  });
};

export const startIspSpeedTest = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<{ requestId: string }>({
    url: ENDPOINTS.ispSpeedTest(args),
    method: METHODS.POST,
    token,
    cloud,
  });
};

export const updateIspSpeedTest = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & { data: Partial<AutoRun> }) => {
  return callApi<{ ispSpeedTestConfiguration: AutoRun }>({
    url: ENDPOINTS.ispSpeedTestConfiguration(args),
    method: METHODS.PUT,
    data,
    token,
    cloud,
  });
};

/* devices */
export const getDevices = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<{ devices: FullDevice[] }>({
    url: ENDPOINTS.devices(args),
    token,
    cloud,
  });
};

export const getDevice = ({
  token,
  cloud,
  mac,
  ...args
}: TypicalArgs & { mac: Mac }) => {
  return callApi<FullDevice>({
    url: ENDPOINTS.device({ mac, ...args }),
    method: METHODS.GET,
    token,
    cloud,
  });
};

export const updateDevice = ({
  token,
  cloud,
  mac,
  newNickname,
  ...args
}: TypicalArgs & { mac: Mac; newNickname: string }) => {
  return callApi({
    url: ENDPOINTS.device({ mac, ...args }),
    method: METHODS.PATCH,
    data: { nickname: newNickname },
    token,
    cloud,
  });
};

/* device app time*/
export const updateDeviceAppTime = ({
  token,
  cloud,
  data,
  mac,
  ...args
}: TypicalArgs & {
  data: { enable: boolean };
  mac: Mac;
}) => {
  return callApi<{ enable: boolean; updatedAt: Time }>({
    url: ENDPOINTS.deviceAppTime({ mac, ...args }),
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

/* device security policy */
export const getDeviceSecurityPolicy = ({
  mac,
  token,
  cloud,
  ...args
}: TypicalArgs & { mac: Mac }) => {
  return callApi<DeviceSecurityPolicy>({
    url: ENDPOINTS.deviceSecurityPolicy({ mac, ...args }),
    token,
    cloud,
  });
};

export const updateDeviceSecurityPolicy = ({
  token,
  cloud,
  mac,
  data,
  ...args
}: TypicalArgs & { mac: Mac; data: RecursivePartial<SecurityPolicy> }) => {
  return callApi<DeviceSecurityPolicy>({
    url: ENDPOINTS.deviceSecurityPolicy({ mac, ...args }),
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

/* person security policy */

export const updatePersonSecurityPolicy = ({
  token,
  cloud,
  personId,
  data,
  ...args
}: TypicalArgs & {
  personId: string;
  data: RecursivePartial<SecurityPolicy>;
}) => {
  return callApi<SecurityPolicy>({
    url: ENDPOINTS.employeeSecurityPolicy({ personId, ...args }),
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

export const blockDevices = ({
  token,
  cloud,
  macs,
  ...args
}: TypicalArgs & { macs: Mac[] }) => {
  return callApi({
    url: ENDPOINTS.blockDevice(args),
    method: METHODS.POST,
    data: macs,
    token,
    cloud,
  });
};

export const unquarantineDevices = ({
  token,
  cloud,
  domain,
  duration,
  mac,
  ...args
}: TypicalArgs & { mac: Mac; domain: string; duration?: number }) => {
  if (duration) {
    return callApi({
      url: ENDPOINTS.unquarantineDevice({ mac, ...args }),
      method: METHODS.POST,
      data: { fqdn: domain, ttl: duration },
      token,
      cloud,
    });
  }
  return callApi({
    url: ENDPOINTS.unquarantineDevice({ mac, ...args }),
    method: METHODS.POST,
    data: { fqdn: domain, reason: 'trust' },
    token,
    cloud,
  });
};

export const approveDevices = ({
  token,
  cloud,
  macs,
  ...args
}: TypicalArgs & { macs: Mac[]; networkId: NetworkType }) => {
  return callApi({
    url: ENDPOINTS.approveDevice(args),
    method: METHODS.POST,
    data: macs,
    token,
    cloud,
  });
};

export const getDeviceGroups = ({
  token,
  cloud,
  ...args
}: TypicalArgs & { networkId: NetworkType }) => {
  return callApi<DeviceGroup[]>({
    url: ENDPOINTS.deviceGroups(args),
    token,
    cloud,
  });
};

export const addDeviceGroup = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & {
  networkId: NetworkType;
  data: { name: string; devices?: Mac[] };
}) => {
  return callApi<DeviceGroup[]>({
    url: ENDPOINTS.deviceGroups(args),
    method: METHODS.POST,
    data,
    token,
    cloud,
  });
};

export const updateDeviceGroup = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & {
  networkId: NetworkType;
  groupId: string;
  data: { name: string; devices?: Mac[] };
}) => {
  return callApi<DeviceGroup[]>({
    url: ENDPOINTS.deviceGroups(args),
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

export const deleteDeviceGroup = ({
  token,
  cloud,
  ...args
}: TypicalArgs & { networkId: NetworkType; groupId: string }) => {
  return callApi<DeviceGroup[]>({
    url: ENDPOINTS.deviceGroups(args),
    method: METHODS.DELETE,
    token,
    cloud,
  });
};

export const updateEmployeeGroupShareAccess = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & {
  networkId: string | undefined;
  groupId: string;
  data: {
    groups: string[];
    devices?: { networkId?: NetworkType; mac?: Mac }[];
  };
}) => {
  return callApi({
    url: ENDPOINTS.employeeGroupShareAccess(args),
    method: METHODS.PUT,
    data,
    token,
    cloud,
  });
};

export const updateEmployeeShareAccess = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & {
  networkId: string;
  mac: Mac;
  data: {
    groups: string[];
    devices?: { networkId?: NetworkType; mac?: Mac }[];
  };
}) => {
  return callApi({
    url: ENDPOINTS.employeeShareAccess(args),
    method: METHODS.PUT,
    data,
    token,
    cloud,
  });
};

const getDeviceOnLineTimeCategories = <R>({
  token,
  cloud,
  period,
  mac,
  grouping,
  ...args
}: TypicalArgs & {
  period: Period;
  mac: string;
  grouping: GroupingTypes;
}) => {
  return callApi<R>({
    url:
      ENDPOINTS.device({ mac, ...args }) +
      ENDPOINTS.onlineTimeCategories() +
      '?' +
      getDataUsageParams(period, grouping),
    token,
    cloud,
  });
};

export const getDeviceDailyOnlineTimeCategories = ({
  mac,
  ...args
}: TypicalArgs & { mac: string }) => {
  return getDeviceOnLineTimeCategories<TimeCategoriesDaily>({
    period: PERIODS.day,
    grouping: GROUPING.perTimeSlot,
    mac,
    ...args,
  });
};

export const getDeviceMonthlyOnlineTimeCategories = ({
  mac,
  ...args
}: TypicalArgs & { mac: string }) => {
  return getDeviceOnLineTimeCategories<TimeCategoriesMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    mac,
    ...args,
  });
};
/* WIFI NETWORKS */

/* default network */
export const getDefaultWifiNetwork = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<{ wifiNetwork: WifiNetwork }>({
    url: ENDPOINTS.wifiNetwork(args),
    token,
    cloud,
  });
};

type WifiInfo = { encryptionKey?: string; ssid?: string };

export const updateDefaultWifiNetwork = ({
  token,
  cloud,
  encryptionKey,
  ssid,
  ...args
}: TypicalArgs & WifiInfo) => {
  const url = ENDPOINTS.wifiNetwork(args);
  return callApi<{ wifiNetwork: WifiNetwork }>({
    url,
    method: METHODS.PUT,
    data: { encryptionKey, ssid },
    token,
    cloud,
  });
};

/* employee network */
export const getEmployeeWifiNetworks = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<FronthaulNetwork[]>({
    url: ENDPOINTS.fronthauls(args),
    token,
    cloud,
  });
};

export const updateEmployeeWifiNetwork = ({
  token,
  cloud,
  encryptionKey,
  ssid,
  ...args
}: TypicalArgs & WifiInfo) => {
  const url = ENDPOINTS.fronthauls({
    networkId: NETWORK_IDS.employee,
    ...args,
  });

  return callApi<FronthaulNetwork>({
    url,
    method: METHODS.PATCH,
    data: { encryptionKey, ssid },
    token,
    cloud,
  });
};

export const createEmployeeWifiNetwork = ({
  token,
  cloud,
  locationId,
  customerId,
  ssid,
  encryptionKey,
}: TypicalArgs & { ssid: string; encryptionKey: string }) => {
  return callApi<FronthaulNetwork>({
    url: `/Customers/${customerId}/locations/${locationId}/secondaryNetworks/fronthauls`,
    method: METHODS.POST,
    data: { encryptionKey, ssid },
    token,
    cloud,
  });
};

/* guest network */
export const getGuestWifiNetworks = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<CaptivePortalNetwork[]>({
    url: ENDPOINTS.captivePortals(args),
    token,
    cloud,
  });
};

type GuestNetworkSaveData = {
  ssid?: string;
  enable?: boolean;
  bandwidthLimit?: BandwidthLimit;
};

export const updateGuestWifiNetwork = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & { data: GuestNetworkSaveData }) => {
  return callApi<CaptivePortalNetwork>({
    url: ENDPOINTS.captivePortals({ networkId: NETWORK_IDS.guest, ...args }),
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

export const createGuestWifiNetwork = ({
  token,
  cloud,
  locationId,
  customerId,
  ssid,
  enable,
}: TypicalArgs & { ssid: string; enable: boolean }) => {
  return callApi<CaptivePortalNetwork>({
    url: `/Customers/${customerId}/locations/${locationId}/secondaryNetworks/captivePortals`,
    method: METHODS.POST,
    data: { ssid, enable },
    token,
    cloud,
  });
};

export const downloadGuestDetails = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<Blob>({
    url: ENDPOINTS.downloadGuestDetails({
      networkId: NETWORK_IDS.guest,
      ...args,
    }),
    token,
    cloud,
    responseType: 'blob',
  });
};

export const enableGuestEmailCollection = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<void>({
    url: ENDPOINTS.enableGuestEmailCollection({
      networkId: NETWORK_IDS.guest,
      ...args,
    }),
    method: METHODS.POST,
    data: {},
    token,
    cloud,
  });
};

export const guestEmailCollectionInfo = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<GuestEmailCollectionInfo>({
    url: ENDPOINTS.guestEmailCollectionInfo({
      networkId: NETWORK_IDS.guest,
      ...args,
    }),
    method: METHODS.GET,
    token,
    cloud,
  });
};

/* network access */
export const getNetworkAccesses = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<NetworkAccessData[]>({
    url: ENDPOINTS.networkAccess(args),
    token,
    cloud,
  });
};

export const updateNetworkAccesses = ({
  token,
  cloud,
  purgatory,
  ...args
}: TypicalArgs & { networkId: NetworkType; purgatory: boolean }) => {
  return callApi<NetworkAccessData>({
    url: ENDPOINTS.networkAccess(args),
    method: METHODS.PATCH,
    data: { purgatory },
    token,
    cloud,
  });
};

/* login */
type LoginWithPasswordArgs = { email: string; password: string };

export const loginWithPassword = ({
  cloud,
  email,
  password,
}: CloudArg & LoginWithPasswordArgs) => {
  return callApi<LoginWithPasswordResponse>({
    url: ENDPOINTS.login(),
    method: METHODS.POST,
    data: { email, password, ttl: 31536000 },
    cloud,
  });
};

export const loginWithMagicLink = ({
  cloud,
  email,
}: CloudArg & { email: string }) => {
  return callApi<{ id: string; userId: string }>({
    url: ENDPOINTS.magicLink(),
    method: METHODS.POST,
    data: { email, notificationOptions: { profile: 'smallbusiness' } },
    cloud,
  });
};

export const loginWithSSO = ({
  cloud,
  partnerId,
  appId,
}: CloudArg & { partnerId: string; appId?: string }) => {
  return callApi<LoginWithSSOResponse>({
    url: ENDPOINTS.partnerSSOlogin(partnerId, appId),
    method: METHODS.GET,
    cloud,
    repoBaseUrl: 'provisioning',
  });
};
export const provisioningPartnersCallback = ({
  cloud,
  partnerId,
  params,
}: CloudArg & {
  partnerId: string;
  params: { code: string; state: string; appId?: string };
}) => {
  return callApi<SSOCallbackResponse>({
    url: ENDPOINTS.partnerSSOCallback(partnerId, params),
    method: METHODS.GET,
    cloud,
    repoBaseUrl: 'provisioning',
  });
};

export const checkEmailExists = ({
  cloud,
  email,
}: CloudArg & { email: string }) => {
  return callApi<{ emailVerified: boolean }>({
    url: ENDPOINTS.checkEmailExists({ email }),
    cloud,
  });
};

export const globalAuthLoginOptions = ({
  cloud,
  email,
}: CloudArg & { email: string }) => {
  return callApi<GlobalAuthLoginOptionsData>({
    url:
      environments[cloud]?.globalAuthUrl + ENDPOINTS.globalAuthLoginOptions(),
    cloud,
    method: METHODS.POST,
    data: {
      email,
    },
  });
};

export const globalAuthGetAllCustomers = ({
  cloud,
  token,
}: CloudArg & TokenArg) => {
  return callApi<GlobalAuthCustomer[]>({
    url:
      environments[cloud]?.globalAuthUrl +
      ENDPOINTS.globalAuthGetAllCustomers(),
    cloud,
    token,
  });
};

export const resetPassword = ({
  cloud,
  email,
  deepLink,
}: CloudArg & { email: string; deepLink: string }) => {
  return callApi({
    url: ENDPOINTS.resetPassword(),
    method: METHODS.POST,
    data: {
      email,
      deepLink,
      notificationOptions: { profile: 'smallbusiness' },
    },
    cloud,
  });
};

/* pods */
export const getNodes = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<AppFacadeCustomerAndNodeList>({
    url: ENDPOINTS.appFacade({ ...args, filters: ['nodes'] }),
    token,
    cloud,
  });
};

export const updatePod = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & { nodeId: string; data: Partial<RawPod> }) => {
  return callApi<RawPod>({
    url: ENDPOINTS.nodes(args),
    method: METHODS.PUT,
    data,
    token,
    cloud,
  });
};

export const updatePodEthernetLan = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & { nodeId: string; data: { default: { mode: Mode } } }) => {
  return callApi<void>({
    url: ENDPOINTS.nodesEthernetLan(args),
    method: METHODS.PUT,
    data,
    token,
    cloud,
  });
};

export const deletePod = ({
  token,
  cloud,
  ...args
}: TypicalArgs & { nodeId: string }) => {
  return callApi<Partial<RawPod>>({
    url: ENDPOINTS.nodes(args),
    method: METHODS.DELETE,
    token,
    cloud,
  });
};

/* security policy */
export const getLocationSecurityPolicy = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<SecurityPolicy>({
    url: ENDPOINTS.locationSecurityPolicy(args),
    token,
    cloud,
  });
};

export const updateLocationSecurityPolicy = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & { data: RecursivePartial<SecurityPolicy> }) => {
  return callApi<SecurityPolicy>({
    url: ENDPOINTS.locationSecurityPolicy(args),
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

export const updateLocationPolicyWhitelist = ({
  token,
  cloud,
  method,
  data,
  ...args
}: TypicalArgs & { method: Method; data: Website }) => {
  return callApi<Website>({
    url:
      method === METHODS.POST
        ? ENDPOINTS.locationPolicyWhitelist(args)
        : ENDPOINTS.locationPolicyWhitelistItem({ ...args, ...data }),
    method: method,
    data: method === METHODS.POST ? { ...data } : undefined,
    token,
    cloud,
  });
};

export const updatePersonPolicyWhitelist = ({
  token,
  cloud,
  method,
  data,
  personId,
  ...args
}: TypicalArgs & { method: Method; data: Website; personId: string }) => {
  return callApi<Website>({
    url:
      method === METHODS.POST
        ? ENDPOINTS.personPolicyWhitelist({ personId, ...args })
        : ENDPOINTS.personPolicyWhitelistItem({ personId, ...args, ...data }),
    method: method,
    data: method === METHODS.POST ? { ...data } : undefined,
    token,
    cloud,
  });
};

export const updateLocationPolicyBlacklist = ({
  token,
  cloud,
  method,
  data,
  ...args
}: TypicalArgs & { method: Method; data: Website }) => {
  return callApi<Website>({
    url:
      method === METHODS.POST
        ? ENDPOINTS.locationPolicyBlacklist(args)
        : ENDPOINTS.locationPolicyBlacklistItem({ ...args, ...data }),
    method: method,
    data: method === METHODS.POST ? { ...data } : undefined,
    token,
    cloud,
  });
};

export const updatePersonPolicyBlacklist = ({
  token,
  cloud,
  method,
  data,
  personId,
  ...args
}: TypicalArgs & { method: Method; data: Website; personId: string }) => {
  return callApi<Website>({
    url:
      method === METHODS.POST
        ? ENDPOINTS.personPolicyBlacklist({ personId, ...args })
        : ENDPOINTS.personPolicyBlacklistItem({ personId, ...args, ...data }),
    method: method,
    data: method === METHODS.POST ? { ...data } : undefined,
    token,
    cloud,
  });
};

type EventParams = {
  includes: 'IoTProtect.blacklistedAnomaly,IoTProtect.rolledbackAnomaly,IoTProtect.whitelistedAnomaly,adBlocking,kids,teenagers,workAppropriate,secureAndProtect,adultAndSensitive,SecureAndProtect.outboundIpBlocked,SecureAndProtect.inboundIpBlocked';
  limit?: string;
  direction: 'before' | 'after';
  startTime: Time;
};

export const getSecurityEvents = ({ token, cloud, ...args }: TypicalArgs) => {
  const params: EventParams = {
    includes:
      'IoTProtect.blacklistedAnomaly,IoTProtect.rolledbackAnomaly,IoTProtect.whitelistedAnomaly,adBlocking,kids,teenagers,workAppropriate,secureAndProtect,adultAndSensitive,SecureAndProtect.outboundIpBlocked,SecureAndProtect.inboundIpBlocked',
    direction: 'after',
    startTime: subHours(new Date(), 23).toISOString() as Time,
  };

  return callApi<SecurityEvent[]>({
    url: ENDPOINTS.events(args, params),
    token,
    cloud,
  });
};

export const getDeviceSecurityEvents = ({
  token,
  cloud,
  mac,
  ...args
}: TypicalArgs & {
  mac: Mac;
}) => {
  const params: EventParams = {
    includes:
      'IoTProtect.blacklistedAnomaly,IoTProtect.rolledbackAnomaly,IoTProtect.whitelistedAnomaly,adBlocking,kids,teenagers,workAppropriate,secureAndProtect,adultAndSensitive,SecureAndProtect.outboundIpBlocked,SecureAndProtect.inboundIpBlocked',
    direction: 'after',
    startTime: subHours(new Date(), 23).toISOString() as Time,
  };

  return callApi<SecurityEvent[]>({
    url: ENDPOINTS.deviceSecurityEvents(args, mac, params),
    token,
    cloud,
  });
};

export const getEmployeeSecurityEvents = ({
  token,
  cloud,
  personId,
  ...args
}: TypicalArgs & {
  personId: string;
}) => {
  const params: EventParams = {
    includes:
      'IoTProtect.blacklistedAnomaly,IoTProtect.rolledbackAnomaly,IoTProtect.whitelistedAnomaly,adBlocking,kids,teenagers,workAppropriate,secureAndProtect,adultAndSensitive,SecureAndProtect.outboundIpBlocked,SecureAndProtect.inboundIpBlocked',
    direction: 'after',
    startTime: subHours(new Date(), 23).toISOString() as Time,
  };

  return callApi<SecurityEvent[]>({
    url: ENDPOINTS.employeeSecurityEvents(args, personId, params),
    token,
    cloud,
  });
};

/* data usage */
type DataUsageFilters = {
  timePeriod:
    | 'weekly'
    | 'dailyToday'
    | 'dailyYesterday'
    | 'daily2DaysAgo'
    | 'daily3DaysAgo'
    | 'daily4DaysAgo'
    | 'daily5DaysAgo'
    | 'daily6DaysAgo'
    | 'last30Days'
    | 'last12Months';
  grouping: GroupingTypes;
};

const getDataUsageParams = (
  period: Period,
  grouping: GroupingTypes = 'overall'
) => {
  const timePeriods = {
    [PERIODS.day]: 'dailyToday',
    // the weekly period returns hourlyStats for some reason, so we use the monthly stats instead
    [PERIODS.week]: 'weekly',
    [PERIODS.month]: 'last30Days',
    [PERIODS.year]: 'last12Months',
  };

  return new URLSearchParams({
    timePeriod: timePeriods[period],
    grouping: GROUPING[grouping],
  } as DataUsageFilters).toString();
};

/* secure (default) network data usage */
const getDefaultNetworkDataUsageCategories = <R>({
  token,
  cloud,
  period,
  ...args
}: TypicalArgs & { period: Period }) => {
  return callApi<R>({
    url:
      ENDPOINTS.wifiNetwork(args) +
      ENDPOINTS.dataUsageCategories() +
      '?' +
      getDataUsageParams(period),
    token,
    cloud,
  });
};

export const getDefaultNetworkDailyDataUsageCategories = (
  args: TypicalArgs
) => {
  return getDefaultNetworkDataUsageCategories<DataUsageCategoriesDaily>({
    period: PERIODS.day,
    ...args,
  });
};

// export const getDefaultNetworkWeeklyDataUsageCategories = (args: TypicalArgs) => {
//   return getDefaultNetworkDataUsageCategories<DataUsageCategoriesWeekly>({ period: PERIODS.week, ...args })
// }

export const getDefaultNetworkMonthlyDataUsageCategories = (
  args: TypicalArgs
) => {
  return getDefaultNetworkDataUsageCategories<DataUsageCategoriesMonthly>({
    period: PERIODS.month,
    ...args,
  });
};

/* employee (fronthaul) network data usage */
const getEmployeeNetworkDataUsageCategories = <R>({
  token,
  cloud,
  period,
  ...args
}: TypicalArgs & { period: Period }) => {
  return callApi<R>({
    url:
      ENDPOINTS.fronthauls({ networkId: NETWORK_IDS.employee, ...args }) +
      ENDPOINTS.dataUsageCategories() +
      '?' +
      getDataUsageParams(period),
    token,
    cloud,
  });
};

export const getEmployeeNetworkDailyDataUsageCategories = (
  args: TypicalArgs
) => {
  return getEmployeeNetworkDataUsageCategories<DataUsageCategoriesDaily>({
    period: PERIODS.day,
    ...args,
  });
};

// export const getEmployeeNetworkWeeklyDataUsageCategories = (args: TypicalArgs) => {
//   return getEmployeeNetworkDataUsageCategories<DataUsageCategoriesWeekly>({ period: PERIODS.week, ...args })
// }

export const getEmployeeNetworkMonthlyDataUsageCategories = (
  args: TypicalArgs
) => {
  return getEmployeeNetworkDataUsageCategories<DataUsageCategoriesMonthly>({
    period: PERIODS.month,
    ...args,
  });
};

/* guest (captivePortal) network data usage */
const getGuestNetworkDataUsageCategories = <R>({
  token,
  cloud,
  period,
  ...args
}: TypicalArgs & { period: Period }) => {
  return callApi<R>({
    url:
      ENDPOINTS.captivePortals({ networkId: NETWORK_IDS.guest, ...args }) +
      ENDPOINTS.dataUsageCategories() +
      '?' +
      getDataUsageParams(period),
    token,
    cloud,
  });
};

export const getGuestNetworkDailyDataUsageCategories = (args: TypicalArgs) => {
  return getGuestNetworkDataUsageCategories<DataUsageCategoriesDaily>({
    period: PERIODS.day,
    ...args,
  });
};

export const getGuestNetworkMonthlyDataUsageCategories = (
  args: TypicalArgs
) => {
  return getGuestNetworkDataUsageCategories<DataUsageCategoriesMonthly>({
    period: PERIODS.month,
    ...args,
  });
};

/* person data usage */
const getPersonDataUsageCategories = <R>({
  token,
  cloud,
  period,
  personId,
  grouping,
  ...args
}: TypicalArgs & {
  period: Period;
  personId: string;
  grouping: GroupingTypes;
}) => {
  return callApi<R>({
    url:
      ENDPOINTS.persons({ personId, ...args }) +
      ENDPOINTS.dataUsageCategories() +
      '?' +
      getDataUsageParams(period, grouping),
    token,
    cloud,
  });
};

export const getPersonDailyDataUsageCategories = ({
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return getPersonDataUsageCategories<DataUsageCategoriesDaily>({
    period: PERIODS.day,
    grouping: GROUPING.perTimeSlot,
    personId,
    ...args,
  });
};

export const getPersonMonthlyDataUsageCategories = ({
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return getPersonDataUsageCategories<DataUsageCategoriesMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    personId,
    ...args,
  });
};

/***Device data usage  ***/

const getDeviceDataUsageCategories = <R>({
  token,
  cloud,
  period,
  mac,
  grouping,
  ...args
}: TypicalArgs & {
  period: Period;
  mac: Mac;
  grouping: GroupingTypes;
}) => {
  return callApi<R>({
    url:
      ENDPOINTS.device({ mac, ...args }) +
      ENDPOINTS.dataUsageCategories() +
      '?' +
      getDataUsageParams(period, grouping),
    token,
    cloud,
  });
};

export const getDeviceDailyDataUsageCategories = ({
  mac,
  ...args
}: TypicalArgs & { mac: string }) => {
  return getDeviceDataUsageCategories<DataUsageCategoriesDaily>({
    period: PERIODS.day,
    grouping: GROUPING.perTimeSlot,
    mac,
    ...args,
  });
};

export const getDeviceMonthlyDataUsageCategories = ({
  mac,
  ...args
}: TypicalArgs & { mac: string }) => {
  return getDeviceDataUsageCategories<DataUsageCategoriesMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    mac,
    ...args,
  });
};

/*** GUESTS ***/
/* guests */
export const getGuests = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<{ connectedNowCount: number; guests: Guest[] }>({
    url: ENDPOINTS.guests({ networkId: NETWORK_IDS.guest, ...args }),
    token,
    cloud,
  });
};

/* guests by hour */
export const getGuestNetworkUsage = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  const data = {
    inclusions: { daily: ['sessionStats', 'hourlyStats', 'deviceCategories'] },
  };

  return callApi<{ daily: GuestNetworkUsage }>({
    url: ENDPOINTS.guestNetworkUsage({ networkId: NETWORK_IDS.guest, ...args }),
    method: METHODS.POST,
    data,
    token,
    cloud,
  });
};

/* app time */
export const getLocationAppTime = ({
  token,
  cloud,
  ...args
}: TypicalArgs & {}) => {
  return callApi<LocationAppTime>({
    url: ENDPOINTS.locations(args) + '/appTime',
    method: METHODS.GET,
    token,
    cloud,
  });
};

export const updateLocationAppTime = ({
  token,
  cloud,
  data,
  ...args
}: TypicalArgs & {
  data: { enable: boolean; appliesToAllDevices: boolean };
}) => {
  return callApi<{ enable: boolean; updatedAt: Time }>({
    url: ENDPOINTS.locations(args) + '/appTime',
    method: METHODS.PATCH,
    data,
    token,
    cloud,
  });
};

/* Person Online Time */
const getPersonOnLineTimeCategories = <R>({
  token,
  cloud,
  period,
  personId,
  grouping,
  ...args
}: TypicalArgs & {
  period: Period;
  personId: string;
  grouping: GroupingTypes;
}) => {
  return callApi<R>({
    url:
      ENDPOINTS.persons({ personId, ...args }) +
      ENDPOINTS.onlineTimeCategories() +
      '?' +
      getDataUsageParams(period, grouping),
    token,
    cloud,
  });
};

export const getPersonDailyOnlineTimeCategories = ({
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return getPersonOnLineTimeCategories<TimeCategoriesDaily>({
    period: PERIODS.day,
    grouping: GROUPING.perTimeSlot,
    personId,
    ...args,
  });
};

// export const getPersonWeeklyOnlineTimeCategories = (
//   { personId, ...args }
//     : TypicalArgs & { personId: string },
// ) => {
//   return getPersonOnLineTimeCategories<OnlineTimeCategoriesWeekly>({
//     period: PERIODS.week,
//     grouping: GROUPING.perTimeSlot,
//     personId,
//     ...args,
//   })
// }

export const getPersonMonthlyOnlineTimeCategories = ({
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return getPersonOnLineTimeCategories<TimeCategoriesMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    personId,
    ...args,
  });
};

/* Person App Time */
const getPersonAppTimeCategories = <R>({
  token,
  cloud,
  period,
  grouping,
  personId,
  ...args
}: TypicalArgs & {
  period: Period;
  grouping: GroupingTypes;
  personId: string;
}) => {
  return callApi<R>({
    url:
      ENDPOINTS.persons({ personId, ...args }) +
      ENDPOINTS.appsOnlineTime() +
      '?' +
      getDataUsageParams(period, grouping) +
      '&limit=500',
    token,
    cloud,
  });
};

export const getPersonMonthlyOnlineAppTime = ({
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return getPersonAppTimeCategories<OnlineTimeAppMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    personId,
    ...args,
  });
};

export const getPersonLast12MonthsOnlineAppTime = ({
  personId,
  ...args
}: TypicalArgs & { personId: string }) => {
  return getPersonAppTimeCategories<OnlineTimeAppYearly>({
    period: PERIODS.year,
    grouping: GROUPING.perTimeSlot,
    personId,
    ...args,
  });
};

/* Guest Online Time */

const getGuestOnlineTimeCategories = <R>({
  token,
  cloud,
  period,
  grouping,
  ...args
}: TypicalArgs & { period: Period; grouping: GroupingTypes }) => {
  return callApi<R>({
    url:
      ENDPOINTS.captivePortals({ networkId: NETWORK_IDS.guest, ...args }) +
      ENDPOINTS.onlineTimeCategories() +
      '?' +
      getDataUsageParams(period, grouping),
    token,
    cloud,
  });
};

export const getGuestDailyOnlineTimeCategories = ({ ...args }: TypicalArgs) => {
  return getGuestOnlineTimeCategories<TimeCategoriesDaily>({
    period: PERIODS.day,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

/* Guest Apps Online Time */

const getGuestAppOnlineTimeCategories = <R>({
  token,
  cloud,
  period,
  grouping,
  ...args
}: TypicalArgs & { period: Period; grouping: GroupingTypes }) => {
  return callApi<R>({
    url:
      ENDPOINTS.captivePortals({ networkId: NETWORK_IDS.guest, ...args }) +
      ENDPOINTS.appsOnlineTime() +
      '?' +
      getDataUsageParams(period, grouping) +
      '&limit=500',
    token,
    cloud,
  });
};

export const getGuestDailyAppsOnlineTime = ({ ...args }: TypicalArgs) => {
  return getGuestAppOnlineTimeCategories<OnlineTimeAppDaily>({
    period: PERIODS.day,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

export const getGuestMonthlyAppsOnlineTime = ({ ...args }: TypicalArgs) => {
  return getGuestAppOnlineTimeCategories<OnlineTimeAppMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

export const getGuestLast12MonthsAppsOnlineTime = ({
  ...args
}: TypicalArgs) => {
  return getGuestAppOnlineTimeCategories<OnlineTimeAppYearly>({
    period: PERIODS.year,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

/* Employee Zone Apps Online Time */

const getEmployeeAppOnlineTimeCategories = <R>({
  token,
  cloud,
  period,
  grouping,
  ...args
}: TypicalArgs & { period: Period; grouping: GroupingTypes }) => {
  return callApi<R>({
    url:
      ENDPOINTS.fronthauls({ networkId: NETWORK_IDS.employee, ...args }) +
      ENDPOINTS.appsOnlineTime() +
      '?' +
      getDataUsageParams(period, grouping) +
      '&limit=500',
    token,
    cloud,
  });
};

export const getEmployeeMonthlyAppsOnlineTime = ({ ...args }: TypicalArgs) => {
  return getEmployeeAppOnlineTimeCategories<OnlineTimeAppMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

export const getEmployeeLast12MonthsAppsOnlineTime = ({
  ...args
}: TypicalArgs) => {
  return getEmployeeAppOnlineTimeCategories<OnlineTimeAppYearly>({
    period: PERIODS.year,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

/* Default Zone Apps Online Time */

const getDefaultAppOnlineTimeCategories = <R>({
  token,
  cloud,
  period,
  grouping,
  ...args
}: TypicalArgs & { period: Period; grouping: GroupingTypes }) => {
  return callApi<R>({
    url:
      ENDPOINTS.wifiNetwork(args) +
      ENDPOINTS.appsOnlineTime() +
      '?' +
      getDataUsageParams(period, grouping) +
      '&limit=500',
    token,
    cloud,
  });
};

export const getDefaultMonthlyAppsOnlineTime = ({ ...args }: TypicalArgs) => {
  return getDefaultAppOnlineTimeCategories<OnlineTimeAppMonthly>({
    period: PERIODS.month,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

export const getDefaultLast12MonthsAppsOnlineTime = ({
  ...args
}: TypicalArgs) => {
  return getDefaultAppOnlineTimeCategories<OnlineTimeAppYearly>({
    period: PERIODS.year,
    grouping: GROUPING.perTimeSlot,
    ...args,
  });
};

/* Topology forceGraph */
export const getForceGraph = ({ token, cloud, ...args }: TypicalArgs) => {
  return callApi<Topology>({ url: ENDPOINTS.forceGraph(args), token, cloud });
};

/* Customer Support */
export const getCustomerSupportConfigurations = ({
  token,
  cloud,
  ...args
}: TypicalArgs) => {
  return callApi<CustomerSupportConfiguration>({
    url: ENDPOINTS.customerSupportConfiguration(args),
    token,
    cloud,
  });
};

/* Topology PubnubSubscribe */
export const getPubnubSubscribe = ({
  token,
  cloud,
  pubnubChannels,
  ...args
}: TypicalArgs & { pubnubChannels: string[] }) => {
  return callApi<PubnubSubscribe>({
    url: ENDPOINTS.pubnubSubscribe(args),
    token,
    cloud,
    method: METHODS.POST,
    data: {
      channels: pubnubChannels,
    },
    repoBaseUrl: 'notifications',
  });
};

/* Employee Consent */
export const getEmployeeLoginConfig = ({
  locationId,
  token,
  cloud,
}: CloudArg & TokenArg & { locationId: LocationId }) => {
  return callApi<EmployeeLoginData>({
    url: ENDPOINTS.employeeLoginConfig(locationId),
    token,
    cloud,
    repoBaseUrl: 'warden',
  });
};

export const updateEmployeeLoginCompanyName = ({
  locationId,
  token,
  cloud,
  companyName,
}: CloudArg & TokenArg & { locationId: LocationId; companyName: string }) => {
  return callApi<Pick<EmployeeLoginData, 'companyName'>>({
    url: ENDPOINTS.employeeLoginCompanyName(locationId),
    method: METHODS.PATCH,
    data: { companyName },
    token,
    cloud,
    repoBaseUrl: 'warden',
  });
};

export const updateEmployeeLoginEnabled = ({
  locationId,
  token,
  cloud,
  enabled,
}: CloudArg & TokenArg & { locationId: LocationId; enabled: boolean }) => {
  return callApi<Pick<EmployeeLoginData, 'enabled'>>({
    url: ENDPOINTS.employeeLoginEnableFeature(locationId),
    method: METHODS.PUT,
    data: { enabled },
    token,
    cloud,
    repoBaseUrl: 'warden',
  });
};

export const getEmployeeLoginBlockedEmployees = ({
  locationId,
  token,
  cloud,
}: CloudArg & TokenArg & { locationId: LocationId }) => {
  return callApi<BlockedEmployeeData[]>({
    url: ENDPOINTS.employeeLoginBlockedEmployees(locationId),
    method: METHODS.GET,
    token,
    cloud,
    repoBaseUrl: 'warden',
  });
};

export const unblockEmployeeLoginEmployee = ({
  locationId,
  idpUserId,
  token,
  cloud,
}: CloudArg & TokenArg & { locationId: LocationId; idpUserId: string }) => {
  return callApi({
    url: ENDPOINTS.unblockEmployeeLoginEmployee(locationId, idpUserId),
    method: METHODS.DELETE,
    token,
    cloud,
    repoBaseUrl: 'warden',
  });
};

/** Location Capabilites */
export const getLocationCapabilities = ({
  customerId,
  locationId,
  token,
  cloud,
}: CloudArg &
  TokenArg & { customerId: CustomerId; locationId: LocationId }) => {
  return callApi<ConfigAndStateType>({
    url: ENDPOINTS.locationCapabilities(customerId, locationId),
    token,
    cloud,
  });
};
