import React, { FunctionComponent } from 'react';
import { TooltipProps } from 'recharts';

import { Categories, Nullable, Period } from 'Consts/types';
import { PERIODS } from 'Consts/defintions';

import type { BarGraphData, ActivityTypes } from 'UI/Components/Graphs/types';
import BarGraph from 'UI/Components/Graphs/BarGraph';

import { FinePrint1 } from 'UI/Elements/Typography';
import { Card } from 'UI/Elements/Card';

import Filters from './Components/Data usage filter';
import CategoryList from './Components/Category list';

import styles from './style.module.css';
import { useTranslation } from 'react-i18next';

type GraphProps = {
  averageLabel?: Nullable<string>;
  data: BarGraphData<string>[];
  customTooltip?: (props: TooltipProps<string, string>) => React.ReactNode;
  colors: Record<string, string>;
  filters: {
    name: Categories;
    value: number;
  }[];
  period?: Period;
  setPeriod?: (value: Period) => void;
  activity?: ActivityTypes;
  setActivity?: (value: ActivityTypes) => void;
  activityType?: ActivityTypes;
  onCategoryIconClick: React.MouseEventHandler;
};

type CategoryDataUsageGraphProps = {
  label: string;
  secondLabel?: string;
  graphProps: GraphProps;
  span?: 2 | 3;
  activityType?: ActivityTypes;
} & JSX.IntrinsicElements['div'];

export const CategoryDataUsageGraphBase: FunctionComponent<GraphProps> = ({
  data,
  customTooltip,
  averageLabel,
  colors,
  filters,
  period,
  setPeriod,
  activity,
  setActivity,
  activityType,
  onCategoryIconClick,
}) => {
  const { t } = useTranslation();
  let interval = 0;

  if (period === PERIODS.day) {
    interval = 3;
  }

  if (period === PERIODS.month) {
    interval = 4;
  }

  return (
    <>
      {averageLabel && (
        <FinePrint1 className={styles.graphAverageLabel}>
          {averageLabel}
        </FinePrint1>
      )}

      <BarGraph
        className={styles.graph}
        data={data}
        graphColors={colors}
        customTooltip={customTooltip}
        interval={interval}
        ariaLabel={t('employee.categoryUsageChartAriaLabel')}
      />

      <CategoryList
        items={filters}
        colors={colors}
        activityType={activityType}
        onIconClick={onCategoryIconClick}
      />

      <Filters
        period={period}
        setPeriod={setPeriod}
        activity={activity}
        setActivity={setActivity}
      />
    </>
  );
};

const CategoryDataUsageGraph: FunctionComponent<
  CategoryDataUsageGraphProps
> = ({ className, label, secondLabel, graphProps, span, activityType }) => {
  return (
    <Card
      span={span}
      className={className}
      header={{
        L2Props: {
          label,
          secondLabel,
        },
      }}
    >
      <CategoryDataUsageGraphBase {...graphProps} activityType={activityType} />
    </Card>
  );
};

export default CategoryDataUsageGraph;
