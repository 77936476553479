import {
  GlobalAuthDeployment,
  GlobalAuthEnvironmentConfig,
} from './globalAuthTypes';
import { CloudBackend, EnvironmentSettings } from './types';

export type GlobalAuthEnvironments = 'CI' | 'DEV' | 'PROD';

export const globalAuthValues: Record<
  GlobalAuthEnvironments,
  GlobalAuthEnvironmentConfig
> = {
  CI: {
    globalAuthUrl: 'https://eks-ext.usw2.dev.ci.plume.tech',
    auth0Domain: 'login-ci.plume.tech',
    auth0ClientId: 'UlXN35I2nJAriUqmnHKWAgwaBf2qLg7e',
  },
  DEV: {
    globalAuthUrl: 'https://eks-ext.usw2.dev.global.plume.tech',
    auth0Domain: 'login-dev.plume.tech',
    auth0ClientId: '7qHdwRIAEpHqAworbHsEczWNnRTokxeF',
  },
  PROD: {
    globalAuthUrl: 'https://global-auth.global.plumenet.io',
    auth0Domain: 'login.plume.com',
    auth0ClientId: 'UIGvqaFnYxwqYPq26r5X6DUCncAcI51b',
  },
};

export const environments: Partial<Record<CloudBackend, EnvironmentSettings>> =
  {
    CI: {
      apiUrl: 'https://piranha-ci.dev.us-west-2.aws.plume.tech',
      metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
      globalAuthUrl: globalAuthValues.CI.globalAuthUrl,
      auth0Domain: globalAuthValues.CI.auth0Domain,
      auth0ClientId: globalAuthValues.CI.auth0ClientId,
    },
    DOGFOOD: {
      apiUrl: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech',
      metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
      globalAuthUrl: globalAuthValues.DEV.globalAuthUrl,
      auth0Domain: globalAuthValues.DEV.auth0Domain,
      auth0ClientId: globalAuthValues.DEV.auth0ClientId,
    },
    OSYNC: {
      apiUrl: 'https://piranha-osync.dogfood.us-west-2.aws.plume.tech',
      metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
      globalAuthUrl: globalAuthValues.DEV.globalAuthUrl,
      auth0Domain: globalAuthValues.DEV.auth0Domain,
      auth0ClientId: globalAuthValues.DEV.auth0ClientId,
    },
    OPENSYNC: {
      apiUrl: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech',
      metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
      globalAuthUrl: globalAuthValues.DEV.globalAuthUrl,
      auth0Domain: globalAuthValues.DEV.auth0Domain,
      auth0ClientId: globalAuthValues.DEV.auth0ClientId,
    },
    BETA: {
      apiUrl: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io',
      metasaurusUrl: 'https://digital-wellbeing-apptime.plumenet.io',
      globalAuthUrl: globalAuthValues.PROD.globalAuthUrl,
      auth0Domain: globalAuthValues.PROD.auth0Domain,
      auth0ClientId: globalAuthValues.PROD.auth0ClientId,
    },
    GAMMA: {
      apiUrl: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io',
      metasaurusUrl: 'https://digital-wellbeing-apptime.plumenet.io',
      globalAuthUrl: globalAuthValues.PROD.globalAuthUrl,
      auth0Domain: globalAuthValues.PROD.auth0Domain,
      auth0ClientId: globalAuthValues.PROD.auth0ClientId,
    },
    KAPPA: {
      apiUrl: 'https://piranha.eu-central-1.prod.kappa.plumenet.io',
      metasaurusUrl: 'https://digital-wellbeing-apptime.plumenet.io',
      globalAuthUrl: globalAuthValues.PROD.globalAuthUrl,
      auth0Domain: globalAuthValues.PROD.auth0Domain,
      auth0ClientId: globalAuthValues.PROD.auth0ClientId,
    },
    ETNA: {
      apiUrl: 'https://piranha-etna.prod.us-west-2.aws.plume.tech',
      metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
    },
    DELTA: {
      apiUrl: 'https://piranha-delta.prod.ca-central-1.aws.plumenet.io',
      metasaurusUrl: 'https://digital-wellbeing-apptime.plumenet.io',
    },
    THETA: {
      apiUrl: 'https://piranha.eu-central-1.prod.theta.plumenet.io',
      metasaurusUrl: 'https://digital-wellbeing-apptime.plumenet.io',
      globalAuthUrl: globalAuthValues.PROD.globalAuthUrl,
      auth0Domain: globalAuthValues.PROD.auth0Domain,
      auth0ClientId: globalAuthValues.PROD.auth0ClientId,
    },
    THETASTAGE: {
      apiUrl: 'https://piranha.euc1.stage.theta.plumenet.io',
      metasaurusUrl: 'https://digital-wellbeing-apptime.plumenet.io',
      globalAuthUrl: globalAuthValues.PROD.globalAuthUrl,
      auth0Domain: globalAuthValues.PROD.auth0Domain,
      auth0ClientId: globalAuthValues.PROD.auth0ClientId,
    },
    THETADEV: {
      apiUrl: 'https://piranha-thetadev.partner.us-west-2.aws.plume.tech',
      metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
      globalAuthUrl: globalAuthValues.DEV.globalAuthUrl,
      auth0Domain: globalAuthValues.DEV.auth0Domain,
      auth0ClientId: globalAuthValues.DEV.auth0ClientId,
    },
    PHI: {
      apiUrl: 'https://piranha.apse1.prod.phi.plumenet.io',
      metasaurusUrl: 'https://digital-wellbeing-apptime.plumenet.io',
      globalAuthUrl: globalAuthValues.PROD.globalAuthUrl,
      auth0Domain: globalAuthValues.PROD.auth0Domain,
      auth0ClientId: globalAuthValues.PROD.auth0ClientId,
    },
  };

export const cloudLookupFromGlobalAuth: Record<
  GlobalAuthDeployment,
  CloudBackend
> = {
  ci: 'CI',
  dog1: 'DOGFOOD',
  beta: 'BETA',
  kappa: 'KAPPA',
  gamma: 'GAMMA',
  osync: 'OSYNC',
  opensync: 'OPENSYNC',
  delta: 'DELTA',
  etna: 'ETNA',
  theta: 'THETA',
  thetadev: 'THETADEV',
  'theta-stage': 'THETASTAGE',
  phi: 'PHI',
};
