import { NETWORK_IDS } from 'Consts/defintions';
import { NetworkType } from 'Consts/types';

import { IconName, IconNames } from 'UI/Elements/Icon';

export enum SETTINGS_LIST {
  index = 'index',
  secure = 'secure',
  employee = 'employee',
  employeeLogin = 'employeeLogin',
  guest = 'guest',
  shield = 'shield',
  pods = 'pods',
  support = 'support',
  account = 'account',
}

export type SettingsKey = keyof typeof SETTINGS_LIST;

export type SettingsConfig = {
  i18labelKey: string;
  iconName: IconName;
  uniqueKey: SettingsKey;
};

export const WIFI_SETTINGS: Record<
  'secure' | 'employee' | 'employeeLogin' | 'guest',
  SettingsConfig & { networkId: NetworkType }
> = {
  secure: {
    i18labelKey: 'settings.secure',
    iconName: IconNames.Wifi,
    uniqueKey: SETTINGS_LIST.secure,
    networkId: NETWORK_IDS.default,
  },
  employee: {
    i18labelKey: 'settings.employee',
    iconName: IconNames.Wifi,
    uniqueKey: SETTINGS_LIST.employee,
    networkId: NETWORK_IDS.employee,
  },
  employeeLogin: {
    i18labelKey: 'settings.employeeLogin',
    iconName: IconNames.Dummy,
    uniqueKey: SETTINGS_LIST.employeeLogin,
    networkId: NETWORK_IDS.employee,
  },
  guest: {
    i18labelKey: 'settings.guest',
    iconName: IconNames.Wifi,
    uniqueKey: SETTINGS_LIST.guest,
    networkId: NETWORK_IDS.guest,
  },
};

export const ALL_SETTINGS: Record<SettingsKey, SettingsConfig> = {
  ...WIFI_SETTINGS,
  index: {
    i18labelKey: 'settings.index',
    iconName: IconNames.Dummy,
    uniqueKey: SETTINGS_LIST.index,
  },
  shield: {
    i18labelKey: 'settings.shieldSettings',
    iconName: IconNames.Dummy,
    uniqueKey: SETTINGS_LIST.shield,
  },
  pods: {
    i18labelKey: 'settings.pods',
    iconName: IconNames.Pod,
    uniqueKey: SETTINGS_LIST.pods,
  },
  support: {
    i18labelKey: 'settings.support',
    iconName: IconNames.Call,
    uniqueKey: SETTINGS_LIST.support,
  },
  account: {
    i18labelKey: 'settings.account',
    iconName: IconNames.Person,
    uniqueKey: SETTINGS_LIST.account,
  },
};
