import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'Consts/routes';
import { EMAIL_REGEX } from 'Consts/defintions';

import InputListItem from 'UI/Components/Lists/List input';

import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { IconNames } from 'UI/Elements/Icon';

import * as api from 'Api/endpoints';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';

import {
  OnboardingPageWrapper,
  OnboardingPageTopContent,
  OnboardingPageBottomContent,
} from '../Components/Onboarding page wrapper';
import CloudSelector from '../Components/CloudSelector';

import onboardingStyles from '../style.module.css';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import { useAuth0 } from '@auth0/auth0-react';
import { GlobalAuthCustomer } from 'Consts/globalAuthTypes';
import { Maybe } from 'Consts/types';
import { cloudLookupFromGlobalAuth } from 'Consts/environments';
import { isDevDomain } from 'subDomainConfiguration';
import Loader from 'UI/Elements/Loader';
import styles from './style.module.css';

export default function GlobalAuthPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const debug = searchParams.get('debug') === 'true';
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const { data: globalAuthData, errorMessage: globalAuthError } = useSelector(
    selectors.globalAuthLoginOptions
  );

  const [email, setEmail] = useState(
    localStorage.getItem('customerEmail') || ''
  );
  const [isCloudMenuOpen, setIsCloudMenuOpen] = useState(false);
  const [accountList, setAccountList] =
    useState<Maybe<GlobalAuthCustomer[]>>(undefined);
  const [error, setError] = useState('');
  const [initialised, setInitialised] = useState(false);

  const enableMagicLink =
    globalAuthData?.legacyLoopbackAuth?.some((element) => {
      return element.loginType === 'MagicLink';
    }) && !isAuthenticated;

  const enableUsernamePasswordLink =
    globalAuthData?.legacyLoopbackAuth?.some((element) => {
      return element.loginType === 'UsernamePassword';
    }) && !isAuthenticated;

  const enableGlobalAuthLink = globalAuthData?.globalAuth && !isAuthenticated;

  const noViableLoginMechanism =
    globalAuthData &&
    (globalAuthData?.legacyLoopbackAuth?.length || 0) === 0 &&
    !globalAuthData?.globalAuth?.domain &&
    !isAuthenticated;

  const cloud = useSelector(selectors.auth.cloud);

  const showCloudSelector = isDevDomain();

  const reset = useCallback(() => {
    setError('');
    dispatch(actions.auth.clear());
  }, [dispatch]);

  const handleChange = useCallback(
    (value: string) => {
      const trimmedEmail = value.trim();
      localStorage.setItem('customerEmail', trimmedEmail);
      reset();

      setEmail(trimmedEmail);
    },
    [reset]
  );

  useEffect(() => {
    if (globalAuthData && !debug && initialised) {
      if (enableGlobalAuthLink) {
        loginWithRedirect({ authorizationParams: { login_hint: email } });
      } else {
        if (enableMagicLink) {
          if (globalAuthData?.legacyLoopbackAuth?.[0]) {
            dispatch(
              actions.auth.setCloud(
                cloudLookupFromGlobalAuth[
                  globalAuthData?.legacyLoopbackAuth[0].deploymentName
                ]
              )
            );
          }
          navigate(ROUTES.onboarding.magicLink);
        } else {
          if (enableUsernamePasswordLink) {
            if (globalAuthData?.legacyLoopbackAuth?.[0]) {
              dispatch(
                actions.auth.setCloud(
                  cloudLookupFromGlobalAuth[
                    globalAuthData?.legacyLoopbackAuth[0].deploymentName
                  ]
                )
              );
            }
            navigate(ROUTES.onboarding.login);
          }
        }
      }
    }
  }, [enableGlobalAuthLink, enableMagicLink, enableUsernamePasswordLink]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const token = await getAccessTokenSilently();
        const { data, error } = await api.globalAuthGetAllCustomers({
          cloud,
          token: 'Bearer ' + token,
        });

        if (error) {
          setError(error.message);
        } else {
          if (data) {
            if (data.length === 1 && !debug) {
              handleSelectAccountForLogin(data[0]);
            } else {
              setAccountList(data);
            }
          }
        }
      } catch (caughtError) {
        setError(() => t('onboarding.errorNetworkError'));
        logout({
          logoutParams: {
            returnTo: window.location.origin + '/onboarding/global-auth',
          },
        });
      }
    };

    if (user?.sub) {
      getUserMetadata();
    }
  }, [user]);

  const handleSelectAccountForLogin = useCallback(
    async (account: GlobalAuthCustomer) => {
      const token = await getAccessTokenSilently();
      dispatch(actions.auth.set('Bearer ' + token));
      dispatch(
        actions.auth.setCloud(cloudLookupFromGlobalAuth[account.deploymentName])
      );
      dispatch(actions.customer.setCustomerId(account.customerId));

      actions.auth.saveAuthToLocalStorage(
        'Bearer ' + token,
        account.customerId
      );
    },
    [dispatch]
  );

  const handleCloudMenuClose = useCallback(() => {
    setIsCloudMenuOpen(false);
    window.location.reload();
  }, []);
  const handleCloudMenuOpen = useCallback((ev: React.MouseEvent) => {
    setIsCloudMenuOpen(true);
  }, []);

  const handleLogin = useCallback(async () => {
    if (!email) {
      setError(() => t('onboarding.errorEmptyField'));
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      setError(() => t('onboarding.errorInvalidEmail'));
      return;
    }

    dispatch(actions.globalAuthLoginOptions.loginOptions(email.toLowerCase()));
    setInitialised(true);
  }, [dispatch, email, t]);

  useEffect(() => {
    setError('');
    dispatch(actions.globalAuthLoginOptions.clear());
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.GlobalAuthPage,
      },
    });
  }, []);

  useEffect(() => {
    if (globalAuthError) {
      const errorMessage = capitalize(globalAuthError);

      setError(errorMessage);
    }
    if (noViableLoginMechanism) {
      setError(() =>
        t('onboarding.errorEmailDoesNotExistInGlobalAuth', {
          invalidEmail: email,
        })
      );
    }
  }, [globalAuthError, noViableLoginMechanism]);

  return (
    <OnboardingPageWrapper
      label={t('onboarding.signInWithGlobalAuth')}
      subLabel={t('onboarding.signInSubLabel')}
    >
      {(isLoading || (isAuthenticated && !accountList)) && (
        <div data-testid="Loader" className={styles.verticalCenter}>
          <Loader />
        </div>
      )}

      {(!isAuthenticated || accountList) && !isLoading && (
        <>
          <OnboardingPageTopContent>
            {!isAuthenticated && (
              <>
                <InputListItem
                  className={onboardingStyles.stretch}
                  prefixIcon={IconNames.EmailIcon}
                  placeholder={t('onboarding.yourEmail')}
                  smallLabel={
                    email ? (t('onboarding.yourEmail') as string) : ''
                  }
                  paragraph={error ? error : ''}
                  value={email}
                  onChange={handleChange}
                  onSubmit={handleLogin}
                  hasError={Boolean(error)}
                />
                {showCloudSelector && (
                  <CloudSelector
                    isOpen={isCloudMenuOpen}
                    onSelect={handleCloudMenuOpen}
                    onClose={handleCloudMenuClose}
                    onSubmit={handleLogin}
                    enabledCloudList={['DOGFOOD', 'CI']}
                  />
                )}
              </>
            )}
          </OnboardingPageTopContent>

          <OnboardingPageBottomContent>
            {enableUsernamePasswordLink && debug && (
              <Button
                theme={BUTTON_THEMES.white}
                label={t('onboarding.signInWithPassword')}
                onClick={() => navigate(ROUTES.onboarding.login)}
              />
            )}

            {enableMagicLink && debug && (
              <Button
                theme={BUTTON_THEMES.white}
                label={t('onboarding.signInWithMagicLink')}
                onClick={() => navigate(ROUTES.onboarding.magicLink)}
              />
            )}
            {enableGlobalAuthLink && debug && (
              <Button
                theme={BUTTON_THEMES.white}
                label={t('onboarding.signInWithGlobalAuth')}
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: { login_hint: email },
                  })
                }
              />
            )}

            {!isAuthenticated && (
              <Button label={t('onboarding.continue')} onClick={handleLogin} />
            )}
            {isAuthenticated && accountList && (
              <>
                {accountList?.map((account) => {
                  return (
                    <Button
                      label={'Login to ' + account.deploymentName}
                      key={account.id}
                      theme={BUTTON_THEMES.white}
                      onClick={() => {
                        handleSelectAccountForLogin(account);
                      }}
                    />
                  );
                })}
              </>
            )}

            {!isAuthenticated && (
              <Button
                theme={BUTTON_THEMES.noborder}
                label={t('common.back')}
                onClick={() => navigate(ROUTES.onboarding.index)}
              />
            )}
          </OnboardingPageBottomContent>
        </>
      )}
    </OnboardingPageWrapper>
  );
}
