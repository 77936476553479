import { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';
import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import Tooltip from 'UI/Elements/Tooltip';

import type { IconName } from './icons';
import { IconNames, Icons, IconShape } from './icons';

import colorStyles from 'Styles/colors.module.css';
import { onEnterKeydown } from 'Utils/keyboardEvents';
import { Nullable } from '../../../Consts/types';
import styles from './style.module.css';

export type { IconName } from './icons';

export { IconNames, Icons } from './icons';

export type Props = {
  ariaHidden?: boolean;
  ariaLabel?: string;
  className?: string;
  name: IconName;
  shape?: IconShape;
  testid?: string;
} & (
  | {
      onClick?: undefined;
      tooltipLabel?: undefined;
    }
  | {
      onClick?: undefined;
      tooltipLabel?: string;
    }
  | {
      onClick?: React.MouseEventHandler;
      tooltipLabel?: string | null;
    }
);

type IconTooltipProps = {
  showTooltip: boolean;
  tooltipLabel: Nullable<string>;
  parent: Nullable<Element>;
};

export const CHECKLIST_ICON_NAMES = [
  IconNames.CheckListOff,
  IconNames.CheckListOn,
  IconNames.CheckListOffBell,
  IconNames.CheckListOnBell,
];

export const IconTooltip: FunctionComponent<IconTooltipProps> = ({
  showTooltip,
  tooltipLabel,
  parent,
}) => {
  const refTimer = useRef<NodeJS.Timeout>();

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [activeToolTip, setActiveToolTip] = useState(false);

  useEffect(() => {
    if (showTooltip) {
      refTimer.current = setTimeout(() => {
        setActiveToolTip(showTooltip);
      }, 500);
    }

    setActiveToolTip(false);

    return () => clearTimeout(refTimer.current);
  }, [showTooltip]);

  const popperModifiers = useMemo(() => {
    const offset: OffsetsFunction = ({ placement }) => {
      if (placement === 'bottom-start') {
        return [-8, 16];
      }

      return [8, 16];
    };

    return {
      name: 'offset',
      options: { offset },
    };
  }, []);

  const { styles: tooltipStyles, attributes } = usePopper(
    parent,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: [popperModifiers],
    }
  );

  if (!activeToolTip || !tooltipLabel) {
    return null;
  }

  return (
    <Tooltip
      ref={setPopperElement}
      className={colorStyles.still800}
      style={{ ...tooltipStyles.popper }}
      {...attributes.popper}
    >
      {tooltipLabel}
    </Tooltip>
  );
};

const Icon: FunctionComponent<Props> = ({
  ariaHidden,
  ariaLabel,
  className,
  name,
  shape = IconShape.auto,
  testid = null,
  onClick,
  tooltipLabel = null,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const FoundIcon = Icons[name];

  const handleOnClick = (ev: React.MouseEvent) => {
    if (onClick) {
      onClick(ev);
      ev.stopPropagation();
    }
  };

  const handleOnMouseOver = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      setShowTooltip(true && !isMobile);
    },
    [isMobile]
  );

  const handleOnMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const handleOnFocus = useCallback(() => {
    if (ariaHidden) return;
    setShowTooltip(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setShowTooltip(false);
  }, []);

  // This is a hack to detect mobile devices
  const handleOnTouchStart = useCallback(() => {
    setIsMobile(true);
  }, []);

  const getRole = () => {
    if (ariaHidden) return;
    if (CHECKLIST_ICON_NAMES.includes(name)) return 'checkbox';
    if (onClick) return 'button';
    if (tooltipLabel) return 'tooltip';
    return 'img';
  };

  const getAriaLabel = () =>
    ariaHidden ? undefined : ariaLabel || tooltipLabel || name;

  const getAriaChecked = () => {
    if (!CHECKLIST_ICON_NAMES.includes(name)) return undefined;
    if (name === IconNames.CheckListOn || name === IconNames.CheckListOnBell)
      return true;
    return false;
  };

  if (!FoundIcon) {
    return null;
  }

  return (
    <div
      ref={setReferenceElement}
      className={cn(
        {
          [styles.interactive]:
            onClick || !!tooltipLabel || tooltipLabel === '',
          [styles.circle]: shape === IconShape.circle,
          [styles.rectangle]: shape === IconShape.rectangle,
          [styles.smallCircle]: shape === IconShape.smallCircle,
          [styles.square]: shape === IconShape.square,
        },
        styles.icon,
        className
      )}
      onClick={handleOnClick}
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      onTouchStart={handleOnTouchStart}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
      data-testid={testid}
      role={getRole()}
      tabIndex={getRole() !== 'img' ? 0 : undefined}
      aria-label={getAriaLabel()}
      aria-hidden={ariaHidden}
      aria-checked={getAriaChecked()}
    >
      <FoundIcon />
      <IconTooltip
        showTooltip={showTooltip}
        parent={referenceElement}
        tooltipLabel={tooltipLabel}
      />
    </div>
  );
};

export default Icon;
