import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import styles from './style.module.css';

export type ContentLeftProps = {
  style?: React.CSSProperties;
  className?: string;
  verticalAlign?: string;
  children?: React.ReactNode;
};

export const ContentLeft: FunctionComponent<ContentLeftProps> = ({
  style,
  className,
  verticalAlign = 'center',
  children,
}) => {
  return (
    <div
      style={style}
      className={cn(
        styles.left,
        {
          [styles.verticalAlignCenter]:
            verticalAlign === 'center' || verticalAlign !== 'top',
          [styles.verticalAlignTop]: verticalAlign === 'top',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export type ContentMainProps = {
  className?: string;
  horizontalAlign?: string;
  children?: React.ReactNode;
};

export const ContentMain: FunctionComponent<ContentMainProps> = ({
  className,
  horizontalAlign = 'left',
  children,
}) => {
  return (
    <div
      className={cn(
        styles.main,
        {
          [styles.horizontalAlignLeft]:
            horizontalAlign === 'left' || horizontalAlign !== 'top',
          [styles.horizontalAlignCenter]: horizontalAlign === 'center',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export type ContentRightProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
};

export const ContentRight: FunctionComponent<ContentRightProps> = ({
  style,
  className,
  children,
}) => {
  return (
    <div style={style} className={cn(styles.right, className)}>
      {children}
    </div>
  );
};

export type ContentBottomProps = {
  className?: string;
  children?: React.ReactNode;
};

export const ContentBottom: FunctionComponent<ContentBottomProps> = ({
  className,
  children,
}) => {
  return <div className={cn(styles.bottom, className)}>{children}</div>;
};

export type InlineContentProps = {
  className?: string;
  noGap?: boolean;
  children?: React.ReactNode;
};

export const InlineContent: FunctionComponent<InlineContentProps> = ({
  className,
  noGap = false,
  children,
}) => {
  const classNames = cn(
    styles.inline,
    {
      [styles.noGap]: noGap,
    },
    className
  );
  return <div className={classNames}>{children}</div>;
};

export const HorizontalDivider: FunctionComponent = () => {
  return <div className={cn(styles.divider)}></div>;
};

export type ContentSpacerProps = {
  className?: string;
};

export const ContentSpacer: FunctionComponent<ContentSpacerProps> = ({
  className,
}) => {
  return <div className={cn(styles.spacer, className)} />;
};

export type ContentProps = {
  className?: string;
  left?: React.ReactNode;
  leftVerticalAlign?: string;
  leftFixedWidthPixels?: number;
  main: React.ReactNode;
  mainHorizontalAlign?: string;
  right?: React.ReactNode;
  rightFixedWidthPixels?: number;
  rightClassName?: string;
  includebottomline?: boolean;
  bottom?: React.ReactNode;
  onClick?: React.MouseEventHandler;
};

export const ContentLayout: FunctionComponent<ContentProps> = ({
  className,
  left,
  leftVerticalAlign,
  leftFixedWidthPixels,
  main,
  mainHorizontalAlign,
  right,
  rightFixedWidthPixels,
  rightClassName,
  includebottomline,
  bottom,
  onClick,
}) => {
  return (
    <div
      className={cn(className, {
        [styles.content]: !!left,
        [styles.contentWithNoLeft]: !left,
        [styles.interactive]: onClick,
      })}
      onClick={onClick}
    >
      {left && (
        <>
          <ContentLeft
            verticalAlign={leftVerticalAlign}
            className={cn(styles.aboveLineLeftContent, {
              [styles.includebottomline]: includebottomline,
            })}
            style={
              leftFixedWidthPixels
                ? { width: `${leftFixedWidthPixels}px` }
                : undefined
            }
          >
            {left && left}
          </ContentLeft>
          <ContentSpacer
            className={cn(styles.spacer, {
              [styles.includebottomline]: includebottomline,
            })}
          />
        </>
      )}

      <div
        className={cn(styles.aboveLineMainAndRightContent, {
          [styles.includebottomline]: includebottomline,
        })}
      >
        <ContentMain horizontalAlign={mainHorizontalAlign}>{main}</ContentMain>
        <ContentRight
          className={rightClassName}
          style={
            rightFixedWidthPixels
              ? { width: `${rightFixedWidthPixels}px` }
              : undefined
          }
        >
          {right && right}
        </ContentRight>
      </div>

      <ContentBottom className={styles.belowLineContent}>
        {bottom && bottom}
      </ContentBottom>
    </div>
  );
};

export default ContentLayout;
