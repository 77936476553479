import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  FunctionComponent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ITEM_LIMIT_PER_CARD_COLUMN } from 'Consts/config';
import { ROUTES } from 'Consts/routes';

import Page from 'UI/Layout/Page';
import Background from 'UI/Layout/Background';
import PageHeader from 'UI/Layout/Page header';
import SectionHeader from 'UI/Components/Headers/Header section';

import type { DeviceGroup, Mac, ZoneDevice } from 'Consts/types';

import Alert from 'UI/Components/Alert';

import { BUTTON_THEMES } from 'UI/Elements/Button';
import AlertModal from 'UI/Elements/Modals/Alerts';

import * as selectors from 'State/selectors';
import * as actions from 'State/actions';
import useDevices from 'State/hooks/useDevices';
import useDeviceGroup from 'State/hooks/useDeviceGroup';

import useLayoutColumns from 'Utils/hooks/useLayout';

import ApprovedDevicesCards from '../Components/Devices/Approved devices';
import UnapprovedDevicesCard from '../Components/Devices/Unapproved devices';
import BlockedDevicesCard from '../Components/Devices/Blocked devices';
import { useMenuItems } from '../Components/Devices/Device menu items';
import { View } from '../Components/Devices/types';
import SecureDataUsage from '../Components/Secure data usage';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import Columns, { GridSectionGap } from '../../../UI/Layout/Grid';
import { AppDispatch } from 'State/store';
import DevicesCard from '../Components/Devices/Devices card';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import Grid from '../../../UI/Layout/Grid';
import AppTimeSecureZone from './Components/AppTime';
import useDeviceFilters from 'State/hooks/useDeviceFilters';
import { sortDevices } from '../Device management view all/utils';
import QuarantinedDevicesCard from '../Components/Devices/Quarantined devices/QuarantinedDevicesCard';

export type MacAndIPAddress = {
  mac: Mac;
  ip?: string;
};

export const Secure: FunctionComponent = () => {
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const navigate = useNavigate();
  const [columns, ref] = useLayoutColumns();

  const closeAlertTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const {
    allDevices: {
      isLoading: isLoadingDevices,
      errorMessage: errorMessageDevices,
      data,
    },
    defaultDevices,
  } = useDevices();

  const { sortingType } = useDeviceFilters();

  const {
    data: deviceGroups,
    isLoading: isLoadingGroups,
    errorMessage: errorMessageGroups,
  } = useDeviceGroup();

  const selectedGroup = useSelector(
    selectors.ui.devicesAndGroups.selectedGroup
  );

  const isLoading =
    (isLoadingDevices && !data) || (isLoadingGroups && !deviceGroups);
  const errorMessage = errorMessageDevices || errorMessageGroups;

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState<'new' | 'rename' | null>(null);
  const [groupName, setGroupName] = useState('');
  const [hasError, setHasError] = useState(false);
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch<AppDispatch>();

  const networkAccessData = useNetworkAccess();
  const secureNetworkAccessMode = networkAccessData?.data?.default?.purgatory;

  const handleOpenAlert = useCallback((type: 'new' | 'rename') => {
    setOpenAlert(type);
    setIsAlertOpen(true);
  }, []);

  const {
    MENU_ITEMS,
    actions: { saveGroup },
  } = useMenuItems({
    onNewGroup: () => handleOpenAlert('new'),
    onRenameGroup: () => handleOpenAlert('rename'),
  });

  const closeAlert = useCallback(() => {
    if (closeAlertTimeoutId.current) {
      return;
    }

    setIsAlertOpen(false);

    closeAlertTimeoutId.current = setTimeout(() => {
      if (
        !setGroupName ||
        !setHasError ||
        !setOpenAlert ||
        !closeAlertTimeoutId
      ) {
        return;
      }

      setGroupName('');
      setHasError(false);

      setOpenAlert(null);

      closeAlertTimeoutId.current = null;
    }, 300);
  }, []);

  const onGroupNameChange = (value: string) => {
    setHasError(Boolean(!value));
    setGroupName(value);
  };

  const handleSaveGroup = useCallback(() => {
    if (!groupName) {
      setHasError(true);
    } else {
      saveGroup(groupName, openAlert === 'new');
      closeAlert();
    }
  }, [groupName, saveGroup, openAlert, closeAlert]);

  useEffect(() => {
    setGroupName(selectedGroup ? selectedGroup.name : '');
  }, [selectedGroup, openAlert]);

  const ungroupedDevices = defaultDevices?.approved.filter(
    ({ mac }: ZoneDevice) => {
      return deviceGroups?.default
        ?.filter((group: DeviceGroup) => !group.standalone)
        .every((group: DeviceGroup) => {
          return !group.devices?.includes(mac);
        });
    }
  );

  const handleDevicesViewAllClick = useCallback(
    (view: View, groupId?: string) => {
      navigate(
        (ROUTES.zones.secure[view] +
          encodeURI(groupId ? `/${groupId}` : '')) as any
      );
    },
    [navigate]
  );

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.SecureZone,
      },
    });
  }, []);

  const sortedUnapprovedDevices = sortDevices(
    defaultDevices?.auto || [],
    sortingType
  ) as ZoneDevice[];
  const sortedUngroupedDevices = sortDevices(
    ungroupedDevices || [],
    sortingType
  ) as ZoneDevice[];
  const sortedBlockedDevices = sortDevices(
    defaultDevices?.blocked || [],
    sortingType
  ) as ZoneDevice[];
  const sortedQuarantinedDevices = sortDevices(
    defaultDevices?.quarantined || [],
    sortingType
  ) as ZoneDevice[];

  return (
    <>
      <AlertModal isOpen={isAlertOpen} onClose={closeAlert}>
        <Alert
          topProps={{
            label:
              openAlert === 'rename'
                ? t('devices.renameTheGroup')
                : t('devices.nameTheGroup'),
          }}
          middleProps={{
            listInputProps: {
              value: groupName,
              onChange: onGroupNameChange,
              placeholder: t('devices.groupAdmin.groupNameEditPlaceholder'),
              paragraph: hasError
                ? (t('devices.nameIsRequired') as string)
                : '',
              hasError: hasError,
            },
          }}
          bottomProps={{
            button1Props: {
              label: t('common.save'),
              onClick: handleSaveGroup,
            },
            button2Props: {
              label: t('common.cancel'),
              onClick: closeAlert,
              theme: BUTTON_THEMES.white,
            },
          }}
        />
      </AlertModal>

      <Page>
        <Background ref={ref}>
          <PageHeader
            label={t('common.secureZone')}
            buttonPillProps={{
              label: t('secureZone.addGroup'),
              onClick: () => {
                dispatch(actions.ui.devicesAndGroups.selectDevices([]));
                handleOpenAlert('new');
              },
            }}
          />
          <SectionHeader label={t('secureZone.overview')} />
          <Grid columnCount={columns}>
            <AppTimeSecureZone maxItems={6} />
            <SecureDataUsage span={1} />
          </Grid>
          <SectionHeader label={t('secureZone.devices')} />
          <Columns columnCount={columns}>
            {!!sortedUnapprovedDevices.length &&
              sortedUnapprovedDevices.length > 0 && (
                <UnapprovedDevicesCard
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                  devices={sortedUnapprovedDevices}
                  columnSpan={1}
                  itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
                  onViewAllClick={handleDevicesViewAllClick}
                  headerMenuItems={[
                    MENU_ITEMS.approveDevices,
                    MENU_ITEMS.blockDevices,
                  ]}
                  menuItems={[
                    MENU_ITEMS.approveDevice,
                    MENU_ITEMS.deviceSettings,
                    MENU_ITEMS.macAndIPAddress,
                    MENU_ITEMS.blockDevice,
                  ]}
                  infoTooltipLabel={t('devices.unapprovedTooltipSecure', {
                    ns: namespace,
                  })}
                />
              )}
            {!!sortedUngroupedDevices.length ||
            !!deviceGroups?.default.length ||
            !!defaultDevices?.blocked.length ? (
              <>
                <ApprovedDevicesCards
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                  devices={sortedUngroupedDevices}
                  columnSpan={1}
                  itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
                  onViewAllClick={handleDevicesViewAllClick}
                  headerMenuItems={[
                    ...(sortedUngroupedDevices.length &&
                    deviceGroups?.default.length
                      ? [MENU_ITEMS.changeGroup]
                      : []),
                    MENU_ITEMS.newGroup,
                    ...(sortedUngroupedDevices.length
                      ? [MENU_ITEMS.blockDevices]
                      : []),
                  ]}
                  menuItems={[
                    ...(sortedUngroupedDevices.length &&
                    deviceGroups?.default.length
                      ? [MENU_ITEMS.changeGroup]
                      : []),
                    MENU_ITEMS.newGroup,
                    MENU_ITEMS.deviceSettings,
                    MENU_ITEMS.deviceShareAccess,
                    MENU_ITEMS.macAndIPAddress,
                    MENU_ITEMS.blockDevice,
                  ]}
                  infoTooltipLabel={t('devices.approvedTooltipSecure', {
                    ns: namespace,
                  })}
                />

                {deviceGroups?.default
                  ?.filter((group: DeviceGroup) => !group.standalone)
                  .map((group: DeviceGroup) => {
                    const sortedApprovedDevices = (
                      sortDevices(
                        defaultDevices?.approved || [],
                        sortingType
                      ) as ZoneDevice[]
                    ).filter(({ mac }) => group.devices?.includes(mac));

                    return (
                      <ApprovedDevicesCards
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        key={group.groupId}
                        group={group}
                        devices={sortedApprovedDevices}
                        columnSpan={1}
                        itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
                        onViewAllClick={handleDevicesViewAllClick}
                        headerMenuItems={[
                          MENU_ITEMS.renameGroup,
                          MENU_ITEMS.groupShareAccess,
                          MENU_ITEMS.deleteGroup,
                          ...(sortedApprovedDevices.length
                            ? [MENU_ITEMS.blockDevices]
                            : []),
                        ]}
                        menuItems={[
                          MENU_ITEMS.changeGroup,
                          MENU_ITEMS.newGroup,
                          MENU_ITEMS.deviceSettings,
                          MENU_ITEMS.macAndIPAddress,
                          MENU_ITEMS.deviceShareAccess,
                          MENU_ITEMS.blockDevice,
                        ]}
                        infoTooltipLabel={t('devices.approvedTooltipSecure', {
                          ns: namespace,
                        })}
                      />
                    );
                  })}
                {!!sortedBlockedDevices.length && (
                  <BlockedDevicesCard
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    devices={sortedBlockedDevices}
                    columnSpan={1}
                    itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
                    onViewAllClick={handleDevicesViewAllClick}
                    headerMenuItems={[MENU_ITEMS.approveDevices]}
                    menuItems={[
                      MENU_ITEMS.approveDevice,
                      MENU_ITEMS.deviceSettings,
                      MENU_ITEMS.macAndIPAddress,
                    ]}
                  />
                )}
                {!!sortedQuarantinedDevices.length && (
                  <QuarantinedDevicesCard
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    devices={sortedQuarantinedDevices}
                    columnSpan={1}
                    itemLimitForOneColumn={ITEM_LIMIT_PER_CARD_COLUMN}
                    onViewAllClick={handleDevicesViewAllClick}
                    headerMenuItems={[
                      MENU_ITEMS.unquarantine1hr,
                      MENU_ITEMS.unquarantinePermanently,
                    ]}
                    menuItems={[
                      MENU_ITEMS.unquarantine1hr,
                      MENU_ITEMS.unquarantinePermanently,
                      MENU_ITEMS.deviceSettings,
                      MENU_ITEMS.macAndIPAddress,
                    ]}
                  />
                )}
              </>
            ) : secureNetworkAccessMode ? (
              <DevicesCard
                errorMessage={errorMessage}
                title={t('secureZone.devices')}
                columnSpan={1}
                devices={[]}
                headerMenuItems={[]}
                menuItems={[]}
              />
            ) : (
              <DevicesCard
                errorMessage={errorMessage}
                title={t('devices.approvedDevices')}
                columnSpan={1}
                devices={[]}
                headerMenuItems={[]}
                menuItems={[]}
              />
            )}
          </Columns>
          <GridSectionGap />
        </Background>
      </Page>
    </>
  );
};
