/* eslint-disable import/no-duplicates */
import i18n from 'i18next';
import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { enUS } from 'date-fns/locale';
import { enGB } from 'date-fns/locale';
import { enCA } from 'date-fns/locale';
import { fr } from 'date-fns/locale';
import { fi } from 'date-fns/locale';
import { frCA } from 'date-fns/locale';
import { ja } from 'date-fns/locale';
import { pt } from 'date-fns/locale';
import { es } from 'date-fns/locale';
import { de } from 'date-fns/locale';
import { et } from 'date-fns/locale';
/* eslint-enable import/no-duplicates */

import type { Period } from 'Consts/types';
import { PERIODS } from 'Consts/defintions';

const localizedHourFormatString = () => {
  const language = i18n.language;
  return LOCALES[language]?.formatLong?.time()?.charAt(0) === 'h'
    ? 'h aaa'
    : 'HH';
};

const localizedHourMinutesFormatString = () => {
  const language = i18n.language;
  return LOCALES[language]?.formatLong?.time()?.charAt(0) === 'h'
    ? 'h:mm aaa'
    : 'HH:mm';
};

export const formatGraphDateLabel = (date: Date, period: Period) => {
  const labelFormat =
    period === PERIODS.day ? localizedHourFormatString() : 'MMM d';
  const language = i18n.language;
  return format(date, labelFormat, {
    locale: LOCALES[language],
  });
};

export const formatHour = (date: Date) => {
  const language = i18n.language;
  return format(date, 'p', {
    locale: LOCALES[language],
  });
};

export const LOCALES: Record<string, Locale> = {
  'en-US': enUS,
  'en-GB': enGB,
  'en-CA': enCA,
  en: enUS,
  fr: fr,
  fi: fi,
  'fr-FR': fr,
  'fr-CA': frCA,
  ja: ja,
  pt: pt,
  'pt-PT': pt,
  es: es,
  de: de,
  'es-ES': es,
  'es-US': es,
  et: et,
  'et-ET': et,
};

export const formatRelativeToNow = (date: Date) => {
  if (date.getTime() === 0) {
    return i18n.t('common.moreThan30Days');
  }
  const language = i18n.language;
  return formatRelative(date, new Date(), {
    locale: LOCALES[language],
    weekStartsOn: 1,
  });
};

export const formatHours = (date: Date) => {
  const language = i18n.language;
  return format(date, localizedHourFormatString(), {
    locale: LOCALES[language],
  });
};
export const formatHoursMinutes = (date: Date) => {
  const language = i18n.language;
  return format(date, localizedHourMinutesFormatString(), {
    locale: LOCALES[language],
  });
};

export const formatDistanceFromNow = (date: Date) => {
  if (date.getTime() === 0) {
    return i18n.t('common.moreThan30Days');
  }
  const language = i18n.language;
  return formatDistanceToNowStrict(date, {
    locale: LOCALES[language],
  });
};

export const formatMonthDay = (date: Date) => {
  const language = i18n.language;
  return format(new Date(date), 'LLL d', {
    locale: LOCALES[language],
  });
};

export const formatSecondsToHoursAndMinutes = (sec: number) => {
  const unitSeconds = i18n.t('common.seconds');
  const unitMinute = i18n.t('common.min');
  const unitHour = i18n.t('common.hr');
  if (sec < 60) {
    return `${sec.toFixed(0)} ${unitSeconds}`;
  }
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor(hours >= 1 ? (sec % 3600) / 60 : sec / 60);
  // Unable to use formatDistanceToNowStrict due to the short name of the hours/minutes i.e. 'min' and 'hr'
  const hourText = `${hours}${unitHour}`;
  const minutesText = `${minutes}${unitMinute}`;

  return hours > 0 ? `${hourText} ${minutesText}` : minutesText;
};

export const formatMinutesToHours = (min: number) => {
  // TODO check this
  return formatSecondsToHoursAndMinutes(min * 60);
};
