import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from 'UI/Components/Alert';
import StandardListItem from 'UI/Components/Lists/List standard';

import ActionSheet from 'UI/Elements/Modals/Action sheet';
import { IconNames } from 'UI/Elements/Icon';
import Card from 'UI/Elements/Card';
import AlertModal from 'UI/Elements/Modals/Alerts';
import { BUTTON_THEMES } from 'UI/Elements/Button';

import { ShieldUpdateProps } from '..';

import styles from './style.module.css';
import {
  ShieldItems,
  ShieldSettingsProps,
} from 'State/slices/settings/securityPolicySlice';
import { Data } from 'State/utils';
import { AppliesToAllDevices, SecurityPolicy } from 'Consts/types';

export type modalActions =
  | 'overWriteLocation'
  | 'protectSettingsDependency'
  | null;

type ShieldActionProps = {
  type: ShieldUpdateProps | null;
  onClose: () => void;
  onChange: (data: ShieldUpdateProps) => void;
  parent: Element | null;
  shield: ShieldSettingsProps;
  security: 'general' | 'device' | 'person';
  securityPolicyData?: Data<SecurityPolicy>;
  locationSecurityAppliesToAllDevices?: Data<AppliesToAllDevices>;
};

type ContentAccessProps = {
  shield: ShieldSettingsProps;
  onClose: () => void;
  onChange: (data: ShieldUpdateProps) => void;
  modalUpdateProps: (value: modalActions, data?: ShieldUpdateProps) => void;
  securityPolicyData?: Data<SecurityPolicy>;
  type: ShieldItems | null;
  security?: 'general' | 'device' | 'person';
  locationSecurityAppliesToAllDevices?: Data<AppliesToAllDevices>;
};

type DropdownMenuActionProps = {
  type: ShieldItems | null;
  shield: ShieldSettingsProps;
  onClose: () => void;
  security?: 'general' | 'device' | 'person';
  onChange: (data: ShieldUpdateProps) => void;
  modalUpdateProps: (value: modalActions, data?: ShieldUpdateProps) => void;
  securityPolicyData?: Data<SecurityPolicy>;
  locationSecurityAppliesToAllDevices?: Data<AppliesToAllDevices>;
};

type ShieldProtectionProps = {
  onChange: (data: ShieldUpdateProps) => void;
  onClose: () => void;
  type: ShieldUpdateProps;
  modalUpdateProps: (value: modalActions, data?: ShieldUpdateProps) => void;
};

const ContentAccessAction: FunctionComponent<ContentAccessProps> = ({
  shield,
  onClose,
  onChange,
  type,
  modalUpdateProps,
  securityPolicyData,
  security,
  locationSecurityAppliesToAllDevices,
}) => {
  const { t } = useTranslation();
  const securityData = securityPolicyData?.data!;

  const locationCustomSetting = useMemo(() => {
    if (!locationSecurityAppliesToAllDevices) {
      return;
    }
    return locationSecurityAppliesToAllDevices?.data?.[type!];
  }, [locationSecurityAppliesToAllDevices]);

  const selectedOption = useMemo(() => {
    if (
      locationCustomSetting !== undefined &&
      locationCustomSetting === false
    ) {
      return 'custom';
    } else if (
      (locationCustomSetting === undefined || locationCustomSetting === true) &&
      shield[type!]
    ) {
      return 'on';
    } else if (
      (locationCustomSetting === undefined || locationCustomSetting === true) &&
      !shield[type!]
    ) {
      return 'off';
    }
  }, [locationCustomSetting, type]);

  const handleAction = (hasLimits: boolean) => {
    if (securityData) {
      if (!securityData.appliesToAllDevices.workAppropriate) {
        modalUpdateProps('overWriteLocation', { key: type!, value: hasLimits });
      } else {
        onChange({ key: 'workAppropriate', value: hasLimits });
        onClose();
      }
    } else if (!securityData) {
      onChange({ key: 'workAppropriate', value: hasLimits });
      onClose();
    }
  };

  return (
    <Card noBottomPadding>
      <StandardListItem
        onClick={() => handleAction(false)}
        L2Props={{ label: t('settings.noLimits') }}
        RProps={
          selectedOption === 'off'
            ? {
                icon1Props: { name: IconNames.Check },
              }
            : undefined
        }
        ariaLabel={t('settings.noLimits')}
      />

      <StandardListItem
        onClick={() => handleAction(true)}
        L2Props={{
          label: t('settings.workAppropriate'),
          paragraph: t('settings.workAppropriateText'),
        }}
        RProps={
          selectedOption === 'on'
            ? {
                icon1Props: { name: IconNames.Check },
              }
            : undefined
        }
        ariaLabel={t('settings.workAppropriate')}
      />

      {security === 'general' && selectedOption === 'custom' && (
        <StandardListItem
          onClick={() => {}}
          L2Props={{
            label: t('settings.custom'),
          }}
          RProps={
            selectedOption === 'custom'
              ? {
                  icon1Props: { name: IconNames.Check },
                }
              : undefined
          }
          ariaLabel={t('settings.custom')}
        />
      )}
    </Card>
  );
};

const DropdownMenuAction: FunctionComponent<DropdownMenuActionProps> = ({
  type,
  shield,
  onClose,
  onChange,
  security,
  modalUpdateProps,
  securityPolicyData,
  locationSecurityAppliesToAllDevices,
}) => {
  const { t } = useTranslation();
  const securityData = securityPolicyData?.data!;

  const locationCustomSetting = useMemo(() => {
    if (!locationSecurityAppliesToAllDevices) {
      return;
    }
    return locationSecurityAppliesToAllDevices?.data?.[type!];
  }, [locationSecurityAppliesToAllDevices]);

  const selectedOption = useMemo(() => {
    if (
      locationCustomSetting !== undefined &&
      locationCustomSetting === false
    ) {
      return 'custom';
    } else if (
      (locationCustomSetting === undefined || locationCustomSetting === true) &&
      shield[type!]
    ) {
      return 'on';
    } else if (
      (locationCustomSetting === undefined || locationCustomSetting === true) &&
      !shield[type!]
    ) {
      return 'off';
    }
  }, [locationCustomSetting, type]);

  const handleLocationSettings = (hasLimits: boolean) => {
    if (type === 'secureAndProtect') {
      if (!securityData.appliesToAllDevices.secureAndProtect) {
        modalUpdateProps('overWriteLocation', { key: type!, value: hasLimits });
      } else if (
        securityData.appliesToAllDevices.secureAndProtect &&
        securityData.iotProtect &&
        !hasLimits
      ) {
        modalUpdateProps('protectSettingsDependency', {
          key: type!,
          value: hasLimits,
        });
      } else {
        onChange({ key: type!, value: hasLimits });
        onClose();
      }
    }

    if (type === 'iotProtect') {
      if (!securityData.appliesToAllDevices.iotProtect) {
        modalUpdateProps('overWriteLocation', { key: type!, value: hasLimits });
      } else if (
        securityData.appliesToAllDevices.iotProtect &&
        hasLimits &&
        !shield.secureAndProtect
      ) {
        modalUpdateProps('protectSettingsDependency', {
          key: type!,
          value: hasLimits,
        });
      } else {
        onChange({ key: type!, value: hasLimits });
        onClose();
      }
    }

    if (type === 'adBlocking') {
      if (!securityData.appliesToAllDevices.adBlocking) {
        modalUpdateProps('overWriteLocation', { key: type!, value: hasLimits });
      } else {
        onChange({ key: type!, value: hasLimits });
        onClose();
      }
    }

    if (type === 'appTime') {
      if (securityData && locationSecurityAppliesToAllDevices) {
        if (!locationSecurityAppliesToAllDevices?.data?.appTime) {
          modalUpdateProps('overWriteLocation', {
            key: type!,
            value: hasLimits,
          });
        } else {
          onChange({ key: type, value: hasLimits });
          onClose();
        }
      }
    }
  };

  const handleAction = (hasLimits: boolean) => {
    modalUpdateProps(null);
    if (securityData) {
      handleLocationSettings(hasLimits);
    } else {
      if (!hasLimits && type === 'secureAndProtect' && shield.iotProtect) {
        modalUpdateProps('protectSettingsDependency', {
          key: type!,
          value: hasLimits,
        });
      } else if (
        hasLimits &&
        type === 'iotProtect' &&
        !shield.secureAndProtect
      ) {
        modalUpdateProps('protectSettingsDependency', {
          key: type!,
          value: hasLimits,
        });
      } else if (!hasLimits) {
        onChange({ key: type!, value: hasLimits });
        onClose();
      } else if (hasLimits) {
        onChange({ key: type!, value: hasLimits });
        onClose();
      }
    }
  };

  return (
    <Card noBottomPadding>
      <StandardListItem
        onClick={() => handleAction(true)}
        L2Props={{ label: t('common.on') }}
        RProps={
          selectedOption === 'on'
            ? {
                icon1Props: { name: IconNames.Check },
              }
            : undefined
        }
        ariaLabel={t('common.on')}
      />

      <StandardListItem
        onClick={() => handleAction(false)}
        L2Props={{
          label: t('common.off'),
        }}
        RProps={
          selectedOption === 'off'
            ? {
                icon1Props: { name: IconNames.Check },
              }
            : undefined
        }
        ariaLabel={t('common.off')}
      />

      {security === 'general' && selectedOption === 'custom' && (
        <StandardListItem
          onClick={() => {}}
          L2Props={{
            label: t('settings.custom'),
          }}
          RProps={
            selectedOption === 'custom'
              ? {
                  icon1Props: { name: IconNames.Check },
                }
              : undefined
          }
          ariaLabel={t('settings.custom')}
        />
      )}
    </Card>
  );
};

const OnlineProtectionAction: FunctionComponent<ShieldProtectionProps> = ({
  onClose,
  onChange,
  type,
  modalUpdateProps,
}) => {
  const { t } = useTranslation();

  return (
    <Alert
      topProps={{
        label: t('settings.OnlineProtectionChangeLabel'),
        paragraph: t('settings.OnlineProtectionChangeText'),
        className: styles.textCenter,
      }}
      bottomProps={{
        button1Props: {
          label: t('settings.enableOnlineProtection'),
          onClick: () => {
            onChange(type);
            modalUpdateProps(null);
          },
        },
        button2Props: {
          label: t('common.cancel'),
          onClick: onClose,
          theme: BUTTON_THEMES.white,
        },
      }}
    />
  );
};

const OverwriteLocationAction: FunctionComponent<ShieldProtectionProps> = ({
  onClose,
  onChange,
  type,
  modalUpdateProps,
}) => {
  const { t } = useTranslation();

  return (
    <Alert
      topProps={{
        label: t('settings.OverwriteLocationChangeLabel'),
        paragraph: t('settings.OverwriteLocationChangeText'),
        className: styles.textCenter,
      }}
      bottomProps={{
        button1Props: {
          label: t('settings.overwrite'),
          onClick: () => {
            onChange(type);
            modalUpdateProps(null);
          },
        },
        button2Props: {
          label: t('common.cancel'),
          onClick: onClose,
          theme: BUTTON_THEMES.white,
        },
      }}
    />
  );
};

const AdvancedIoTProtectionAction: FunctionComponent<ShieldProtectionProps> = ({
  onClose,
  onChange,
  type,
  modalUpdateProps,
}) => {
  const { t } = useTranslation();

  return (
    <Alert
      topProps={{
        label: t('settings.AdvancedIoTProtectionChangeLabel') + '?',
        paragraph: t('settings.AdvancedIoTProtectionChangeText'),
        className: styles.textCenter,
      }}
      bottomProps={{
        button1Props: {
          label: t('settings.AdvancedIoTProtectionChangeLabel'),
          onClick: () => {
            onChange(type);
            modalUpdateProps(null);
          },
        },
        button2Props: {
          label: t('common.cancel'),
          onClick: onClose,
          theme: BUTTON_THEMES.white,
        },
      }}
    />
  );
};

const ShieldActions: FunctionComponent<ShieldActionProps> = ({
  type,
  onClose,
  parent,
  onChange,
  shield,
  security,
  securityPolicyData,
  locationSecurityAppliesToAllDevices,
}) => {
  const actionType = type ? type.key : null;
  const [modalType, setModalType] = useState<modalActions>(null);

  const [updatedValue, setUpdatedValue] = useState<ShieldUpdateProps>();
  const updateModalProps = (
    value: modalActions,
    updatedValue?: ShieldUpdateProps
  ) => {
    setModalType(value);
    setUpdatedValue(updatedValue);
  };

  if (!type) {
    return null;
  }

  return (
    <div>
      <ActionSheet
        isOpen={actionType === 'workAppropriate'}
        onClose={() => {
          setModalType(null);
          onClose();
        }}
        parent={parent}
      >
        <ContentAccessAction
          shield={shield}
          onClose={() => {
            setModalType(null);
            onClose();
          }}
          onChange={onChange}
          type={actionType}
          security={security}
          modalUpdateProps={updateModalProps}
          securityPolicyData={securityPolicyData}
          locationSecurityAppliesToAllDevices={
            locationSecurityAppliesToAllDevices
          }
        />
      </ActionSheet>

      <ActionSheet
        isOpen={actionType !== 'workAppropriate'}
        onClose={onClose}
        parent={parent}
      >
        <DropdownMenuAction
          type={actionType}
          shield={shield}
          onClose={onClose}
          onChange={onChange}
          modalUpdateProps={updateModalProps}
          securityPolicyData={securityPolicyData}
          locationSecurityAppliesToAllDevices={
            locationSecurityAppliesToAllDevices
          }
          security={security}
        />
      </ActionSheet>

      <AlertModal
        isOpen={
          actionType === 'secureAndProtect' &&
          modalType === 'protectSettingsDependency'
        }
        onClose={() => {
          setModalType(null);
          onClose();
        }}
      >
        <AdvancedIoTProtectionAction
          onClose={() => {
            setModalType(null);
            onClose();
          }}
          onChange={onChange}
          type={updatedValue!}
          modalUpdateProps={updateModalProps}
        />
      </AlertModal>

      <AlertModal
        isOpen={modalType === 'overWriteLocation'}
        onClose={() => {
          setModalType(null);
          onClose();
        }}
      >
        <OverwriteLocationAction
          onClose={() => {
            setModalType(null);
            onClose();
          }}
          onChange={onChange}
          type={updatedValue!}
          modalUpdateProps={updateModalProps}
        />
      </AlertModal>

      <AlertModal
        isOpen={
          actionType === 'iotProtect' &&
          modalType === 'protectSettingsDependency' &&
          !shield.secureAndProtect
        }
        onClose={() => {
          setModalType(null);
          onClose();
        }}
      >
        <OnlineProtectionAction
          onClose={() => {
            setModalType(null);
            onClose();
          }}
          onChange={onChange}
          type={updatedValue!}
          modalUpdateProps={updateModalProps}
        />
      </AlertModal>
    </div>
  );
};

export default ShieldActions;
