import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NetworkType } from 'Consts/types';
import { NETWORK_IDS } from 'Consts/defintions';

import {
  SimpleSidePanel,
  SidePanelContentWrapper,
  SimpleSidePanelProps,
} from 'UI/Layout/Side panel';

import InputListItem, {
  InputListItemProps,
} from 'UI/Components/Lists/List input';

import { IconNames } from 'UI/Elements/Icon';
import Card from 'UI/Elements/Card';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';

import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';

import { useSidepanel } from 'Utils/hooks/useSidepanel';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import cn from 'classnames';

type WifiEditSidepanelProps = {
  listInputProps: InputListItemProps;
  onSave: React.MouseEventHandler;
} & SimpleSidePanelProps;

type WithNetworkId = {
  networkId: NetworkType;
};

const WifiEditSidepanel: FunctionComponent<WifiEditSidepanelProps> = ({
  listInputProps,
  title,
  onSave,
}) => {
  const { t } = useTranslation();

  return (
    <SimpleSidePanel title={title}>
      <SidePanelContentWrapper className={styles.edit}>
        <Card>
          <InputListItem
            {...listInputProps}
            prefixIcon={IconNames.Edit}
            className={cn(colorStyles.still400, styles.inputListItem)}
          />
        </Card>

        <Button
          label={t('common.apply')}
          theme={BUTTON_THEMES.black}
          onClick={onSave}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export const WifiNameEditSidepanel: FunctionComponent<WithNetworkId> = ({
  networkId,
}) => {
  const { closeSidepanel } = useSidepanel();
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();

  const dispatch = useDispatch<AppDispatch>();
  const wifiData = useSelector(
    networkId === NETWORK_IDS.default
      ? selectors.settings.wifi.default
      : selectors.settings.wifi.employee
  );
  const guestWifiData = useSelector(selectors.settings.wifi.guest);

  const data = networkId === NETWORK_IDS.guest ? guestWifiData : wifiData;

  const [name, setName] = useState(data?.ssid || '');
  const [error, setError] = useState('');
  const [subtext, setSubtext] = useState('');
  const onSuccess = useCallback(() => {
    dispatch(
      actions.ui.miniBanner.notify({
        label: t('settings.updateText', { field: t('settings.name') }),
        state: 'set',
      })
    );

    setError('');
    closeSidepanel();
  }, [closeSidepanel, dispatch, t]);

  const onError = useCallback(
    (msg?: string) => {
      if (msg) {
        setError(msg);
        setSubtext(msg);
      }
    },
    [dispatch]
  );

  const handleSave = useCallback(() => {
    if (data?.ssid && name === data.ssid) {
      return;
    }

    if (!name) {
      const error =
        t('settings.validationNotEmpty', { field: t('settings.name') }) || '';
      setSubtext(error);
      setError(error);

      return;
    }

    const action =
      networkId === NETWORK_IDS.default
        ? actions.settings.wifi.updateDefaultWifiData
        : networkId === NETWORK_IDS.employee
        ? actions.settings.wifi.updateEmployeeWifiData
        : actions.settings.wifi.updateGuestsWifiData;

    dispatch(action({ ssid: name }, onSuccess, onError));
  }, [data?.ssid, dispatch, name, networkId, onError, onSuccess, t]);

  return (
    <WifiEditSidepanel
      listInputProps={{
        smallLabel: t('settings.wifiName', { ns: namespace }) || '',
        value: name,
        paragraph: subtext,
        onChange: setName,
        hasError: !!error,
        prefixIcon: IconNames.Wifi,
        onSubmit: handleSave,
      }}
      title={t('settings.editWifiName', { ns: namespace })}
      onSave={handleSave}
    />
  );
};

export const WifiPasswordEditSidepanel: FunctionComponent<WithNetworkId> = ({
  networkId,
}) => {
  const { closeSidepanel } = useSidepanel();
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const wifiData = useSelector(
    networkId === NETWORK_IDS.default
      ? selectors.settings.wifi.default
      : selectors.settings.wifi.employee
  );

  const [password, setPassword] = useState(wifiData?.encryptionKey || '');
  const [error, setError] = useState('');
  const namespace = useCspTranslationNamespace();
  const TIP = t('settings.passwordEditTip', { ns: namespace });
  const [subtext, setSubtext] = useState(TIP);

  const onSuccess = useCallback(() => {
    dispatch(
      actions.ui.miniBanner.notify({
        label: t('settings.updateText', { field: t('settings.password') }),
        state: 'set',
      })
    );

    setError('');
    closeSidepanel();
  }, [closeSidepanel, dispatch, t]);

  const onError = useCallback(
    (msg?: string) => {
      if (msg) {
        setSubtext(msg);
        setError(msg);
      }
    },
    [dispatch]
  );

  const handleSave = useCallback(() => {
    if (wifiData?.encryptionKey && password === wifiData.encryptionKey) {
      return;
    }

    if (!password) {
      const error =
        t('settings.validationNotEmpty', { field: t('settings.password') }) ||
        '';
      setSubtext(error);
      setError(error);

      return;
    }

    const action =
      networkId === NETWORK_IDS.default
        ? actions.settings.wifi.updateDefaultWifiData
        : actions.settings.wifi.updateEmployeeWifiData;

    dispatch(action({ encryptionKey: password }, onSuccess, onError));
  }, [
    wifiData?.encryptionKey,
    password,
    networkId,
    dispatch,
    onSuccess,
    onError,
    t,
  ]);

  return (
    <WifiEditSidepanel
      listInputProps={{
        smallLabel: t('settings.wifiPassword', { ns: namespace }) || '',
        value: password,
        paragraph: subtext,
        onChange: setPassword,
        hasError: !!error,
        prefixIcon: IconNames.Key,
        onSubmit: handleSave,
      }}
      title={t('settings.editWifiPassword', { ns: namespace })}
      onSave={handleSave}
    />
  );
};
