import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Period, SpeedDimension } from 'Consts/types';

import Filters from 'UI/Reusable/Filters/Filters';

import PillWeb from 'UI/Elements/Pill web';
import { IconNames } from 'UI/Elements/Icon';

import styles from './style.module.css';

type PeriodProps = {
  key: Period;
  i18labelKey: string;
};

type SpeedDimensionProps = {
  key: SpeedDimension;
  i18labelKey: string;
};

const PERIOD_LIST: Partial<Record<Period, PeriodProps>> = {
  day: {
    key: 'day',
    i18labelKey: 'common.today',
  },
  week: {
    key: 'week',
    i18labelKey: 'common.7days',
  },
  month: {
    key: 'month',
    i18labelKey: 'common.30days',
  },
};

const SPEED_DIMENSIONS_LIST: Record<SpeedDimension, SpeedDimensionProps> = {
  download: {
    key: 'download',
    i18labelKey: 'common.download',
  },
  upload: {
    key: 'upload',
    i18labelKey: 'common.upload',
  },
};

type WlanFilterProps = {
  period: Period;
  setPeriod: Dispatch<SetStateAction<Period>>;
  speedDimension: SpeedDimension;
  setSpeedDimension: Dispatch<SetStateAction<SpeedDimension>>;
};

export const MobileWlanFilters = ({
  period,
  setPeriod,
  speedDimension,
  setSpeedDimension,
}: WlanFilterProps) => {
  const { t } = useTranslation();
  const parentRef = useRef<Element | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = useCallback((ev: React.MouseEvent) => {
    setIsMenuOpen(true);
    parentRef.current = ev.currentTarget;
  }, []);

  const handleOnClose = useCallback(() => setIsMenuOpen(false), []);

  const commonFilter = { filterKey: 'Filter', label: t('common.filter') };

  const periodList = useMemo(() => {
    return Object.values(PERIOD_LIST).map((item, i) => {
      return {
        label: t(item.i18labelKey),
        onClick: () => setPeriod(item.key),
        iconName: period === item.key ? IconNames.Check : undefined,
      };
    });
  }, [period, setPeriod, t]);

  const speedDimensionsList = useMemo(() => {
    return Object.values(SPEED_DIMENSIONS_LIST).map((item, i) => {
      return {
        label: t(item.i18labelKey),
        onClick: () => setSpeedDimension(item.key),
        iconName: speedDimension === item.key ? IconNames.Check : undefined,
      };
    });
  }, [setSpeedDimension, speedDimension, t]);

  return (
    <Filters
      className={styles.filterBlock}
      handleOnClose={handleOnClose}
      menuItems={[...periodList, { isDivider: true }, ...speedDimensionsList]}
      parent={parentRef.current}
      isMenuOpen={isMenuOpen}
      handleClick={handleClick}
      filters={[commonFilter]}
    />
  );
};

export const WlanFilters = ({
  period,
  setPeriod,
  speedDimension,
  setSpeedDimension,
}: WlanFilterProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.filterBlock}>
        {Object.keys(PERIOD_LIST).map((item) => {
          const currentPeriod = PERIOD_LIST[item as Period];
          const isActive = currentPeriod?.key === period;

          return (
            <PillWeb
              isSelected={isActive}
              key={item}
              label={t(currentPeriod?.i18labelKey || '')}
              onClick={() => {
                if (currentPeriod?.key) setPeriod(currentPeriod.key);
              }}
            />
          );
        })}
      </div>

      <div className={styles.filterBlock}>
        {Object.keys(SPEED_DIMENSIONS_LIST).map((dimension) => {
          const currentDimension =
            SPEED_DIMENSIONS_LIST[dimension as SpeedDimension];
          const isActive = currentDimension.key === speedDimension;

          return (
            <PillWeb
              isSelected={isActive}
              key={dimension}
              label={t(currentDimension.i18labelKey)}
              onClick={() => setSpeedDimension(currentDimension.key)}
            />
          );
        })}
      </div>
    </div>
  );
};
