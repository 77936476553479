import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ZoneDevice } from 'Consts/types';

import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';

import styles from './style.module.css';
import Card from 'UI/Elements/Card';
import InputListItem from 'UI/Components/Lists/List input';
import { IconNames } from 'UI/Elements/Icon';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import ShieldSettings from 'Pages/Settings/Left/Components/Shield settings';
import useLocations from 'State/hooks/useLocations';
import useDeviceSecurityPolicy from 'State/hooks/useDeviceSecurityPolicy';
import { Heading2Medium } from 'UI/Elements/Typography';

type DeviceSettingsProps = {
  device?: ZoneDevice;
};

const DeviceSettings: FunctionComponent<DeviceSettingsProps> = ({ device }) => {
  const { t } = useTranslation();
  const nickname = device?.nickname || device?.name!;
  const [deviceNickName, setDeviceNickName] = useState('');
  const deviceMac = device?.mac!;
  const { activeLocation } = useLocations();
  const { deviceSecurityPolicy, deviceShieldSettings } =
    useDeviceSecurityPolicy(deviceMac);
  const [error, setError] = useState({ deviceNickName: '' });
  const dispatch = useDispatch<AppDispatch>();
  const { closeSidepanel } = useSidepanel();

  const handleChangeDeviceNickName = useCallback((value: string) => {
    setDeviceNickName(value);
  }, []);

  useEffect(() => {
    if (!nickname) {
      setDeviceNickName('');
    }
    setDeviceNickName(nickname);
  }, [device]);

  const handleSubmit = useCallback(async () => {
    if (!deviceNickName || !device?.mac) {
      setError((prev) => ({
        ...prev,
        deviceNickName: t('onboarding.errorEmptyField'),
      }));
      return;
    }

    if (deviceNickName.length > 32) {
      setError((prev) => ({
        ...prev,
        deviceNickName: t('onboarding.errorMaxChars'),
      }));
      return;
    }

    dispatch(actions.device.updateDevice(device?.mac, deviceNickName));

    closeSidepanel();
  }, [deviceNickName, dispatch, t]);

  return (
    <SimpleSidePanel title={t('devices.deviceSettings.title')}>
      <SidePanelContentWrapper className={styles.sidePanelContentWrapper}>
        <Heading2Medium className={styles.deviceName}>
          {device?.name}
        </Heading2Medium>
        <Card>
          <div className={styles.listInputContainer}>
            <InputListItem
              className={styles.listInput}
              prefixIcon={IconNames.Edit}
              value={deviceNickName ? deviceNickName : ''}
              smallLabel={t('devices.deviceSettings.deviceName') as string}
              onChange={handleChangeDeviceNickName}
              hasError={Boolean(error.deviceNickName)}
              paragraph={error.deviceNickName}
            />
          </div>
        </Card>
        <ShieldSettings
          locationName={activeLocation.data?.name || ''}
          deviceSecurityPolicyData={deviceSecurityPolicy}
          shieldSettingsData={deviceShieldSettings}
          security="device"
          device={device}
          showShieldText={false}
          shieldSettingsForDevice
        />
        <Button
          className={styles.deviceSettingsDoneBtn}
          theme={BUTTON_THEMES.black}
          label={t('common.done')}
          onClick={() => handleSubmit()}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default DeviceSettings;
