import Card from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import StandardListItem from 'UI/Components/Lists/List standard';
import { useTranslation } from 'react-i18next';
import Divider from 'UI/Elements/Divider';
import { Body3 } from 'UI/Elements/Typography';
import colorStyles from 'Styles/colors.module.css';
import cn from 'classnames';
import AlertModal from 'UI/Elements/Modals/Alerts';
import Alert from 'UI/Components/Alert';
import styles from './style.module.css';
import useEmployeeLoginDatabase from 'State/hooks/useEmployeeLoginDatabase';
import Loader from 'UI/Elements/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import useBlockedEmployees from 'State/hooks/useBlockedEmployees';

export type EmployeeLoginProps = {
  onEditEmployeeLogin?: () => void;
  onDisableFeature: () => void;
  onOpenBlockedEmployees?: () => void;
};

const EmployeeLoginSetting: FunctionComponent<EmployeeLoginProps> = ({
  onEditEmployeeLogin,
  onDisableFeature,
  onOpenBlockedEmployees,
}) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch<AppDispatch>();
  const employeeLoginEnabled = useSelector(selectors.settings.employeeLogin)
    .data?.databaseData.enabled;
  const employeeLoginLiabilityDisclaimer = useSelector(
    selectors.settings.employeeLogin
  ).data?.databaseData.liabilityDisclaimer;
  const [isFeatureEnabled, setIsFeatureEnabled] =
    useState(employeeLoginEnabled);
  const { data: blockedEmployees } = useBlockedEmployees();

  useEffect(() => {
    setIsFeatureEnabled(employeeLoginEnabled);
  }, [employeeLoginEnabled]);

  const handleEmployeeConsentChange = () => {
    if (employeeLoginEnabled && employeeLoginLiabilityDisclaimer) {
      onDisableFeature();
    } else {
      setIsFeatureEnabled(!employeeLoginEnabled);

      trackEvent({
        eventName: MixPanelEvents.EMPLOYEE_LOGIN_ENABLEMENT,
        additionalContent: {
          ENABLE: !employeeLoginEnabled,
        },
      });

      dispatch(
        actions.settings.employeeLogin.updateEmployeeLoginEnabled({
          enabled: !employeeLoginEnabled,
        })
      );
    }
  };
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { isLoading, errorMessage } = useEmployeeLoginDatabase();

  useEffect(() => {
    !!errorMessage && setShowErrorModal(true);
  }, [errorMessage]);

  useEffect(() => {
    employeeLoginEnabled && setIsFeatureEnabled(employeeLoginEnabled);
  }, [employeeLoginEnabled]);

  return (
    <>
      <Card noBottomPadding>
        {!errorMessage && isLoading && (
          <div className={styles.employeeLoginSettingLoader}>
            <Loader />
          </div>
        )}
        {!isLoading && !errorMessage && (
          <>
            <StandardListItem
              L1Props={{ iconProps: { name: IconNames.PersonProfile } }}
              L2Props={{ label: t('settings.employeeLogin') }}
              RProps={{
                toggleProps: {
                  onChange: handleEmployeeConsentChange,
                  checked: isFeatureEnabled,
                  ariaLabel: t('settings.employeeLogin'),
                },
              }}
              ariaLabel={t('settings.employeeLogin')}
            />
            {employeeLoginEnabled && (
              <>
                {blockedEmployees?.length ? (
                  <>
                    <Divider />
                    <StandardListItem
                      L1Props={{ iconProps: { name: IconNames.Hand } }}
                      L2Props={{ label: t('settings.blockedEmployees') }}
                      RProps={{
                        icon2Props: {
                          name: IconNames.ChevronRight,
                          onClick: onOpenBlockedEmployees,
                        },
                      }}
                      ariaLabel={t('settings.blockedEmployees')}
                    />
                  </>
                ) : null}
                <Divider />
                <StandardListItem
                  L2Props={{
                    label: t('common.edit'),
                  }}
                  RProps={{
                    icon2Props: {
                      name: IconNames.Edit,
                      onClick: onEditEmployeeLogin,
                      tooltipLabel: t('common.edit'),
                    },
                  }}
                  ariaLabel={t('common.edit')}
                />
              </>
            )}
          </>
        )}
      </Card>

      {!isLoading && !errorMessage && (
        <Body3 className={cn(colorStyles.still400, styles.description)}>
          {t('settings.employeeLoginConfigurationInfo')}
        </Body3>
      )}
      <AlertModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      >
        <Alert
          topProps={{
            label: t('settings.employeeLoginConfigurationUnavailable'),
            paragraph: `${errorMessage}. ${t('errors.pleaseTryAgain')}.`,
            className: styles.modalBody,
          }}
          bottomProps={{
            button1Props: {
              label: t('common.ok'),
              onClick: () => setShowErrorModal(false),
            },
          }}
        />
      </AlertModal>
    </>
  );
};

export default EmployeeLoginSetting;
