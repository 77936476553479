import cn from 'classnames';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { IconNames, Props as IconProps } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { Body1, Body1Medium, Heading3Medium } from 'UI/Elements/Typography';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import useWindowDimensions from 'Utils/hooks/useWindowSize';
import styles from './style.module.css';

type SidePanelContentWrapperProps = JSX.IntrinsicElements['div'];

type SidePanelHeaderProps = {
  leftIconProps?: IconProps;
  leftLabel?: string;
  centerLabel?: string;
  rightLabel?: string;
  rightIconProps?: IconProps;
  heading3M?: boolean;
  className?: string;
};

export type SimpleSidePanelProps = {
  className?: string;
  children?: React.ReactNode;

  title: string;
  rightIconProps?: IconProps;
  heading3M?: boolean;
  headerClassName?: string;
  onClose?: () => void;
};

export const SidePanelContentWrapper: FunctionComponent<
  PropsWithChildren<SidePanelContentWrapperProps>
> = ({ children, className }) => (
  <div className={cn(styles.sidePanelContentWrapper, className)}>
    {children}
  </div>
);

export const SidePanelHeader: FunctionComponent<SidePanelHeaderProps> = ({
  leftIconProps,
  leftLabel,
  centerLabel,
  rightLabel,
  rightIconProps,
  heading3M,
  className,
}) => (
  <div className={cn(styles.sidePanelHeader, className)}>
    <div className={styles.leftHeader}>
      {leftIconProps && <Icon {...leftIconProps} />}
      {leftLabel && <Body1>{leftLabel}</Body1>}
    </div>

    {heading3M ? (
      <Heading3Medium className={styles.centerHeader}>
        {centerLabel}
      </Heading3Medium>
    ) : (
      <Body1Medium className={styles.centerHeader}>{centerLabel}</Body1Medium>
    )}

    <div className={styles.rightHeader}>
      {rightLabel && <Body1>{rightLabel}</Body1>}
      {rightIconProps && <Icon {...rightIconProps} />}
    </div>
  </div>
);

export const SimpleSidePanel: FunctionComponent<SimpleSidePanelProps> = ({
  className,
  title,
  children,
  rightIconProps,
  heading3M,
  headerClassName,
  onClose,
}) => {
  const { t } = useTranslation();
  const { closeSidepanel } = useSidepanel();
  const { width } = useWindowDimensions();

  const panelContainerRef = React.useRef<HTMLDivElement>(null);

  const handleCloseSidepanel = () => {
    if (onClose) {
      onClose();
    } else {
      closeSidepanel();
    }
  };

  let leftIconProps = {
    name: IconNames.ArrowRightStop,
    tooltipLabel: t('common.collapse'),
    onClick: handleCloseSidepanel,
    shape: IconShape.square,
  };

  if (width < 473) {
    leftIconProps = {
      name: IconNames.ChevronLeft,
      tooltipLabel: t('common.back'),
      onClick: handleCloseSidepanel,
      shape: IconShape.square,
    };
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (panelContainerRef.current) {
        const firstFocusableElement =
          panelContainerRef.current.querySelector('[tabindex]');
        if (firstFocusableElement) {
          (firstFocusableElement as HTMLElement).focus();
        }
      }
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      ref={panelContainerRef}
      className={cn(styles.sidePanel, className)}
      role="complementary"
      aria-label={title}
    >
      <SidePanelHeader
        leftIconProps={leftIconProps}
        centerLabel={title}
        rightIconProps={rightIconProps}
        heading3M={heading3M}
        className={headerClassName}
      />
      {children}
    </div>
  );
};
