import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import { getMetasaurusDeviceIconUrl } from 'Utils/metasaurusUtils';

export type Props = {
  className?: string;
  fileName: string;
  onClick?: React.MouseEventHandler;
  size?: 'medium' | 'small';
};

const DeviceIcon = ({
  className,
  fileName,
  onClick,
  size = 'medium',
}: Props) => {
  const cloud = useSelector(selectors.auth.cloud);
  const [url, setUrl] = useState('');
  getMetasaurusDeviceIconUrl(cloud, fileName, size)
    .then((url) => {
      setUrl(url);
    })
    .catch((err) => console.error(err));

  return (
    <img
      className={className}
      src={url}
      alt={fileName}
      onClick={onClick}
      aria-label={fileName}
    />
  );
};

export default DeviceIcon;
