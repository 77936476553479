import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { ROUTES } from 'Consts/routes';
import useSecurityEvents from 'State/hooks/useSecurityEvents';

import Page from 'UI/Layout/Page';
import Background from 'UI/Layout/Background';
import PageHeader from 'UI/Layout/Page header';
import Columns from 'UI/Layout/Columns';

import Guests from 'UI/Reusable/Guests';
import EmployeesAtWork from 'UI/Reusable/Employees at work';

import ShieldGraphCard from 'UI/Reusable/Shield graph';
import { FilterNamePlusAll } from 'UI/Reusable/Shield graph/utils';

import useLayoutColumns from 'Utils/hooks/useLayout';

import WlanCard from './Components/Wlan';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import AddEmployeeSidePanel from 'UI/Reusable/AddEmployee/AddEmployee';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import EmployeeLoginFeatureAnnouncement from './Components/EmployeeLoginFeatureAnnouncement';
import Modal from 'UI/Elements/Modals';
import useLocationCapabilities from 'State/hooks/useLocationCapabilities';
import useLocationSummary from 'State/hooks/useLocationSummary';
import useEmployeeLoginDatabase from 'State/hooks/useEmployeeLoginDatabase';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import useLocations from 'State/hooks/useLocations';

const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columns, ref] = useLayoutColumns();
  const trackEvent = useTrackEvent();
  const { setContent } = useSidepanel();
  const { data: securityEvents, isLoading, errorMessage } = useSecurityEvents();
  const [activeFilter, setActiveFilter] = useState<FilterNamePlusAll>('all');
  useLocationSummary();
  const [showFeatureAnnouncement, setShowFeatureAnnouncement] = useState(false);
  const announcementShown =
    localStorage.getItem('employeeLoginFeatureAlerted') === 'true';
  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const { data: locationCapabilitiesData } = useLocationCapabilities();
  const { data: employeeLoginDatabaseData } = useEmployeeLoginDatabase();
  const employeeLoginToggleIsEnabled =
    employeeLoginDatabaseData?.databaseData.enabled;
  useNetworkAccess();
  const activeLocation = useLocations();
  const [redirectToSSIDConfig, setRedirectToSSIDConfig] = useState(false);

  useEffect(() => {
    if (
      activeLocation.activeLocation.data &&
      !activeLocation.activeLocation.data?.ssid
    ) {
      setRedirectToSSIDConfig(true);
    }
  }, [activeLocation]);

  const employeeLoginFeatureIsSupportedAndEnabled = useMemo(() => {
    return (
      !!customerSupportConfigurations?.customerFeatureEnablement
        .employeeOnboardingCaptivePortalEnabled &&
      !!locationCapabilitiesData?.state.capabilities?.captivePortalV2 &&
      !!employeeLoginToggleIsEnabled
    );
  }, [
    locationCapabilitiesData,
    customerSupportConfigurations,
    employeeLoginToggleIsEnabled,
  ]);

  useEffect(() => {
    setShowFeatureAnnouncement(
      employeeLoginFeatureIsSupportedAndEnabled && !announcementShown
    );
  }, [employeeLoginFeatureIsSupportedAndEnabled, announcementShown]);

  const handleSelectFilter = useCallback(
    (name: FilterNamePlusAll) => {
      setActiveFilter((prev) => {
        return prev === name ? 'all' : name;
      });
    },
    [setActiveFilter]
  );

  const handleShieldViewAllClick = useCallback(() => {
    navigate(ROUTES.shield);
  }, [navigate]);

  const shield = (
    <ShieldGraphCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      eventsData={securityEvents || []}
      activeTypeFilter={activeFilter}
      onTypeFilterClick={handleSelectFilter}
      onViewAllClick={handleShieldViewAllClick}
    />
  );

  const guests = <Guests maxItems={5} />;

  const handleAddEmployee = useCallback(() => {
    setContent(<AddEmployeeSidePanel />);
  }, [setContent]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Home,
      },
    });
  }, []);

  const closeFeatureAnnouncement = () => {
    setShowFeatureAnnouncement(false);
    localStorage.setItem('employeeLoginFeatureAlerted', 'true');
  };

  const openEmployeeWifiSettings = () => {
    closeFeatureAnnouncement();
    navigate(ROUTES.settings.employee);
    localStorage.setItem('settingsExpanded', true.toString());
  };

  return redirectToSSIDConfig ? (
    <Navigate to={ROUTES.ssidConfiguration.configuration} replace />
  ) : (
    <Page>
      <Background ref={ref}>
        <PageHeader
          label={t('common.home')}
          buttonPillProps={
            employeeLoginFeatureIsSupportedAndEnabled
              ? undefined
              : {
                  label: t('common.addEmployee'),
                  onClick: () => handleAddEmployee(),
                }
          }
        />

        <Columns columnCount={columns} topMargin={16}>
          <EmployeesAtWork
            employeeLoginFeatureIsSupportedAndEnabled={
              employeeLoginFeatureIsSupportedAndEnabled
            }
          />
          {shield}
          {guests}
          <WlanCard />
        </Columns>
      </Background>

      <Modal
        isOpen={showFeatureAnnouncement}
        onClose={closeFeatureAnnouncement}
      >
        <EmployeeLoginFeatureAnnouncement
          handleNotNow={closeFeatureAnnouncement}
          handleOpen={openEmployeeWifiSettings}
        />
      </Modal>
    </Page>
  );
};

export default Home;
