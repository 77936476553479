import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputListItem from 'UI/Components/Lists/List input';
import styles from 'UI/Components/Lists/List input/style.module.css';

import Menu, { MenuItemProps } from 'UI/Components/Menu';
import { IconNames } from 'UI/Elements/Icon';

import cn from 'classnames';

import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';

import { CLOUDS } from 'Api/index';
import { CloudBackend } from 'Consts/types';

import onboardingStyles from '../../style.module.css';
import { isDevDomain } from 'subDomainConfiguration';

const cloudLabelKeys = {
  [CLOUDS.CI]: 'onboarding.regions.CI',
  [CLOUDS.DOGFOOD]: 'onboarding.regions.DOGFOOD',
  [CLOUDS.BETA]: 'onboarding.regions.BETA',
  [CLOUDS.GAMMA]: 'onboarding.regions.GAMMA',
  [CLOUDS.KAPPA]: 'onboarding.regions.KAPPA',
  [CLOUDS.OSYNC]: 'onboarding.regions.OSYNC',
  [CLOUDS.OPENSYNC]: 'onboarding.regions.OPENSYNC',
  [CLOUDS.THETADEV]: 'onboarding.regions.THETADEV',
  [CLOUDS.THETASTAGE]: 'onboarding.regions.THETASTAGE',
  [CLOUDS.THETA]: 'onboarding.regions.THETA',
};

const CloudSelector = ({
  isOpen,
  onSelect,
  onClose,
  enabledCloudList,
  onSubmit,
}: {
  isOpen: boolean;
  onSelect: (ev: React.MouseEvent) => void;
  onClose: () => void;
  enabledCloudList?: CloudBackend[];
  onSubmit?: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const cloud = useSelector(selectors.auth.cloud);
  const parentRef = useRef<Element | null>(null);
  const preprodDomain = isDevDomain();

  const getCloudMenuItems = (onItemSelect: (cloud: CloudBackend) => void) => {
    const AllCloudItems: Partial<Record<CloudBackend, MenuItemProps>> = {
      CI: {
        label: t(cloudLabelKeys[CLOUDS.CI]),
        onClick: () => onItemSelect(CLOUDS.CI),
      },
      DOGFOOD: {
        label: t(cloudLabelKeys[CLOUDS.DOGFOOD]),
        onClick: () => onItemSelect(CLOUDS.DOGFOOD),
      },
      BETA: {
        label: t(cloudLabelKeys[CLOUDS.BETA]),
        onClick: () => onItemSelect(CLOUDS.BETA),
      },
      GAMMA: {
        label: t(cloudLabelKeys[CLOUDS.GAMMA]),
        onClick: () => onItemSelect(CLOUDS.GAMMA),
      },
      KAPPA: {
        label: t(cloudLabelKeys[CLOUDS.KAPPA]),
        onClick: () => onItemSelect(CLOUDS.KAPPA),
      },
      OSYNC: {
        label: t(cloudLabelKeys[CLOUDS.OSYNC]),
        onClick: () => onItemSelect(CLOUDS.OSYNC),
      },
      OPENSYNC: {
        label: t(cloudLabelKeys[CLOUDS.OPENSYNC]),
        onClick: () => onItemSelect(CLOUDS.OPENSYNC),
      },
      THETADEV: {
        label: t(cloudLabelKeys[CLOUDS.THETADEV]),
        onClick: () => onItemSelect(CLOUDS.THETADEV),
      },
      THETASTAGE: {
        label: t(cloudLabelKeys[CLOUDS.THETASTAGE]),
        onClick: () => onItemSelect(CLOUDS.THETASTAGE),
      },
      THETA: {
        label: t(cloudLabelKeys[CLOUDS.THETA]),
        onClick: () => onItemSelect(CLOUDS.THETA),
      },
    };

    const preProdCloudItems = [AllCloudItems.CI, AllCloudItems.DOGFOOD];
    const prodCloudItems = [
      AllCloudItems.GAMMA,
      AllCloudItems.KAPPA,
      AllCloudItems.BETA,
    ];

    if (!enabledCloudList || enabledCloudList.length === 0) {
      return preprodDomain ? [...preProdCloudItems] : [...prodCloudItems];
    }

    var output: MenuItemProps[] = [];
    enabledCloudList.forEach((cloudbackend: CloudBackend) => {
      const lookupFound = AllCloudItems[cloudbackend];
      if (lookupFound) {
        output.push(lookupFound);
      }
    });
    return output;
  };

  const items = getCloudMenuItems((cloud: CloudBackend) => {
    dispatch(actions.auth.setCloud(cloud));
  });

  const handleSelect = useCallback(
    (ev: React.MouseEvent) => {
      parentRef.current = ev.currentTarget.querySelector(
        '[data-testid="dropdownIcon"]'
      );
      onSelect(ev);
    },
    [onSelect]
  );

  return (
    <>
      <InputListItem
        className={cn(onboardingStyles.stretch, styles.dropdown)}
        inputType="dropdown"
        prefixIcon={IconNames.Map}
        placeholder={t('onboarding.yourRegion')}
        value={t(cloudLabelKeys[cloud])}
        onDropdownClick={handleSelect}
        onSubmit={onSubmit}
      />

      <Menu
        isOpen={isOpen}
        parent={parentRef.current}
        onClose={onClose}
        items={items as MenuItemProps[]}
      />
    </>
  );
};

export default CloudSelector;
