import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { singletonHook } from 'react-singleton-hook';

import type { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import useWindowDimensions from './useWindowSize';

export const useSidepanel = singletonHook(
  {
    closeSidepanel: () => undefined,
    setContent: () => undefined,
    content: null,
  },
  () => {
    const dispatch = useDispatch<AppDispatch>();
    const { width } = useWindowDimensions();

    const [content, setContent] = useState<React.ReactNode>(null);
    const closeTimeoutId = useRef<NodeJS.Timeout | null>(null);

    const openSidepanel = useCallback(
      (content: React.ReactNode) => {
        if (closeTimeoutId.current) {
          clearTimeout(closeTimeoutId.current);
          closeTimeoutId.current = null;
        }

        setContent(content);
        dispatch(actions.ui.page.setSidepanelOpen(true));
      },
      [dispatch]
    );

    const withTimeout = width > 472;

    const closeSidepanel = useCallback(() => {
      if (!content) {
        return;
      }

      if (closeTimeoutId.current) {
        return;
      }

      if (!withTimeout) {
        dispatch(actions.ui.page.setSidepanelIsClosing(false));
        dispatch(actions.ui.page.setSidepanelOpen(false));
        setContent(null);

        return;
      }

      dispatch(actions.ui.page.setSidepanelIsClosing(true));

      // Wait for the animation to finish before closing the sidepanel
      // It waits for 100ms more than the animation duration
      // to make sure the layout has finished updating
      // necessary for the column layout to work properly
      closeTimeoutId.current = setTimeout(() => {
        dispatch(actions.ui.page.setSidepanelIsClosing(false));
        dispatch(actions.ui.page.setSidepanelOpen(false));
        setContent(null);

        closeTimeoutId.current = null;
      }, 600);
    }, [content, dispatch, withTimeout]);

    return {
      closeSidepanel,
      setContent: openSidepanel,
      content,
    };
  }
);
