import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import type { DeviceGroup, Mac } from 'Consts/types';
import { NETWORK_IDS } from 'Consts/defintions';

import { MenuItemProps } from 'UI/Components/Menu';

import { IconNames } from 'UI/Elements/Icon';

import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';

import { useSidepanel } from 'Utils/hooks/useSidepanel';

import MacAndIpAddressSidePanel from '../Mac And IP Address SidePanel';
import AssignDevice from '../Assign devices/AssignDevices';
import useDevices from 'State/hooks/useDevices';
import DeviceSettings from '../Device Settings /DeviceSettings';
import ShareAccessSidePanel from 'UI/Reusable/ShareAccess/ShareAccessSidePanel';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { getConfigurationFromDomain } from 'subDomainConfiguration';

const BELL = 'bell';

export const useChangeGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const environment = getConfigurationFromDomain();

  const ChangeMenuHeader: MenuItemProps = {
    label: t('devices.changeGroup'),
    isHeader: true,
  };

  const { data: groups } = useSelector(selectors.zones.deviceGroups);

  const selectedDevices = useSelector(
    selectors.ui.devicesAndGroups.selectedDevices
  );
  const selectedGroup = useSelector(
    selectors.ui.devicesAndGroups.selectedGroup
  );

  const handleChangeToDefaultGroup = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());

    if (!selectedGroup) {
      return;
    }

    const devices = selectedGroup.devices.filter((mac) => {
      return !selectedDevices?.find((device) => mac === device.mac);
    });

    dispatch(
      actions.zones.deviceGroups.addOrUpdateGroup({
        networkId: NETWORK_IDS.default,
        name: selectedGroup.name,
        devices,
        groupId: selectedGroup.groupId,
      })
    );

    dispatch(actions.ui.devicesAndGroups.selectGroup(null));
    dispatch(actions.ui.devicesAndGroups.selectDevices([]));
    dispatch(actions.ui.devicesAndGroups.selectQuarantineDomain(null));
  }, [dispatch, selectedDevices, selectedGroup]);

  const handleChangeGroup = useCallback(
    (targetGroup: DeviceGroup) => {
      dispatch(actions.ui.devicesAndGroups.closeMenus());

      dispatch(
        actions.zones.deviceGroups.addOrUpdateGroup({
          networkId: NETWORK_IDS.default,
          name: targetGroup.name,
          devices: [
            ...targetGroup.devices,
            ...(selectedDevices?.map(({ mac }) => mac) as []),
          ],
          groupId: targetGroup.groupId,
        })
      );

      dispatch(actions.ui.devicesAndGroups.selectGroup(null));
      dispatch(actions.ui.devicesAndGroups.selectDevices([]));
      dispatch(actions.ui.devicesAndGroups.selectQuarantineDomain(null));
    },
    [dispatch, selectedDevices]
  );

  const checkListOnIcon =
    environment.id === BELL ? IconNames.CheckListOnBell : IconNames.CheckListOn;

  const checkListOffIcon =
    environment.id === BELL
      ? IconNames.CheckListOffBell
      : IconNames.CheckListOff;

  const defaultGroupMenuItem: MenuItemProps = {
    label: t('devices.approvedDevices'),
    iconName: !selectedGroup ? checkListOnIcon : checkListOffIcon,
    onClick: selectedGroup ? handleChangeToDefaultGroup : undefined,
  };

  const changeMenuItems = (groups?.default ?? [])
    .filter((group) => group?.standalone === false)
    .map((group) => {
      const isCurrentGroup = group?.groupId === selectedGroup?.groupId;

      return {
        label: group.name,
        iconName: isCurrentGroup ? checkListOnIcon : checkListOffIcon,
        onClick: !isCurrentGroup ? () => handleChangeGroup(group) : undefined,
      } as MenuItemProps;
    });

  return [
    ChangeMenuHeader,
    { isDivider: true },
    defaultGroupMenuItem,
    ...changeMenuItems,
  ];
};

export const useMenuItems = ({
  onNewGroup = () => undefined,
  onRenameGroup = () => undefined,
  onDeleteGroup = () => undefined,
}: {
  onNewGroup?: () => void;
  onRenameGroup?: () => void;
  onDeleteGroup?: () => void;
}) => {
  const { allDevices } = useDevices();
  const devices = allDevices.data;
  const dispatch = useDispatch<AppDispatch>();
  const { setContent } = useSidepanel();
  const trackEvent = useTrackEvent();
  let filteredDevices: Mac[];
  const selectedDevices = useSelector(
    selectors.ui.devicesAndGroups.selectedDevices
  );
  const selectedEmployee = useSelector(
    selectors.ui.devicesAndGroups.selectEmployee
  );

  const assignedDevices = useMemo(() => {
    if (!selectedEmployee?.length) {
      return;
    }
    return (
      devices
        ?.filter((device) => device.personId === selectedEmployee[0].id)
        .map((device) => device.mac) || []
    );
  }, [devices, selectedEmployee]);

  const selectedGroup = useSelector(
    selectors.ui.devicesAndGroups.selectedGroup
  );

  const handleAproveDevices = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    dispatch(actions.ui.devicesAndGroups.approveDevices());
  }, [dispatch]);

  const handleBlockDevices = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    dispatch(actions.ui.devicesAndGroups.blockDevices());
  }, [dispatch]);

  const handleGroupShareAccess = useCallback(() => {
    if (!selectedGroup) {
      return;
    }
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    setContent(<ShareAccessSidePanel group={selectedGroup!} />);
  }, [selectedGroup, setContent, dispatch]);

  const handleDeviceShareAccess = useCallback(() => {
    if (!selectedDevices?.length) {
      return;
    }
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    setContent(<ShareAccessSidePanel device={selectedDevices[0]} />);
  }, [setContent, selectedDevices, dispatch]);

  const handleUnquarantineDevice1hr = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    dispatch(actions.ui.devicesAndGroups.unQuarantineDevices(60));

    trackEvent({
      eventName: MixPanelEvents.UNQUARANTINE_DEVICE,
      additionalContent: {
        UNQUARANTINE_DURATION: 60,
      },
    });
  }, [dispatch]);

  const handleUnquarantineDevicePermanently = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    dispatch(actions.ui.devicesAndGroups.unQuarantineDevices());
    trackEvent({
      eventName: MixPanelEvents.UNQUARANTINE_DEVICE,
    });
  }, [dispatch]);

  const handleOpenDeviceSettingsSideBar = useCallback(() => {
    if (!selectedDevices?.length) {
      return;
    }

    dispatch(actions.ui.devicesAndGroups.closeMenus());
    setContent(<DeviceSettings device={selectedDevices[0]} />);
  }, [dispatch, selectedDevices, setContent]);

  const handleOpenMacAndIPADressSidebar = useCallback(() => {
    if (!selectedDevices?.length) {
      return;
    }

    dispatch(actions.ui.devicesAndGroups.closeMenus());

    setContent(
      <MacAndIpAddressSidePanel
        mac={selectedDevices[0].mac}
        ip={selectedDevices[0].ip}
        deviceName={selectedDevices[0].name}
      />
    );
  }, [dispatch, selectedDevices, setContent]);

  const handleAssignDeviceSidebar = useCallback(() => {
    if (!selectedDevices?.length) {
      return;
    }

    dispatch(actions.ui.devicesAndGroups.closeMenus());
    setContent(<AssignDevice device={selectedDevices} />);
  }, [dispatch, selectedDevices, setContent]);

  const handleUnAssignDevice = useCallback(() => {
    if (!assignedDevices?.length) {
      return;
    }

    filteredDevices = assignedDevices.filter((mac) => {
      return mac !== selectedDevices?.[0].mac;
    });

    if (!filteredDevices) return;
    dispatch(
      actions.employee.assignDeviceToEmployee(
        selectedEmployee[0].id,
        filteredDevices
      )
    );
    dispatch(
      actions.ui.miniBanner.notify({
        label: t('devices.assignDeviceZone.unassigned', {
          employee: selectedEmployee[0].nickname,
        }),
        state: 'set',
      })
    );
  }, [selectedDevices, selectedEmployee, devices, assignedDevices]);

  const handleNewGroup = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    onNewGroup();
  }, [dispatch, onNewGroup]);

  const handleRenameGroup = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    onRenameGroup();
  }, [dispatch, onRenameGroup]);

  const handleAddOrUpdateGroup = useCallback(
    (groupName: string, isNew?: boolean) => {
      if (isNew) {
        dispatch(actions.ui.devicesAndGroups.closeMenus());
        dispatch(
          actions.zones.deviceGroups.addOrUpdateGroup({
            networkId: NETWORK_IDS.default,
            name: groupName,
            devices: selectedDevices?.map(({ mac }) => mac),
          })
        );

        return;
      }

      if (!selectedGroup) {
        return;
      }

      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(
        actions.zones.deviceGroups.addOrUpdateGroup({
          networkId: NETWORK_IDS.default,
          name: groupName || selectedGroup.name,
          devices: selectedDevices?.map(({ mac }) => mac),
          groupId: selectedGroup.groupId,
        })
      );

      dispatch(actions.ui.devicesAndGroups.selectDevices([]));
      dispatch(actions.ui.devicesAndGroups.selectGroup(null));
      dispatch(actions.ui.devicesAndGroups.selectQuarantineDomain(null));
    },
    [dispatch, selectedDevices, selectedGroup]
  );

  const handleDeleteGroup = useCallback(() => {
    if (!selectedGroup) {
      return;
    }

    dispatch(actions.ui.devicesAndGroups.closeMenus());
    dispatch(
      actions.zones.deviceGroups.deleteGroup({
        networkId: NETWORK_IDS.default,
        groupId: selectedGroup.groupId,
      })
    );

    dispatch(actions.ui.devicesAndGroups.selectDevices([]));
    dispatch(actions.ui.devicesAndGroups.selectGroup(null));
    dispatch(actions.ui.devicesAndGroups.selectQuarantineDomain(null));
    onDeleteGroup();
  }, [dispatch, selectedGroup]);

  const handleOpenChangeMenu = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.setChangeMenuOpen(true));
  }, [dispatch]);

  const MENU_ITEMS: Record<string, MenuItemProps> = {
    divider: { isDivider: true },

    changeGroup: {
      label: `${t('devices.changeGroup')}...`,
      iconName: IconNames.Devices,
      onClick: handleOpenChangeMenu,
      preventOnClose: true,
    },

    newGroup: {
      label: t('devices.newGroup'),
      iconName: IconNames.Plus,
      onClick: handleNewGroup,
    },

    renameGroup: {
      label: t('devices.rename'),
      iconName: IconNames.Edit,
      onClick: handleRenameGroup,
      preventOnClose: true,
    },

    groupShareAccess: {
      label: t('devices.shareAccess'),
      iconName: IconNames.ShareAccess,
      onClick: handleGroupShareAccess,
    },
    deviceShareAccess: {
      label: t('devices.shareAccess'),
      iconName: IconNames.ShareAccess,
      onClick: handleDeviceShareAccess,
    },

    deleteGroup: {
      label: t('devices.deleteGroup'),
      iconName: IconNames.Trash,
      theme: 'danger',
      onClick: handleDeleteGroup,
    },

    approveDevices: {
      label: t('devices.approveDevices'),
      iconName: IconNames.Devices,
      onClick: handleAproveDevices,
    },

    approveDevice: {
      label: t('devices.approveDevice'),
      iconName: IconNames.Devices,
      onClick: handleAproveDevices,
    },

    blockDevices: {
      label: t('devices.blockDevices'),
      iconName: IconNames.X,
      theme: 'danger',
      onClick: handleBlockDevices,
    },

    blockDevice: {
      label: t('devices.blockDevice'),
      iconName: IconNames.X,
      theme: 'danger',
      onClick: handleBlockDevices,
    },

    deviceSettings: {
      label: t('homepage.deviceSettings'),
      iconName: IconNames.Edit,
      onClick: handleOpenDeviceSettingsSideBar,
    },

    macAndIPAddress: {
      label: t('homepage.macAndIp'),
      iconName: IconNames.CircleInfo,
      onClick: handleOpenMacAndIPADressSidebar,
    },

    assignDevice: {
      label: t('devices.assignDeviceZone.assignDevice'),
      iconName: IconNames.Person,
      onClick: handleAssignDeviceSidebar,
    },
    unAssignDevice: {
      label: t('employeeZone.unassign'),
      iconName: IconNames.Devices,
      onClick: handleUnAssignDevice,
    },
    unquarantine1hr: {
      label: t('devices.unquarantineFor1hr'),
      iconName: IconNames.Clock,
      onClick: handleUnquarantineDevice1hr,
    },
    unquarantinePermanently: {
      label: t('devices.unquarantinePermanently'),
      iconName: IconNames.Check,
      onClick: handleUnquarantineDevicePermanently,
    },
  };

  return {
    MENU_ITEMS,
    actions: { saveGroup: handleAddOrUpdateGroup },
  };
};
