import { AutoCompleteConfiguration } from '../AutoComplete';
import { t } from 'i18next';

export const getDefaultConfiguration = (
  config?: Partial<AutoCompleteConfiguration>
): AutoCompleteConfiguration => {
  return {
    noResultsText: t('search.noResults'),
    placeholderText: t('search.searchPlaceholder'),
    closeOnSelectItem: true,
    minCharactersToSearch: 0,
    displayFilters: true,
    ...config,
  };
};
