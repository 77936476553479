import type {
  CustomerId,
  LocationId,
  Mac,
  NetworkType,
  CloudBackend,
  WebsiteType,
  Maybe,
} from 'Consts/types';
import { BELL_FONSE_URLS } from './bellEndpoints';
import { environments } from '../Consts/environments';

export const CLOUDS: Record<CloudBackend, CloudBackend> = {
  CI: 'CI',
  DOGFOOD: 'DOGFOOD',
  BETA: 'BETA',
  GAMMA: 'GAMMA',
  KAPPA: 'KAPPA',
  ETNA: 'ETNA',
  DELTA: 'DELTA',
  OSYNC: 'OSYNC',
  OPENSYNC: 'OPENSYNC',
  THETA: 'THETA',
  THETASTAGE: 'THETASTAGE',
  THETADEV: 'THETADEV',
  PHI: 'PHI',
};

type ErrorCodes = 401 | 403 | 404 | 409 | 422 | 500;

type ApiError = {
  statusCode: ErrorCodes;
  name: 'Error';
  message: string;
  code: string;
  status: ErrorCodes;
};

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const METHODS: Record<Method, Method> = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export type CustomerAndLocationIds = {
  customerId: CustomerId;
  locationId: LocationId;
};

const paramsToQuery = (params: Record<string, string>) => {
  return '?' + new URLSearchParams(params).toString();
};

const getOneMonthAgoStartTime = () => {
  const currentTimestampInSeconds = Math.floor(
    new Date().setUTCHours(0, 0, 0, 0) / 1000
  ); // Get current start of day in seconds
  const oneMonthInSeconds = 2592000;
  const startTimeInMilliseconds = currentTimestampInSeconds - oneMonthInSeconds;

  return startTimeInMilliseconds;
};

export const ENDPOINTS = {
  login() {
    return ENDPOINTS.customers({}) + '/login?include=user';
  },

  magicLink() {
    return ENDPOINTS.customers({}) + '/passwordLessToken';
  },

  partnerSSOlogin(partnerId: string, appId?: string) {
    return (
      '/sso/partners/' +
      partnerId +
      '/login?' +
      (appId ? 'appId=' + appId + '&' : '') +
      'customRedirectUri=' +
      window.location.origin +
      '/partner/callback'
    );
  },

  partnerSSOCallback(
    partnerId: string,
    params: { state: string; code: string; appId?: string }
  ) {
    return '/sso/partners/' + partnerId + '/callback' + paramsToQuery(params);
  },

  partnerSSORefresh(
    partnerId: string,
    params: { refreshToken: string; appId?: string }
  ) {
    return (
      '/sso/partners/' + partnerId + '/refreshToken' + paramsToQuery(params)
    );
  },

  bellPasswordLogin(cloud: CloudBackend) {
    return BELL_FONSE_URLS[cloud] + '/api/authnz/v3/auth';
  },

  iguanaExchangeToken() {
    return '/iguana/exchangeToken';
  },

  resetPassword() {
    return ENDPOINTS.customers({}) + '/reset';
  },

  checkEmailExists(params: { email: string }) {
    return ENDPOINTS.customers({}) + '/exists' + paramsToQuery(params);
  },

  customers({ customerId = '' }: { customerId?: CustomerId }) {
    return '/customers' + (customerId ? '/' + customerId : '');
  },

  locations({
    customerId,
    locationId = '',
  }: {
    customerId: CustomerId;
    locationId?: LocationId;
  }) {
    return ENDPOINTS.customers({ customerId }) + '/locations/' + locationId;
  },

  entitledAccess({ customerId }: { customerId: CustomerId }) {
    return ENDPOINTS.customers({ customerId }) + '/entitledAccess';
  },

  coAdminAccessTokens({
    customerId,
    accessId,
  }: {
    customerId: CustomerId;
    accessId: string;
  }) {
    return (
      ENDPOINTS.customers({ customerId }) +
      '/entitledAccess/' +
      accessId +
      '/accessTokens'
    );
  },

  dashboard(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/appFacade/dashboard';
  },

  locationAtWorkEvents(args: CustomerAndLocationIds) {
    const fromTimestamp = getOneMonthAgoStartTime();
    return (
      ENDPOINTS.locations(args) +
      '/homeaway/events?from=' +
      fromTimestamp +
      '&limit=3000'
    );
  },

  //fromTimestamp = 896857
  persons({
    personId = '',
    ...args
  }: CustomerAndLocationIds & { personId?: string }) {
    return (
      ENDPOINTS.locations(args) + '/persons' + (personId ? '/' + personId : '')
    );
  },

  personAppTime({
    personId = '',
    ...args
  }: CustomerAndLocationIds & { personId: string }) {
    return ENDPOINTS.persons({ personId, ...args }) + '/appTime';
  },

  addEmployee(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/persons';
  },

  ispSpeedTest(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/ispSpeedTest';
  },

  ispSpeedTestConfiguration(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/ispSpeedTestConfiguration';
  },

  fronthauls({
    networkId = '',
    ...args
  }: CustomerAndLocationIds & { networkId?: string }) {
    return (
      ENDPOINTS.locations(args) +
      '/secondaryNetworks/fronthauls' +
      (networkId ? '/' + networkId : '')
    );
  },

  captivePortals({
    networkId = '',
    ...args
  }: CustomerAndLocationIds & { networkId?: string }) {
    return (
      ENDPOINTS.locations(args) +
      '/secondaryNetworks/captivePortals' +
      (networkId ? '/' + networkId : '')
    );
  },

  guests({
    guestId = '',
    ...args
  }: CustomerAndLocationIds & { networkId: string; guestId?: string }) {
    return (
      ENDPOINTS.captivePortals(args) +
      '/guests' +
      (guestId ? '/' + guestId : '')
    );
  },

  downloadGuestDetails({
    ...args
  }: CustomerAndLocationIds & { networkId: string }) {
    return (
      ENDPOINTS.captivePortals(args) +
      '/downloadGuestDetailsDirect?duration=350&limit=1000'
    );
  },

  enableGuestEmailCollection({
    ...args
  }: CustomerAndLocationIds & { networkId: string }) {
    return ENDPOINTS.captivePortals(args) + '/enableGuestEmailCollection';
  },

  guestEmailCollectionInfo({
    ...args
  }: CustomerAndLocationIds & { networkId: string }) {
    return (
      ENDPOINTS.captivePortals(args) + '/guestEmailCollectionInfo?duration=350'
    );
  },

  wifiNetwork(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/wifiNetwork';
  },

  wifiNetworks(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/wifiNetworks';
  },

  networkAccess({
    networkId,
    ...args
  }: CustomerAndLocationIds & { networkId?: NetworkType }) {
    return (
      ENDPOINTS.locations(args) + '/networkAccess/networks/' + (networkId ?? '')
    );
  },

  devices(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/devices?include=bandwidth';
  },

  device({ mac = '', ...args }: CustomerAndLocationIds & { mac?: string }) {
    return ENDPOINTS.locations(args) + '/devices' + (mac ? '/' + mac : '');
  },

  deviceAppTime({
    mac = '',
    ...args
  }: CustomerAndLocationIds & { mac: string }) {
    return ENDPOINTS.device({ mac, ...args }) + '/appTime';
  },

  deviceSecurityPolicy({
    mac = '',
    ...args
  }: CustomerAndLocationIds & { mac: string }) {
    return ENDPOINTS.device({ mac, ...args }) + '/securityPolicy';
  },

  employeeSecurityPolicy({
    personId = '',
    ...args
  }: CustomerAndLocationIds & { personId: string }) {
    return ENDPOINTS.persons({ personId, ...args }) + '/securityPolicy';
  },

  blockDevice({ mac = '', ...args }: CustomerAndLocationIds & { mac?: Mac }) {
    return (
      ENDPOINTS.locations(args) +
      '/networkAccess/blocked' +
      (mac ? '/' + mac : '')
    );
  },
  unquarantineDevice({ mac, ...args }: CustomerAndLocationIds & { mac: Mac }) {
    return (
      ENDPOINTS.locations(args) +
      '/devices/' +
      mac +
      '/securityPolicy/anomaly/websites/whitelist'
    );
  },

  approveDevice(args: CustomerAndLocationIds & { networkId: NetworkType }) {
    return ENDPOINTS.networkAccess(args) + '/approved';
  },

  appFacade({
    filters,
    ...args
  }: CustomerAndLocationIds & { filters: string[] }) {
    const filterList = filters.length ? `?filters=${filters.join(',')}` : '';

    return ENDPOINTS.locations(args) + '/appFacade/home' + filterList;
  },

  nodes({
    nodeId = '',
    ...args
  }: CustomerAndLocationIds & { nodeId?: string }) {
    return ENDPOINTS.locations(args) + '/nodes' + (nodeId ? '/' + nodeId : '');
  },

  nodesEthernetLan({
    nodeId,
    ...args
  }: CustomerAndLocationIds & { nodeId?: string }) {
    return ENDPOINTS.locations(args) + '/nodes/' + nodeId + '/ethernetLan';
  },

  dataUsageCategories() {
    return '/appTime/categories/dataUsage';
  },

  guestNetworkUsage(args: CustomerAndLocationIds & { networkId: NetworkType }) {
    return ENDPOINTS.captivePortals(args) + '/networkUsage';
  },

  locationSecurityPolicy(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/securityPolicy';
  },

  locationPolicyWhitelist({ ...args }: CustomerAndLocationIds) {
    return ENDPOINTS.locationSecurityPolicy(args) + '/websites/whitelist';
  },

  locationPolicyWhitelistItem({
    value = '',
    ...args
  }: CustomerAndLocationIds & { value: string; type: WebsiteType }) {
    return (
      ENDPOINTS.locationSecurityPolicy(args) +
      '/websites/whitelist' +
      (value ? '/' + value : '')
    );
  },

  locationPolicyBlacklist({ ...args }: CustomerAndLocationIds) {
    return ENDPOINTS.locationSecurityPolicy(args) + '/websites/blacklist';
  },

  locationPolicyBlacklistItem({
    value = '',
    ...args
  }: CustomerAndLocationIds & { value: string; type: WebsiteType }) {
    return (
      ENDPOINTS.locationSecurityPolicy(args) +
      '/websites/blacklist' +
      (value ? '/' + value : '')
    );
  },

  personSecurityPolicy(args: CustomerAndLocationIds & { personId: string }) {
    return ENDPOINTS.persons(args) + '/securityPolicy';
  },

  personPolicyWhitelist({
    personId,
    ...args
  }: CustomerAndLocationIds & { personId: string }) {
    return (
      ENDPOINTS.personSecurityPolicy({ personId, ...args }) +
      '/websites/whitelist'
    );
  },

  personPolicyWhitelistItem({
    value = '',
    personId,
    ...args
  }: CustomerAndLocationIds & {
    value: string;
    type: WebsiteType;
    personId: string;
  }) {
    return (
      ENDPOINTS.personSecurityPolicy({ personId, ...args }) +
      '/websites/whitelist' +
      (value ? '/' + value : '')
    );
  },

  personPolicyBlacklist({
    personId,
    ...args
  }: CustomerAndLocationIds & { personId: string }) {
    return (
      ENDPOINTS.personSecurityPolicy({ personId, ...args }) +
      '/websites/blacklist'
    );
  },

  personPolicyBlacklistItem({
    value = '',
    personId,
    ...args
  }: CustomerAndLocationIds & {
    value: string;
    type: WebsiteType;
    personId: string;
  }) {
    return (
      ENDPOINTS.personSecurityPolicy({ personId, ...args }) +
      '/websites/blacklist' +
      (value ? '/' + value : '')
    );
  },

  deviceGroups({
    groupId = '',
    ...args
  }: CustomerAndLocationIds & { networkId: NetworkType; groupId?: string }) {
    return (
      ENDPOINTS.networkAccess(args) +
      '/deviceGroups' +
      (groupId ? '/' + groupId : '')
    );
  },

  employeeGroupShareAccess({
    networkId = '',
    groupId = '',
    ...args
  }: CustomerAndLocationIds & {
    networkId?: string;
    groupId?: string;
  }) {
    return (
      ENDPOINTS.locations(args) +
      '/networkAccess/networks' +
      (networkId ? '/' + networkId : '') +
      '/deviceGroups' +
      (groupId ? '/' + groupId : '') +
      '/groupShares'
    );
  },

  employeeShareAccess({
    networkId = '',
    mac = '',
    ...args
  }: CustomerAndLocationIds & {
    networkId?: string;
    mac: Mac;
  }) {
    return (
      ENDPOINTS.locations(args) +
      '/networkAccess/networks' +
      (networkId ? '/' + networkId : '') +
      '/devices' +
      (mac ? '/' + mac : '') +
      '/groupShares'
    );
  },

  events(args: CustomerAndLocationIds, params: Record<string, string> = {}) {
    return (
      ENDPOINTS.locationSecurityPolicy(args) + '/events' + paramsToQuery(params)
    );
  },

  deviceSecurityEvents(
    args: CustomerAndLocationIds,
    mac: Mac,
    params: Record<string, string> = {}
  ) {
    return (
      ENDPOINTS.deviceSecurityPolicy({ mac, ...args }) +
      '/events' +
      paramsToQuery(params)
    );
  },

  employeeSecurityEvents(
    args: CustomerAndLocationIds,
    personId: string,
    params: Record<string, string> = {}
  ) {
    return (
      ENDPOINTS.employeeSecurityPolicy({ personId, ...args }) +
      '/events' +
      paramsToQuery(params)
    );
  },

  onlineTimeCategories() {
    return '/appTime/categories/onlineTime';
  },

  appsOnlineTime() {
    return '/appTime/apps/onlineTime';
  },

  forceGraph(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/forceGraph';
  },

  customerSupportConfiguration(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/customerSupportConfigurations';
  },

  pubnubSubscribe(args: CustomerAndLocationIds) {
    return ENDPOINTS.locations(args) + '/subscribe';
  },
  globalAuthLoginOptions() {
    return '/global-auth/workflows/login-options';
  },
  globalAuthGetAllCustomers() {
    return '/global-auth/users/me/global-customers';
  },

  employeeLoginConfig(locationId: LocationId) {
    return `/v1/locations/${locationId}/networks/employee/captiveportal`;
  },

  employeeLoginCompanyName(locationId: LocationId) {
    return `/v1/locations/${locationId}/networks/employee/captiveportal`;
  },

  employeeLoginEnableFeature(locationId: LocationId) {
    return `/v1/locations/${locationId}/networks/employee/captivePortal/enabled`;
  },

  locationCapabilities(customerId: CustomerId, locationId: LocationId) {
    return `/Customers/${customerId}/locations/${locationId}/v2/configAndState`;
  },

  employeeLoginBlockedEmployees(locationId: LocationId) {
    return `/v1/locations/${locationId}/networks/employee/users/blocked`;
  },

  unblockEmployeeLoginEmployee(locationId: LocationId, idpUserId: string) {
    return `/v1/locations/${locationId}/networks/employee/users/blocked/${idpUserId}`;
  },
};

interface ResponseError {
  name: string;
  message: string;
  code?: string;
  status?: ErrorCodes;
}

class ErrorResponse {
  public name: string;
  public message: string;
  public code?: string;
  public status?: ErrorCodes;

  constructor({ name, message, code, status }: ApiError) {
    this.name = name;
    this.message = message;
    this.code = code;
    this.status = status;
  }
}

export const callApi = async <R>({
  url,
  method = METHODS.GET,
  data,
  token,
  cloud,
  responseType = 'json',
  repoBaseUrl = 'api',
}: {
  url: string;
  method?: Method;
  data?: unknown;
  token?: string;
  cloud: CloudBackend;
  responseType?: string;
  repoBaseUrl?: string;
}): Promise<{ data?: R; error?: ResponseError; blob?: Blob }> => {
  const headers = new Headers({
    'Content-Type': responseType === 'blob' ? 'text/csv' : 'application/json',
    accept:
      responseType === 'blob'
        ? 'text/csv'
        : 'application/json, application/vnd.plumedesign.v2+json',
  });

  if (token) {
    headers.append('authorization', token);
  }

  const options: RequestInit = { method, headers };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const endpoint = url.startsWith('http')
    ? url
    : environments[cloud]?.apiUrl + '/' + repoBaseUrl + url;
  let response: Maybe<Response>;
  try {
    response = await fetch(endpoint, options);

    if (responseType === 'blob') {
      const responseData = await response.blob();

      return { blob: responseData };
    }
    const responseData = await response.json();

    if (responseData?.error) {
      throw new ErrorResponse(responseData.error);
    }

    if (responseData?.statusCode < 200 || responseData?.statusCode >= 300) {
      throw new ErrorResponse(responseData);
    }

    return { data: responseData };
  } catch (error) {
    if (response && response.status < 400) {
      return {};
    }
    if (response && response.status === 401) {
      return {
        error: {
          name: 'Unauthorized',
          message: (error as ErrorResponse)?.message,
        },
      };
    }

    if (error instanceof ErrorResponse) {
      if (process.env.REACT_APP_APP_ENV !== 'production') {
        console.error(error);
      }

      return { error: { name: error.name, message: error.message } };
    }

    return {
      error: { name: 'Generic error', message: 'Generic network error' },
    };
  }
};

export const callApiDirect = async <R>({
  url,
  method = METHODS.GET,
  data,
  incomingHeaders,
  token,
}: {
  url: string;
  method?: Method;
  data?: unknown;
  token?: string;
  incomingHeaders?: { name: string; value: string }[];
}): Promise<{ data?: R; error?: ResponseError; blob?: Blob }> => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    accept: 'application/json',
  });

  if (token) {
    headers.append('authorization', token);
  }

  incomingHeaders?.forEach((incomingHeader) => {
    headers.append(incomingHeader.name, incomingHeader.value);
  });

  const options: RequestInit = { method, headers };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(url, options);

  try {
    const responseData = await response.json();
    if (responseData?.error) {
      throw new ErrorResponse(responseData.error);
    }
    if (!response.ok) {
      if (responseData?.code && responseData?.description) {
        throw new ErrorResponse({
          name: responseData.code,
          message: responseData?.description,
          code: responseData.code,
          statusCode: response?.status as ErrorCodes,
          status: response?.status as ErrorCodes,
        });
      } else {
        throw new Error();
      }
    }

    return { data: responseData };
  } catch (error) {
    if (response.status < 400) {
      return {};
    }

    if (error instanceof ErrorResponse) {
      return { error: { name: error.name, message: error.message } };
    }

    return {
      error: { name: 'Generic error', message: 'Generic network error' },
    };
  }
};

export type ApiCall<P, R> = (
  args: P
) => Promise<{ data?: R; error?: ResponseError; blob?: Blob }>;
