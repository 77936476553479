import React, { FunctionComponent } from 'react';
import Icon, { IconNames } from 'UI/Elements/Icon';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import { Body1, Heading4Medium } from 'UI/Elements/Typography';

export type EmployeeLoginFeatureAnnouncementProps = {
  handleNotNow: () => void;
  handleOpen: () => void;
};

const EmployeeLoginFeatureAnnouncement: FunctionComponent<
  EmployeeLoginFeatureAnnouncementProps
> = ({ handleNotNow, handleOpen }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.featureAnnouncement}>
      <div className={styles.featureAnnouncementTop}>
        <Heading4Medium>{t('homepage.newEmployeeLogin')}</Heading4Medium>
        <Body1 className={styles.featureAnnouncementParagraph}>
          {t('homepage.newEmployeeLoginDescription')}
        </Body1>
      </div>
      <Icon name={IconNames.KeycardIllustration} />
      <div className={styles.featureAnnouncementBottom}>
        <Button
          className={styles.featureAnnouncementCta}
          label={t('common.open')}
          onClick={handleOpen}
        />
        <Button
          className={styles.featureAnnouncementCta}
          label={t('common.notNow')}
          onClick={handleNotNow}
          theme={BUTTON_THEMES.white}
        />
      </div>
    </div>
  );
};

export default EmployeeLoginFeatureAnnouncement;
