import AutoComplete, {
  AutoCompleteParams,
} from 'UI/Components/AutoComplete/AutoComplete';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import { AutoCompleteResultsItem } from 'UI/Components/AutoComplete/AutoCompleteResults';
import { IconNames } from 'UI/Elements/Icon';
import cx from 'classnames';
import { Events } from 'Consts/types';
import { useTranslation } from 'react-i18next';
import PillXLarge from 'UI/Elements/PillXLarge';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import { onEnterKeydown } from 'Utils/keyboardEvents';

export enum AutoCompleteResultTypes {
  Employee = 'employee',
  Device = 'device',
}

type ShieldAutoCompleteProps = {
  onSelectItem: (item: AutoCompleteResultsItem | null) => void;
  activeTab: string;
};
const BELL = 'bell';
const ShieldAutoComplete: FunctionComponent<ShieldAutoCompleteProps> = ({
  onSelectItem,
  activeTab,
}) => {
  const employees = useSelector(selectors.employees);
  const devices = useSelector(selectors.zones.devices);
  const { t } = useTranslation();
  const environment = getConfigurationFromDomain();
  const isBell = useMemo(() => {
    return environment.id === BELL;
  }, [environment]);
  const [selectedResult, setSelectedResult] =
    useState<AutoCompleteResultsItem | null>(null);
  const onSearch = (query: string, params: AutoCompleteParams) => {
    const employeesData = (employees.data || [])
      .map((d) => {
        return {
          id: d.id,
          label: d.nickname,
          type: AutoCompleteResultTypes.Employee,
        };
      })
      .filter((d) => d.label.toLowerCase().includes(query.toLowerCase()));
    const devicesData = (devices.data || [])
      .map((d) => {
        return {
          id: d.mac,
          label: d.nickname || d.name || d.mac,
          type: AutoCompleteResultTypes.Device,
        };
      })
      .filter((d) => {
        return d.label.toLowerCase().includes(query.toLowerCase());
      });

    return Promise.resolve([...employeesData, ...devicesData]);
  };

  const handleResultsItemClick = (item: AutoCompleteResultsItem) => {
    onSelectItem(item);
    setSelectedResult(item);
  };

  const handleSelectedPillClick = () => {
    onSelectItem(null);
    setSelectedResult(null);
    const autoCompleteInput = document.getElementById(
      'shieldAutoCompleteInput'
    ) as HTMLElement;
    autoCompleteInput?.focus();
  };

  // this logic and the hidden div below are used to ensure that the full selected filter label
  //  is announced by the screen reader. Without it, only part of the label is read due to
  //  a timing issue with state updates
  useEffect(() => {
    if (selectedResult) {
      const liveAnnouncer = document.getElementById('aria-live-announcer');
      if (liveAnnouncer)
        liveAnnouncer.innerText = `${t('shield.shieldSelectedFilter')}: ${
          selectedResult.label
        }`;
    }
  }, [selectedResult]);

  return (
    <div className={styles.shieldEmployeeAutoComplete}>
      <AutoComplete
        className={
          isBell ? styles.bellShieldAutoComplete : styles.shieldAutoComplete
        }
        configuration={{
          placeholderText: t('shield.filter'),
          maxHeight: 255,
          clearQuery: true,
          icon: IconNames.Filter,
          iconAriaLabel: t('shield.filter'),
        }}
        onSearch={onSearch}
        availableFilters={[
          {
            name: 'employee',
            label: 'Employees',
          },
          {
            name: 'device',
            label: 'Devices',
          },
        ]}
        onItemClick={handleResultsItemClick}
        resultsClassname={styles.shieldAutoCompleteResults}
        isShieldAutoComplete
        autoCompleteInputId="shieldAutoCompleteInput"
      />
      {selectedResult && (
        <div
          data-testid="selected-result-pill"
          tabIndex={0}
          onKeyDown={(e) => onEnterKeydown(e, handleSelectedPillClick)}
        >
          <PillXLarge
            label={selectedResult.label}
            leftIconName={
              selectedResult.type === AutoCompleteResultTypes.Employee
                ? IconNames.Person
                : IconNames.LaptopAndPhone
            }
            rightIconName={IconNames.X}
            className={cx(styles.selectedResultPill, {
              [styles.pillDisabed]:
                activeTab !== Events.Protected &&
                selectedResult.type === AutoCompleteResultTypes.Device,
            })}
            onClick={handleSelectedPillClick}
          />
        </div>
      )}
      <div
        id="aria-live-announcer"
        className={styles.hiddenLiveAnnouncer}
        aria-live="assertive"
        aria-atomic="true"
      ></div>
    </div>
  );
};

export default ShieldAutoComplete;
