import cn from 'classnames';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Card } from 'UI/Elements/Card';

import { environments } from 'Consts/environments';
import { AppOnlineTime, CloudBackend, Nullable } from 'Consts/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useApplicationInfo from 'State/hooks/useApplicationInfo';
import * as selectors from 'State/selectors';
import colorStyles from 'Styles/colors';
import DataTile from 'UI/Components/Tiles/Data tile';
import styles from './style.module.css';

export type AppTimeProps = {
  personId?: string;
  maxItems: number;
} & JSX.IntrinsicElements['div'];

type AppTimeUIProps = {
  isLoading?: boolean;
  errorMessage?: string;
  applications: AppOnlineTime[];
  title?: Nullable<string>;
  maxItems?: number;
  viewAllHandler?: () => void;
} & JSX.IntrinsicElements['div'];

type AppListProps = {
  applications: AppOnlineTime[];
  maxItems?: number;
};

const getIconURL = (appId: string, cloud: CloudBackend): string => {
  return appId === 'other'
    ? '/devices/iot-small/unknown.svg'
    : environments[cloud]?.metasaurusUrl + '/apps/' + appId + '/logo.png';
};

export const AppItem: FunctionComponent<
  AppOnlineTime & {
    maxOnlineSeconds: number;
    totalOnlineTime: number;
  }
> = ({ appId, onlineSeconds, maxOnlineSeconds, totalOnlineTime }) => {
  const { t } = useTranslation();
  const cloud = useSelector(selectors.auth.cloud);
  const iconURL = getIconURL(appId, cloud);
  const appInfo = useApplicationInfo();
  const [appName, setAppName] = useState(appId);

  useEffect(() => {
    const fetchData = async () => {
      const data = await appInfo(appId);
      setAppName(data);
    };
    fetchData();
  }, []);

  const percentage = (onlineSeconds / totalOnlineTime) * 100 || 0;
  return (
    <>
      <img src={iconURL} height={24} alt={appId} className={styles.appImage} />
      <DataTile
        className={styles.appTile}
        L2TopProps={{ label1: appName }}
        RightProps={{
          smallLabel1:
            t('appTime.percentageOfTime', {
              percentage: percentage.toFixed(1),
            }) || '',
        }}
        ProgressBarProps={{
          percent: (onlineSeconds / maxOnlineSeconds) * 100 || 0,
          color1: colorStyles.still600,
          color2: colorStyles.still600,
        }}
      />
    </>
  );
};

export const AppList: FunctionComponent<AppListProps> = ({
  applications,
  maxItems,
}) => {
  // sort by name if the onlineSeconds are equal
  const sortedApplications = applications
    .sort((app1, app2) =>
      app2.onlineSeconds === app1.onlineSeconds
        ? app1.appId > app2.appId
          ? 1
          : -1
        : app2.onlineSeconds - app1.onlineSeconds
    )
    .slice(0, maxItems);

  const maxOnlineSeconds = sortedApplications?.[0]?.onlineSeconds;

  const totalOnlineTime =
    applications
      ?.map((app) => {
        return app.onlineSeconds;
      })
      ?.reduce((app, acc) => acc + app, 0) || 0;

  return (
    <>
      {sortedApplications.map(({ appId, onlineSeconds }, i) => {
        return (
          <Fragment key={`${appId}_${i}`}>
            <div className={styles.appRow}>
              <AppItem
                appId={appId}
                onlineSeconds={onlineSeconds}
                maxOnlineSeconds={maxOnlineSeconds}
                totalOnlineTime={totalOnlineTime}
              />
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export const AppTimeUI: FunctionComponent<AppTimeUIProps> = ({
  className,
  title,
  isLoading,
  errorMessage,
  applications,
  viewAllHandler,
  maxItems = 20,
}) => {
  const { t } = useTranslation();

  const viewAllOnClick = useCallback(() => {
    if (viewAllHandler) viewAllHandler();
  }, [applications]);

  const noOfApps = applications.length;
  const showIsViewMore =
    noOfApps > 0
      ? {
          label: t('common.viewMore'),
          ariaLabel: t('common.viewMoreAriaLabel', { label: title }),
          onClick: viewAllOnClick,
        }
      : undefined;

  return (
    <>
      <Card
        className={cn(
          styles.appTimeCard,
          {
            [styles.withPlaceholder]: errorMessage || noOfApps === 0,
          },
          className
        )}
        isLoading={isLoading}
        errorMessage={errorMessage}
        emptyCardLabel={
          errorMessage || (noOfApps === 0 ? t('appTime.noEvents') : '')
        }
        header={{
          L2Props: {
            label: title,
            secondLabel: t('common.today'),
          },
        }}
        footer={showIsViewMore}
        noBottomPadding
      >
        {Boolean(applications.length) && (
          <AppList applications={applications.slice()} maxItems={maxItems} />
        )}
      </Card>
    </>
  );
};
