import cx from 'classnames';
import React, { FunctionComponent, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import useIsMobile from '../../../Utils/hooks/useIsMobile';
import Icon, { Props as IconProps } from '../../Elements/Icon';
import PendingContent from '../PendingContent/PendingContent';
import { AutoCompleteContext } from './context';
import { useClickOutside } from './hooks/useClickOutside';
import styles from './style.module.css';

export type AutoCompleteResultsItem = {
  id: string;
  label: string;
  type?: string;
  searchItem?: string;
};

export type AutoCompleteResultsProps = {
  items?: AutoCompleteResultsItem[];
  maxHeight?: number;
  onItemClick?: (item: AutoCompleteResultsItem) => void;
  toggle: (state: boolean) => void;
  isOpen: boolean;
  resultsClassname?: string;
  isShieldAutoComplete?: boolean;
};

type AutoCompleteResultItemProps = {
  item: AutoCompleteResultsItem;
  type: string;
  onItemClick: (item: AutoCompleteResultsItem) => void;
  isShieldAutoComplete?: boolean;
};

const AutoCompleteResultItem: FunctionComponent<
  AutoCompleteResultItemProps
> = ({ item, type, onItemClick, isShieldAutoComplete }) => {
  const mapResultTypeToIcon: Record<string, IconProps['name']> = {
    device: 'LaptopAndPhone',
    person: 'Person',
  };
  const iconName = mapResultTypeToIcon[type] || 'Person';
  return (
    <div
      onClick={() => onItemClick(item)}
      className={cx(styles.autoCompleteResultsItem, {
        [styles.shieldAutoCompleteResultsItem]: isShieldAutoComplete,
      })}
      role="option"
      tabIndex={0}
    >
      <Icon name={iconName} className={styles.autoCompleteResultsItemIcon} />
      <div
        className={styles.autoCompleteResultsItemLabel}
        data-testid="autoComplete-results-item"
      >
        <p aria-live="polite" role="status">
          {item.label}
        </p>
      </div>
    </div>
  );
};

const AutoCompleteResults: FunctionComponent<AutoCompleteResultsProps> = ({
  items,
  maxHeight,
  onItemClick,
  toggle,
  isOpen,
  resultsClassname,
  isShieldAutoComplete = false,
}) => {
  const { configuration, isLoading, closeSearchResults, availableFilters } =
    useContext(AutoCompleteContext);
  const isMobile = useIsMobile();
  const { ref } = useClickOutside({
    toggler: () => {
      toggle(false);
    },
    state: isOpen,
  });

  const onClick = (item: AutoCompleteResultsItem) => {
    if (configuration.closeOnSelectItem) {
      closeSearchResults();
    }
    onItemClick?.(item);
  };

  const shouldShowNoResultsText = !Boolean(items?.length) && !isLoading;
  const hasResults = Boolean(items?.length);
  const getFilteredResults = (
    itemType: string,
    items?: AutoCompleteResultsItem[]
  ) => {
    if (!items) {
      return [];
    }
    return items.filter((item) => item.type === itemType);
  };

  const renderResults = () => {
    if (!hasResults) {
      return null;
    }
    return (
      <>
        {availableFilters?.map((filter) => (
          <React.Fragment key={filter.name}>
            {getFilteredResults(filter.name, items).map((item) => (
              <AutoCompleteResultItem
                item={item}
                type={filter.name}
                onItemClick={onClick}
                key={item.id}
                isShieldAutoComplete={isShieldAutoComplete}
              />
            ))}
          </React.Fragment>
        ))}
      </>
    );
  };

  const pendingContent = () => (
    <PendingContent loading={isLoading} hideContent>
      {shouldShowNoResultsText && (
        <div
          className={cx(styles.autoCompleteResultsEmpty, {
            [styles.shieldAutoCompleteResultsItem]: isShieldAutoComplete,
          })}
        >
          <p aria-live="polite" role="alert">
            {configuration.noResultsText}
          </p>
        </div>
      )}

      {renderResults()}
    </PendingContent>
  );

  const rootClassNames = cx(styles.autoCompleteResults, {
    [styles.autoCompleteResultsMobile]: isMobile,
  });

  return (
    <div
      className={cx(rootClassNames, resultsClassname)}
      ref={ref}
      role="listbox"
    >
      {maxHeight ? (
        <PerfectScrollbar
          style={{
            maxHeight: `${maxHeight}px`,
          }}
          className={styles.autoCompleteResultsScrollbarContainer}
        >
          {pendingContent()}
        </PerfectScrollbar>
      ) : (
        pendingContent()
      )}
    </div>
  );
};

export default AutoCompleteResults;
