import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import PillSmall from 'UI/Elements/Pill small';

import { Heading4Medium, Heading4, Body2 } from 'UI/Elements/Typography';
import ContentLayout, { InlineContent } from 'UI/Components/ContentLayout';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';
import { Nullable } from 'Consts/types';

export type CardHeaderProps = {
  className?: string;
  left?: React.ReactNode;
  title?: Nullable<string>;
  titleModifier?: Nullable<string>;
  wrapTitle?: boolean;
  subtitle?: Nullable<string>;
  subtitleColor?: string;
  wrapSubtitle?: boolean;
  pillLabel?: Nullable<string>;
  right?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  isCollapsed?: boolean;
  onCollapseToggle?: React.MouseEventHandler;
};

const CardHeader: FunctionComponent<CardHeaderProps> = ({
  className,
  left,
  title,
  titleModifier,
  wrapTitle = false,
  subtitle,
  subtitleColor = 'still400',
  wrapSubtitle = false,
  pillLabel,
  right,
  onClick,
  isCollapsed = false,
  onCollapseToggle,
}) => {
  const { t } = useTranslation();

  const handleCollapseToggle = (ev: React.MouseEvent) => {
    if (onCollapseToggle) {
      ev.stopPropagation();
      onCollapseToggle(ev);
    }
  };

  const collapseButton = () => (
    <Icon
      name={IconNames[isCollapsed ? 'ChevronDown' : 'ChevronUp']}
      shape={IconShape.square}
      tooltipLabel={isCollapsed ? t('common.expand') : t('common.collapse')}
      onClick={handleCollapseToggle}
    />
  );

  return (
    <div
      className={cn(
        styles.cardHeader,
        { [styles.interactive]: !!onClick },
        className
      )}
    >
      <ContentLayout
        className={cn(className, {
          [styles.interactive]: onClick,
        })}
        left={left}
        main={
          <>
            <InlineContent>
              {title && (
                <Heading4Medium noWrap={!wrapTitle}>{title}</Heading4Medium>
              )}
              {titleModifier && (
                <Heading4 noWrap className={colorStyles.still400}>
                  {titleModifier}
                </Heading4>
              )}
              {pillLabel && <PillSmall>{pillLabel}</PillSmall>}
            </InlineContent>
            {subtitle && (
              <Body2
                noWrap={!wrapSubtitle}
                className={colorStyles[subtitleColor]}
              >
                {subtitle}
              </Body2>
            )}
          </>
        }
        right={
          onCollapseToggle ? (
            <InlineContent noGap>
              {right ? right : null}
              {collapseButton()}
            </InlineContent>
          ) : right ? (
            right
          ) : null
        }
        rightClassName={colorStyles.still400}
        onClick={onClick}
      />
    </div>
  );
};

export default CardHeader;
