import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { DeviceGroup, Maybe, Nullable, ZoneDevice } from 'Consts/types';
import { useTranslation } from 'react-i18next';

import Menu, { MenuItemProps } from 'UI/Components/Menu';

import DeviceListItem, { Paragraph } from 'UI/Components/Lists/List device';
import { V2Card } from 'UI/Elements/Card';
import Divider from 'UI/Elements/Divider';
import Icon, { IconName, IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import V2CardHeader from 'UI/Molecules/CardHeader';

import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';

import { formatRelativeToNow } from 'Utils/formatters/date';

import { useChangeGroup } from '../Device menu items';

import { smallProfilePhoto } from 'Consts/defintions';
import useEmployees from 'State/hooks/useEmployees';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import { InlineContent } from 'UI/Components/ContentLayout';
import useNavigateToDevice from 'Utils/hooks/useNavigateToDevice';
import useNavigateToEmployee from 'Utils/hooks/useNavigateToEmployee';
import styles from './style.module.css';

type DeviceItemProps = {
  device: ZoneDevice;
  withDivider: boolean;
  onMenuClick?: (ev: React.MouseEvent, device: ZoneDevice) => void;
  personIcon?: IconName;
};

export const DeviceItem: FunctionComponent<DeviceItemProps> = ({
  device,
  withDivider,
  onMenuClick,
  personIcon,
}) => {
  const { t } = useTranslation();
  const paragraphList: Paragraph[] = [];
  const navigateToEmployee = useNavigateToEmployee();
  const navigateToDevice = useNavigateToDevice();
  if (device?.connectionStateChangeAt) {
    const relativeTime = formatRelativeToNow(
      new Date(device?.connectionStateChangeAt)
    );

    if (device?.connectionState === 'connected') {
      paragraphList.push({
        label: t('devices.onlineSince', { time: relativeTime }),
        key: '1',
      });
    } else {
      paragraphList.push({
        label: t('homepage.lastOnline', { time: relativeTime }),
        key: '1',
      });
    }
  }

  // TODO need to understand which field is responsible for this element
  // if (item?.connectionInfo) {
  //   paragraphList.push({
  //     label: item?.connectionInfo,
  //     key: "2",
  //     prefix: <Icon className={styles.sharedAccess} name={IconNames.ShareAccess} />,
  //   })
  // }

  const handleMenuClick = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      onMenuClick!(ev, device);
    },
    [device, onMenuClick]
  );

  const handleEmployeeClick = useCallback(
    (ev: React.MouseEvent) => {
      if (!device.personId) {
        return;
      }
      ev.stopPropagation();
      navigateToEmployee(device.personId);
    },
    [device, navigateToEmployee]
  );

  const navigateToDevicePage = useCallback(() => {
    if (!device.mac) {
      return;
    }
    navigateToDevice(device.mac);
  }, [device, navigateToDevice]);

  return (
    <div
      className={
        withDivider
          ? 'deviceListItemWithDivider'
          : 'deviceListItemWithoutDivider'
      }
    >
      <DeviceListItem
        L1Props={{
          iconProps: personIcon
            ? {
                name: personIcon,
                shape: IconShape.smallCircle,
                onClick: handleEmployeeClick,
              }
            : undefined,
          mediumDeviceIconProps: { fileName: device.icon },
        }}
        L2Props={{ label: device.nickname || device.name, paragraphList }}
        RProps={
          !onMenuClick
            ? undefined
            : {
                iconProps: {
                  name: IconNames.OverflowVertical,
                  tooltipLabel: t('common.moreActions'),
                  className: styles.overflowIcon,
                  onClick: handleMenuClick,
                  ariaLabel: t('common.moreActions'),
                },
              }
        }
        onClick={navigateToDevicePage}
      />

      {withDivider && <Divider />}
    </div>
  );
};

export type ListItem = {
  id: number | string;
  name: string;
  activityInfo?: string;
  connectionInfo?: string;
  deviceIcon?: IconName;
};

type DevicesCardProps = {
  isLoading?: boolean;
  errorMessage?: string;
  columnSpan: 1 | 2 | 3;
  title?: string;
  devices: ZoneDevice[];
  group?: DeviceGroup;
  groupName?: string;
  headerMenuItems: MenuItemProps[];
  menuItems?: MenuItemProps[];
  onViewAllClick?: React.MouseEventHandler;
  className?: string;
  footerClassName?: string;
  itemLimitForOneColumn?: number | null;
  infoTooltipLabel?: string;
  infoTooltipDisabled?: boolean;
};

const DevicesCard: FunctionComponent<DevicesCardProps> = ({
  isLoading,
  errorMessage,
  devices = [],
  columnSpan = 1,
  title,
  group,
  groupName = '',
  headerMenuItems,
  menuItems,
  onViewAllClick,
  className,
  footerClassName,
  itemLimitForOneColumn = null,
  infoTooltipLabel = '',
  infoTooltipDisabled = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const changeMenuItems = useChangeGroup();
  const employees = useEmployees();
  const networkAccessData = useNetworkAccess();
  const secureNetworkAccessMode = networkAccessData?.data?.default?.purgatory;
  const changeMenuOpen = useSelector(
    selectors.ui.devicesAndGroups.changeMenuOpen
  );
  const headerMenuOpen = useSelector(
    selectors.ui.devicesAndGroups.headerMenuOpen
  );
  const deviceMenuOpen = useSelector(
    selectors.ui.devicesAndGroups.deviceMenuOpen
  );

  const itemsCount = devices?.length || 0;
  let itemsToDisplayLimit;
  let numberOfDisplayColumns = columnSpan;
  if (itemLimitForOneColumn! < 0) {
    itemsToDisplayLimit = itemsCount;
    console.error('Error assigning invalid limits for the devices card');
  } else if (itemLimitForOneColumn) {
    itemsToDisplayLimit = itemLimitForOneColumn * columnSpan;
  } else {
    itemsToDisplayLimit = itemsCount;
  }

  const itemsToDisplay = devices.slice(0, itemsToDisplayLimit!);

  const parentRef = useRef<Element | null>(null);

  const handleHeaderMenuOpen = useCallback(
    (ev: React.MouseEvent) => {
      dispatch(actions.ui.devicesAndGroups.selectDevices(devices));
      dispatch(
        actions.ui.devicesAndGroups.selectQuarantineDomain(
          devices?.[0]?.quarantineAnomalyBlacklistFqdn || null
        )
      );
      dispatch(actions.ui.devicesAndGroups.selectGroup(group ? group : null));

      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(actions.ui.devicesAndGroups.setHeaderMenuOpen(true));

      parentRef.current = ev.currentTarget;
    },
    [devices, dispatch, group]
  );

  const handleMenuOpen = useCallback(
    (ev: React.MouseEvent, device: ZoneDevice) => {
      dispatch(actions.ui.devicesAndGroups.selectDevices([device]));
      dispatch(
        actions.ui.devicesAndGroups.selectQuarantineDomain(
          device.quarantineAnomalyBlacklistFqdn || null
        )
      );
      dispatch(actions.ui.devicesAndGroups.selectGroup(group ? group : null));

      dispatch(actions.ui.devicesAndGroups.closeMenus());
      dispatch(actions.ui.devicesAndGroups.setDeviceMenuOpen(true));

      parentRef.current = ev.currentTarget;
    },
    [dispatch, group]
  );

  const handleOnClose = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
  }, [dispatch]);

  useEffect(() => {
    if (!changeMenuOpen && !headerMenuOpen && !deviceMenuOpen) {
      parentRef.current = null;
    }
  }, [changeMenuOpen, deviceMenuOpen, headerMenuOpen]);

  const items = useMemo(() => {
    return changeMenuOpen
      ? changeMenuItems
      : headerMenuOpen
      ? headerMenuItems
      : deviceMenuOpen
      ? menuItems
      : [];
  }, [
    changeMenuItems,
    changeMenuOpen,
    deviceMenuOpen,
    headerMenuItems,
    headerMenuOpen,
    menuItems,
  ]);
  const getImageId = (personId: Nullable<Maybe<string>>) => {
    if (!personId) {
      return;
    }
    return employees?.data?.filter((e) => e.id === personId)?.[0]?.imageId;
  };

  return (
    <>
      <Menu
        isOpen={changeMenuOpen || headerMenuOpen || deviceMenuOpen}
        parent={parentRef.current}
        items={items || []}
        onClose={handleOnClose}
      />

      <V2Card
        isLoading={isLoading}
        errorMessage={errorMessage}
        className={cn(
          styles.devicesCard,
          { [styles.infoTooltipDisabled]: infoTooltipDisabled },
          className
        )}
        span={numberOfDisplayColumns}
        emptyCardLabel={itemsCount === 0 ? t('devices.noDevices') : ''}
        header={
          <V2CardHeader
            title={title}
            titleModifier={isLoading ? '' : `${itemsCount}`}
            pillLabel={secureNetworkAccessMode ? groupName : ''}
            right={
              <InlineContent noGap>
                {!infoTooltipDisabled && (
                  <Icon
                    name={IconNames.Info}
                    shape={IconShape.square}
                    tooltipLabel={infoTooltipLabel}
                  />
                )}

                {headerMenuItems.length > 0 && (
                  <Icon
                    name={IconNames.OverflowVertical}
                    shape={IconShape.square}
                    tooltipLabel={t('common.moreActions')}
                    onClick={handleHeaderMenuOpen}
                    ariaLabel={t('common.moreActions')}
                  />
                )}
              </InlineContent>
            }
          />
        }
        footer={
          onViewAllClick
            ? {
                className: footerClassName,
                label: t('common.viewMore'),
                onClick: onViewAllClick,
                ariaLabel: groupName
                  ? t('devices.viewMoreGroupAriaLabel', { title, groupName })
                  : t('devices.viewMoreAriaLabel', { title }),
              }
            : undefined
        }
        noBottomPadding
      >
        <div
          className={cn({
            [styles.grid]: columnSpan === 2 || columnSpan === 3,
            [styles.spanTwoColumns]: columnSpan === 2,
            [styles.spanThreeColumns]: columnSpan === 3,
          })}
        >
          {itemsToDisplay.map((device, i) => {
            const divider =
              itemsToDisplay.length % columnSpan === 0
                ? i < itemsToDisplay.length - columnSpan
                : i <
                  Math.floor(itemsToDisplay.length / columnSpan) * columnSpan;
            const imageId = getImageId((device as ZoneDevice).personId);
            return (
              <DeviceItem
                key={(device as ZoneDevice).mac}
                device={device as ZoneDevice}
                withDivider={divider}
                onMenuClick={menuItems ? handleMenuOpen : undefined}
                personIcon={
                  imageId
                    ? smallProfilePhoto[imageId] || IconNames.Profile24Generic04
                    : undefined
                }
                aria-label={IconNames.Profile24Generic04}
              />
            );
          })}
        </div>
      </V2Card>
    </>
  );
};

export default DevicesCard;
