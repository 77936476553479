import React, {
  useState,
  useCallback,
  FunctionComponent,
  Fragment,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import colorStyles from 'Styles/colors.module.css';
import { SimpleSidePanel, SidePanelContentWrapper } from 'UI/Layout/Side panel';
import Card from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';
import Carousel from 'UI/Components/Carousel/Carousel';
import InputListItem from 'UI/Components/Lists/List input';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'State/store';
import * as actions from 'State/actions';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { largeProfilePhoto } from 'Consts/defintions';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { ZoneDevice, Mac, Person } from 'Consts/types';
import useDevices from 'State/hooks/useDevices';
import DeviceListItem, { Paragraph } from 'UI/Components/Lists/List device';
import SectionHeader from 'UI/Components/Headers/Header section';
import ShieldSettings from 'Pages/Settings/Left/Components/Shield settings';
import useLocations from 'State/hooks/useLocations';
import usePersonSecurityPolicy from 'State/hooks/usePersonSecurityPolicy';
import Divider from 'UI/Elements/Divider';
import useEmployees from 'State/hooks/useEmployees';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import { isZonesRoute } from 'Consts/routes';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { getConfigurationFromDomain } from 'subDomainConfiguration';

const BELL = 'bell';

type AddEmployeeSidePanelProps = {
  person?: Person;
  selectedDevices?: ZoneDevice[];
};
const AddEmployeeSidePanel: FunctionComponent<AddEmployeeSidePanelProps> = ({
  selectedDevices,
  person,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const personNickName = person?.nickname!;
  const [nickName, setNickName] = useState('');
  const [error, setError] = useState({ nickName: '' });

  const { closeSidepanel } = useSidepanel();
  const [assignedDevicesList, setAssignedDevicesList] = useState<Mac[]>([]);
  const { activeLocation } = useLocations();
  const { personSecurityPolicy, personShieldSettings } =
    usePersonSecurityPolicy(person?.id!);
  const environment = getConfigurationFromDomain();

  const handleChangePersonName = useCallback((value: string) => {
    setNickName(value);
  }, []);
  const { allDevices } = useDevices();
  const persons = useEmployees();
  const networkAccessData = useNetworkAccess();
  const personNicknameLookup = useCallback(
    (personId: string) => {
      if (persons)
        return persons.data?.find((p) => personId === p.id)?.nickname;
    },
    [persons]
  );
  const routerLocation = useLocation();
  const currentRoute = routerLocation.pathname;

  useEffect(() => {
    if (selectedDevices?.length) {
      selectedDevices.forEach((d) => {
        assignedDevicesList.push(d.mac);
      });
    }
    if (allDevices?.data && person) {
      allDevices.data.forEach((device) => {
        if (device.personId === person?.id)
          assignedDevicesList.push(device.mac);
      });
    }
    setAssignedDevicesList([...assignedDevicesList]);
  }, [actions.device]);

  useEffect(() => {
    if (!personNickName) {
      setNickName('');
    }
    setNickName(personNickName);
  }, [person]);

  const carouselItems = useMemo(() => {
    return Object.entries(largeProfilePhoto).map((item, i) => {
      return {
        id: item[0],
        name: item[1],
      };
    });
  }, []);

  const [index, setIndex] = useState(
    person?.imageId
      ? carouselItems.findIndex((item) => person?.imageId === item?.id)
      : 0
  );

  const imageId = useMemo(() => {
    if (index === -1) {
      setIndex(1);
      return carouselItems[1].id;
    } else {
      return carouselItems[index].id;
    }
  }, [carouselItems, index]);

  const handlePrevious = () => {
    setIndex(index === 0 ? carouselItems.length - 1 : index - 1);
  };

  const handleNext = () => {
    setIndex(index === carouselItems.length - 1 ? 0 : index + 1);
  };

  const addAssignDevice = useCallback(
    (devicelist: ZoneDevice) => {
      if (!devicelist) return;

      if (assignedDevicesList.includes(devicelist.mac)) {
        setAssignedDevicesList((current) =>
          current.filter((item) => item !== devicelist.mac)
        );
      } else {
        setAssignedDevicesList([...assignedDevicesList, devicelist.mac]);
      }
    },

    [setAssignedDevicesList, assignedDevicesList]
  );

  const deviceParagraphText = (device: ZoneDevice): Paragraph[] => {
    // Display Blocked device, if not blocked display if it is unapproved,
    // else display the current employee the device is assigned to.
    if (device.networkAccessMode === 'blocked')
      return [
        {
          label: t('common.blocked'),
          color: colorStyles.sore600,
        },
      ];
    if (
      device.networkAccessMode === 'auto' &&
      ((networkAccessData?.data?.default?.purgatory &&
        device.networkId === 'default') ||
        (networkAccessData?.data?.employee?.purgatory &&
          device.networkId === 'employee'))
    )
      return [
        {
          label: t('common.unapproved'),
          color: colorStyles.warm700,
        },
      ];
    if (device.personId)
      return [
        {
          label: t('devices.assignDeviceZone.newPersonAssigned', {
            employee: personNicknameLookup(device.personId),
          }),
        },
      ];
    return [];
  };

  const handleSubmit = useCallback(async () => {
    if (!person) {
      if (!nickName || !imageId) {
        setError((prev) => ({
          ...prev,
          nickName: t('onboarding.errorEmptyField'),
        }));
        return;
      }

      if (assignedDevicesList && assignedDevicesList.length > 0) {
        dispatch(
          actions.employees.addEmployee(nickName, imageId, assignedDevicesList)
        );
      } else {
        dispatch(actions.employees.addEmployee(nickName, imageId));
      }

      dispatch(
        actions.ui.miniBanner.notify({
          label: t('addEmployee.addEmployeeBanner'),
          state: 'set',
        })
      );
    } else if (person) {
      if (!nickName) {
        setError((prev) => ({
          ...prev,
          nickName: t('onboarding.errorEmptyField'),
        }));
        return;
      }
      if (assignedDevicesList.length && !person.primaryDevice) {
        dispatch(
          actions.employee.assignPrimaryDeviceToEmployee(
            person.id,
            assignedDevicesList[0]
          )
        );
      }
      dispatch(
        actions.employee.updateEmployee(
          person.id,
          nickName,
          imageId,
          assignedDevicesList
        )
      );
    }
    closeSidepanel();
  }, [person, nickName, imageId, assignedDevicesList, dispatch, t]);

  const sortedDevices = () =>
    allDevices?.data
      ?.filter(
        (device: ZoneDevice) =>
          device.networkId === 'employee' || device.networkId === 'default'
      )
      .sort((a, b) => a.name.localeCompare(b.name)) || [];
  const numSortedDevices = sortedDevices().length;

  const checkListOnIcon =
    environment.id === BELL ? IconNames.CheckListOnBell : IconNames.CheckListOn;

  const checkListOffIcon =
    environment.id === BELL
      ? IconNames.CheckListOffBell
      : IconNames.CheckListOff;

  return (
    <SimpleSidePanel
      title={!person ? t('common.addEmployee') : t('employeeZone.editProfile')}
    >
      <SidePanelContentWrapper className={styles.sidePanelContentWrapper}>
        <Card noBottomPadding>
          <div className={styles.cardWrapper}>
            <Carousel
              carouselItems={carouselItems}
              selectedIndex={index}
              onPrev={() => handlePrevious()}
              onNext={() => handleNext()}
            />
            <div className={styles.listInputConatiner}>
              <InputListItem
                className={styles.listInput}
                prefixIcon={IconNames.Person}
                smallLabel={t('employeeZone.personName') as string}
                value={nickName ? nickName : ''}
                paragraph={error.nickName ? error.nickName : ''}
                onChange={handleChangePersonName}
                hasError={Boolean(error.nickName)}
              />
            </div>
          </div>
        </Card>
        {person && (
          <ShieldSettings
            locationName={activeLocation.data?.name || ''}
            personSecurityPolicyData={personSecurityPolicy}
            shieldSettingsData={personShieldSettings}
            security="person"
            showShieldText={false}
            person={person}
          />
        )}
        <Card
          noBottomPadding
          className={cn(styles.addEmployeeDevicesCard, {
            [styles.empty]: numSortedDevices === 0,
          })}
          emptyCardLabel={
            numSortedDevices === 0
              ? t('devices.assignDeviceZone.noDevices')
              : ''
          }
        >
          <SectionHeader
            className={styles.sectionHeader}
            label={t('devices.assignDeviceZone.assignDevices')}
          />
          {sortedDevices().map((device: ZoneDevice, i) => (
            <Fragment key={`${device.mac}_${i}`}>
              {i !== 0 && <Divider />}
              <DeviceListItem
                L1Props={{
                  mediumDeviceIconProps: {
                    fileName: device.icon,
                    size: 'small',
                  },
                  className: styles.deviceWithNoPersonIcons,
                }}
                L2Props={{
                  label: device.nickname || device.name,
                  paragraphList: deviceParagraphText(device),
                }}
                RProps={{
                  iconProps: {
                    name: assignedDevicesList.includes(device.mac)
                      ? checkListOnIcon
                      : checkListOffIcon,
                    onClick: () => addAssignDevice(device),
                  },
                }}
                onClick={() => addAssignDevice(device)}
              />
            </Fragment>
          ))}
        </Card>
        <Button
          className={cn(styles.addEmployeeDoneBtn, {
            [styles.zonesNavBarButton]: isZonesRoute(currentRoute),
          })}
          theme={BUTTON_THEMES.black}
          label={t('common.done')}
          onClick={() => handleSubmit()}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default AddEmployeeSidePanel;
