import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type {
  LocationId,
  Location,
  EntitledAccess,
  LocationSelection,
} from 'Consts/types';

import * as api from 'Api/endpoints';
import { actions as authActions } from './authSlice';

import type { RootState, Action } from 'State/store';
import { Data, updateData } from 'State/utils';
import * as globalActions from '../../State/actions';

export type LocationsState = {
  activeLocationId: LocationId | null;
  activeLocation: Location | null;
  ownedLocations: Data<Location[]>;
  coAdminLocations: Data<EntitledAccess[]> | null;
};

const initialState: LocationsState = {
  activeLocationId: null,
  activeLocation: null,
  ownedLocations: updateData(null),
  coAdminLocations: updateData(null),
};

function updateActiveLocation(state: LocationsState) {
  let newActiveLocation = state.ownedLocations.data?.find(
    (location) => location.id === state.activeLocationId
  );

  if (!newActiveLocation && state.coAdminLocations?.data) {
    newActiveLocation = state.coAdminLocations.data.find(
      (location: any) => location.id === state.activeLocationId
    ) as Location | undefined;
  }

  state.activeLocation = newActiveLocation || state.activeLocation;
}

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setOwnedLocations(state, action: PayloadAction<Location[]>) {
      state.ownedLocations = updateData(action.payload);
      updateActiveLocation(state);
    },
    isLoading(state, action: PayloadAction<boolean>) {
      state.ownedLocations.isLoading = action.payload;
      state.ownedLocations.errorMessage = '';
    },
    error(state, action: PayloadAction<string>) {
      state.ownedLocations.isLoading = false;
      state.ownedLocations.errorMessage = action.payload;
      state.ownedLocations.lastAttempt = Date.now();
    },
    setCoAdminLocationAsActive(state, action: PayloadAction<Location>) {
      state.activeLocationId = action.payload.id;
      state.activeLocation = action.payload;
    },
    setCoAdminLocations(state, action: PayloadAction<EntitledAccess[]>) {
      state.coAdminLocations = updateData(action.payload);
      updateActiveLocation(state);
    },
    setOwnLocationAsActiveId(state, action: PayloadAction<LocationId>) {
      state.activeLocationId = action.payload;
      updateActiveLocation(state);
    },
  },
});

const fetchData = (): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId, activeLocation } = getState().locations;
    const customerId = getState().customer.customerId;
    const { data: token } = getState().auth.data;
    const { data: coAdminToken } = getState().auth.coAdminData;
    const cloud = getState().auth.cloud;
    const locations = getState().locations.ownedLocations;

    if (locations.isLoading || !customerId || !token) {
      return;
    }

    dispatch(actions.isLoading(true));

    const { data, error } = await api.getLocations({
      customerId,
      token,
      cloud,
    });

    if (error || !data) {
      dispatch(actions.error(error?.message || 'Error fetching locations'));

      return;
    }

    //call entitled Access also
    const { data: coAdminLocations, error: coAdminError } =
      await api.getEntitledAccess({
        customerId,
        token,
        cloud,
      });
    if (coAdminError || !coAdminLocations) {
      dispatch(
        actions.error(
          coAdminError?.message || 'Error fetching entitledAccess for Co-Admin'
        )
      );

      return;
    }

    dispatch(actions.setCoAdminLocations(coAdminLocations));

    const filteredOwnedLocations = data.filter(
      (loc) => loc.profile === 'smallbusiness'
    );

    if (!filteredOwnedLocations.length) {
      const emptyPayload = [] as Location[];
      dispatch(actions.setOwnedLocations(emptyPayload));
    } else {
      dispatch(actions.setOwnedLocations(filteredOwnedLocations));
    }

    if (!filteredOwnedLocations.length && !coAdminLocations.length) {
      dispatch(actions.error('noWorkPass'));
      return;
    }

    if (!activeLocationId) {
      const storedActiveLocationId = localStorage.getItem(
        'activeLocationId'
      ) as LocationId;

      if (storedActiveLocationId) {
        if (
          filteredOwnedLocations.findIndex((location) => {
            return location.id === storedActiveLocationId;
          }) > -1
        ) {
          dispatch(actions.setOwnLocationAsActiveId(storedActiveLocationId));
        } else {
          const coAdminLocationIndex = coAdminLocations.findIndex(
            (entitledAccess) => {
              return entitledAccess.location.id === storedActiveLocationId;
            }
          );
          if (coAdminLocationIndex > -1) {
            dispatch(
              authActions.getCoAdminTokenAndSetActiveLocation({
                customerId,
                locationId:
                  coAdminLocations?.[coAdminLocationIndex].location.id,
                ownerId: coAdminLocations?.[coAdminLocationIndex].ownerId,
              })
            );
          }
        }
        return;
      }

      if (filteredOwnedLocations.length) {
        // choose your own location first
        const defaultLocationId = filteredOwnedLocations[0].id;

        localStorage.setItem('activeLocationId', defaultLocationId);

        dispatch(actions.setOwnLocationAsActiveId(defaultLocationId));
      } else {
        const defaultLocationId = coAdminLocations[0].location.id;

        localStorage.setItem('activeLocationId', defaultLocationId);
        dispatch(
          authActions.getCoAdminTokenAndSetActiveLocation({
            customerId,
            locationId: coAdminLocations?.[0].location.id,
            ownerId: coAdminLocations?.[0].ownerId,
          })
        );
      }
    } else {
      if (activeLocation?.accessedAsCoAdminLocation && coAdminToken) {
        // refresh the coAdmin Location using specific token
        const { data, error } = await api.getLocation({
          customerId: activeLocation.customerId,
          locationId: activeLocation.id,
          token: coAdminToken || '',
          cloud,
        });

        if (error) {
          dispatch(actions.error(error.message));
        }
        if (data) {
          data.accessedAsCoAdminLocation = true;
          dispatch(actions.setCoAdminLocationAsActive(data));
          return data;
        }
      }
    }
  };
};

const createLocation = ({ name }: { name: string }): Action => {
  return async (dispatch, getState) => {
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !token) {
      return;
    }

    const { error } = await api.createLocation({
      customerId,
      data: { name },
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

const renameLocation = ({
  name,
  locationId,
}: {
  name: string;
  locationId: string;
}): Action => {
  return async (dispatch, getState) => {
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !token) {
      return;
    }

    const { error } = await api.renameLocation({
      customerId,
      locationId,
      data: { name },
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(actions.fetchData());
  };
};

const switchLocation = ({ locationId }: { locationId: string }): Action => {
  return async (dispatch, getState) => {
    // hacky but safe for now
    localStorage.setItem('activeLocationId', locationId);

    window.location.reload();
  };
};

const updateActiveLocationAppTime = (appTimeEnabled: boolean): Action => {
  return async (dispatch, getState) => {
    const { activeLocationId } = getState().locations;
    const customerId = getState().locations.activeLocation?.customerId;
    const { data: token } = getState().locations.activeLocation
      ?.accessedAsCoAdminLocation
      ? getState().auth.coAdminData
      : getState().auth.data;
    const cloud = getState().auth.cloud;

    if (!customerId || !activeLocationId || !token) {
      return;
    }

    const { error } = await api.updateLocationAppTime({
      customerId,
      locationId: activeLocationId,
      data: { enable: appTimeEnabled, appliesToAllDevices: true },
      token,
      cloud,
    });

    if (error) {
      dispatch(actions.error(error.message));

      return;
    }

    dispatch(actions.isLoading(false));
    dispatch(globalActions.locationAppTime.fetchData());
  };
};

export const selectors = {
  activeLocationId: (state: RootState) => state.locations.activeLocationId,
  ownedLocations: (state: RootState) => state.locations.ownedLocations,
  activeLocation: (state: RootState) => ({
    ...state.locations.ownedLocations,
    data: state.locations.activeLocation,
  }),
  coAdminLocations: (state: RootState) => state.locations.coAdminLocations,
  mergedLocations: (state: RootState): LocationSelection[] => {
    return [
      ...(state.locations.ownedLocations.data || []).map((location) => {
        return {
          id: location.id,
          name: location.name,
          coAdmin: false,
        };
      }),
      ...(state.locations.coAdminLocations?.data || []).map((location) => {
        return {
          id: location.location.id,
          name: location.location.name,
          coAdmin: true,
        };
      }),
    ];
  },
};

export const actions = {
  ...slice.actions,
  fetchData,
  createLocation,
  renameLocation,
  updateActiveLocationAppTime,
  switchLocation,
};

export default slice.reducer;
