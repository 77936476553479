import cn from 'classnames';
import React, { FunctionComponent } from 'react';

import L1, { L1Props } from 'UI/Elements/L1';
import ProgressBar, {
  Props as ProgressBarProps,
} from 'UI/Elements/Progress bar';
import { Body1, Body1Medium, Body2 } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors';

import styles from './style.module.css';

type L2TopProps = {
  label1: string;
  label2?: string;
  className?: string;
};

type L2BottomProps = {
  label: string;
  className?: string;
};

type RightProps = {
  smallLabel1?: string;
  smallLabel2?: string;
  className?: string;
};

type DataTileProps = {
  className?: string;
  onClick?: React.MouseEventHandler;
  L1Props?: L1Props;
  L2TopProps: L2TopProps;
  L2BottomProps?: L2BottomProps;
  RightProps?: RightProps;
  ProgressBarProps?: ProgressBarProps;
};

const Top: FunctionComponent<L2TopProps> = ({ label1, label2, className }) => {
  return (
    <div className={cn(styles.top, className)}>
      <div className={styles.topLeft}>
        {label1 && !label2 ? (
          <Body1>{label1}</Body1>
        ) : (
          <>
            <Body1Medium>{label1}</Body1Medium>
            <Body1 style={{ color: colorStyles.still400 }}>{label2}</Body1>
          </>
        )}
      </div>
    </div>
  );
};

const Bottom: FunctionComponent<L2BottomProps> = ({ label, className }) => {
  return (
    <div className={className}>
      <Body2>{label}</Body2>
    </div>
  );
};

const Right: FunctionComponent<RightProps> = ({
  smallLabel1,
  smallLabel2,
  className,
}) => {
  return (
    <div className={cn(styles.right, className)}>
      {smallLabel1 && <Body2>{smallLabel1}</Body2>}
      {smallLabel2 && <Body2>{smallLabel2}</Body2>}
    </div>
  );
};

const DataTile: FunctionComponent<DataTileProps> = ({
  className,
  onClick,
  L1Props,
  L2TopProps,
  L2BottomProps,
  ProgressBarProps,
  RightProps,
}) => {
  return (
    <div className={cn(styles.dataTile, className)} onClick={onClick}>
      <L1 {...L1Props} />
      <div className={styles.left}>
        <div className={styles.leftTop}>
          <Top {...L2TopProps} />
          <Right {...RightProps} />
        </div>

        {ProgressBarProps && (
          <ProgressBar
            {...ProgressBarProps}
            className={ProgressBarProps?.className}
          />
        )}
        {L2BottomProps && <Bottom {...L2BottomProps} />}
      </div>
    </div>
  );
};

export default DataTile;
