import { profilePhoto } from 'Consts/defintions';
import { AppTimeSpecificEmployee } from 'Pages/Zones/Components/Employees/Components/AppTime';
import * as actions from 'State/actions';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import useDevices from 'State/hooks/useDevices';
import useEmployee from 'State/hooks/useEmployee';
import useEmployeeLoginDatabase from 'State/hooks/useEmployeeLoginDatabase';
import useLocationCapabilities from 'State/hooks/useLocationCapabilities';
import usePersonLast12MonthsAppsOnlineTime from 'State/hooks/usePersonLast12MonthsAppsOnlineTime';
import { AppDispatch } from 'State/store';
import colorStyles from 'Styles/colors.module.css';
import Alert from 'UI/Components/Alert';
import ScreenHeader from 'UI/Components/Headers/Header screen web';
import Menu, { MenuItemProps } from 'UI/Components/Menu';
import { BUTTON_THEMES } from 'UI/Elements/Button';
import { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import Loader from 'UI/Elements/Loader';
import AlertModal from 'UI/Elements/Modals/Alerts';
import Background from 'UI/Layout/Background';
import Columns from 'UI/Layout/Columns';
import Page from 'UI/Layout/Page';
import pageHeaderStyles from 'UI/Layout/Page header/style.module.css';
import AddEmployeeSidePanel from 'UI/Reusable/AddEmployee/AddEmployee';
import { EmployeeAssignedDevices } from 'UI/Reusable/Employees at work';
import ShareAccessSidePanel from 'UI/Reusable/ShareAccess/ShareAccessSidePanel';
import { formatHour, formatRelativeToNow } from 'Utils/formatters/date';
import useLayoutColumns from 'Utils/hooks/useLayout';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import cn from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AssignPrimaryDevice from './Components/AssignPrimaryDevice/AssignPrimaryDevice';
import DownloadEmployeeData from './Components/DownloadEmployeeData/DownloadEmployeeData';
import EmployeeAtWork from './Components/EmployeeAtWork';
import EmployeeDataUsageGraph from './Components/EmployeeDataUsageGraph';
import EmployeeShieldGraph from './Components/EmployeeShieldGraph';
import OnlineActivityGraph from './Components/OnlineActivityGraph';
import PrimaryDeviceTile from './Components/PrimaryDeviceTile';
import styles from './style.module.css';

const Employee: FunctionComponent = () => {
  const { t } = useTranslation();
  const { closeSidepanel, setContent } = useSidepanel();
  const navigate = useNavigate();
  const { allDevices } = useDevices();
  const { id } = useParams();
  const [columns, ref] = useLayoutColumns();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRemovePersonAlertOpen, setIsRemovePersonAlertOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const parentRef = useRef<Element | null>(null);
  const devices = allDevices.data;
  const personId = id!;
  usePersonLast12MonthsAppsOnlineTime(personId);

  const {
    data: employeedata,
    isLoading: isLoadingEmployee,
    errorMessage: employeeErrorMessage,
  } = useEmployee(personId);

  const employeeErrorRef = useRef(employeeErrorMessage);
  const person = !isLoadingEmployee && employeedata ? employeedata : undefined;

  const personWasManuallyCreated =
    person?.serviceLinking?.providerId !== 'warden';

  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const employeeLoginIsAvailableToCustomer =
    customerSupportConfigurations?.customerFeatureEnablement
      .employeeOnboardingCaptivePortalEnabled;

  const { data: locationCapabilitiesData } = useLocationCapabilities();
  const employeeLoginIsSupportedInHardware =
    locationCapabilitiesData?.state.capabilities?.captivePortalV2;

  const { data: employeeLoginDatabaseData } = useEmployeeLoginDatabase();
  const employeeLoginToggleIsEnabled =
    employeeLoginDatabaseData?.databaseData.enabled;

  const warnAboutManualCreation =
    personWasManuallyCreated &&
    employeeLoginIsAvailableToCustomer &&
    employeeLoginIsSupportedInHardware &&
    employeeLoginToggleIsEnabled;

  useEffect(() => {
    employeeErrorRef.current = employeeErrorMessage;
  }, [employeeErrorMessage]);

  const handleEditProfileSideBar = useCallback(() => {
    if (!person || !devices) {
      return;
    }
    setContent(<AddEmployeeSidePanel person={person} />);
  }, [setContent, person]);

  const primaryDevice =
    (devices &&
      person &&
      devices.filter((device) => device.mac === person.primaryDevice)) ||
    null;

  const assignedDevices = useMemo(() => {
    return (
      devices
        ?.filter((device) => device.personId === person?.id)
        .map((device) => device.mac) || []
    );
  }, [devices, person?.id]);

  const shouldDisablePrimaryDeviceTile = useMemo(() => {
    return !!(assignedDevices.length === 1 && primaryDevice?.length);
  }, [primaryDevice, assignedDevices]);

  const handleEditPrimaryDeviceSidebar = useCallback(() => {
    if (!devices || !person || shouldDisablePrimaryDeviceTile) {
      return;
    }

    setContent(<AssignPrimaryDevice devices={devices} employee={person} />);
  }, [setContent, devices, person]);

  const handleShareAccess = useCallback(() => {
    dispatch(actions.ui.devicesAndGroups.closeMenus());
    setContent(<ShareAccessSidePanel person={person} />);
  }, [setContent, dispatch, person]);

  const handleDownloadEmployeeData = useCallback(() => {
    setContent(<DownloadEmployeeData personId={personId} />);
  }, []);

  const handleRemovePersonModalDisplay = useCallback(() => {
    if (!devices || !person) {
      return;
    }
    setIsRemovePersonAlertOpen(true);
  }, [devices, person]);

  const handleRemovePerson = useCallback(() => {
    if (!devices || !person) {
      return;
    }

    const successMessage = t('employeeZone.removedPerson', {
      personName: person.nickname,
    });
    dispatch(actions.employee.removeEmployee(person.id, successMessage));

    closeRemovePersonAlert();
    setTimeout(() => {
      if (employeeErrorRef.current === '') {
        navigate(-1);
      }
    }, 4100);
  }, [devices, person, employeeErrorRef]);

  const closeRemovePersonAlert = useCallback(() => {
    setIsRemovePersonAlertOpen(false);
  }, []);

  const handleMenuOpen = useCallback((ev: React.MouseEvent) => {
    setIsMenuOpen(true);

    parentRef.current = ev.currentTarget;
  }, []);

  const handleOnClose = useCallback(() => {
    setIsMenuOpen(false);
    parentRef.current = null;
  }, []);

  const atWork =
    person && person.atHomeChangedAt
      ? person.atHome
        ? t('homepage.atWorkSince', {
            time: formatHour(new Date(person.atHomeChangedAt)),
          })
        : t('homepage.lastOnline', {
            time: formatRelativeToNow(new Date(person.atHomeChangedAt)),
          })
      : '';

  const menuItems: MenuItemProps[] = [
    {
      label: t('employeeZone.editProfile'),
      iconName: IconNames.Person,
      onClick: handleEditProfileSideBar,
    },
    {
      label: t('employeeZone.editPrimaryDevice'),
      iconName: IconNames.Devices,
      onClick: handleEditPrimaryDeviceSidebar,
    },
    {
      label: t('devices.shareAccess'),
      iconName: IconNames.ShareAccess,
      onClick: handleShareAccess,
    },
    {
      label: t('employeeZone.downloadEmployeeData'),
      iconName: IconNames.ArrowDownStop,
      onClick: handleDownloadEmployeeData,
    },
    {
      label: t('employeeZone.removePerson'),
      iconName: IconNames.X,
      theme: 'danger',
      onClick: handleRemovePersonModalDisplay,
    },
  ];

  const removeEmployeeModalParagraph = () => {
    if (personWasManuallyCreated) {
      return t('employeeZone.removePersonModal.warningText', {
        personName: person?.nickname,
      });
    } else {
      return (
        <div className={styles.removeEmployeeModalParagraph}>
          <span>
            {t('employeeZone.removePersonModal.warningText', {
              personName: person.nickname,
            })}
          </span>
          <span>
            {t('employeeZone.removePersonModal.wardenEmployeeWarningSubText', {
              personName: person.nickname,
            })}
          </span>
        </div>
      );
    }
  };

  return (
    <Page>
      {!person || !devices ? (
        <div className={styles.employeeLoader}>
          <Loader />
        </div>
      ) : (
        <>
          <Background ref={ref}>
            <ScreenHeader
              className={pageHeaderStyles.pageHeader}
              LProps={{
                label: person.nickname,
                paragraph: atWork,
                iconProps: {
                  name: IconNames.ChevronLeft,
                  onClick: () => {
                    navigate(-1);
                    closeSidepanel();
                  },
                  tooltipLabel: t('common.goBack'),
                  shape: IconShape.square,
                },
              }}
              RProps={{
                avatarProps: {
                  name:
                    profilePhoto[person.imageId] ||
                    IconNames.Profile56Generic11,
                  className: cn(styles.avatarIcon),
                },
                icon1Props: warnAboutManualCreation
                  ? {
                      name: IconNames.PersonExclamation,
                      className: cn(colorStyles.sore500),
                      onClick: () => console.log('FIXME: JIRA-6159'),
                      tooltipLabel: t('employeeZone.personExclamation'),
                      shape: IconShape.square,
                    }
                  : undefined,
                icon2Props: {
                  name: IconNames.OverflowVertical,
                  className: cn(styles.OverflowVertical),
                  onClick: handleMenuOpen,
                  tooltipLabel: '',
                  ariaLabel: t('common.moreActions'),
                  shape: IconShape.square,
                },
              }}
            />
            <Menu
              isOpen={isMenuOpen}
              parent={parentRef.current}
              items={menuItems}
              onClose={handleOnClose}
            />
            <Columns columnCount={columns} topMargin={16}>
              <PrimaryDeviceTile
                primaryDevice={primaryDevice}
                onClick={handleEditPrimaryDeviceSidebar}
                shouldDisable={shouldDisablePrimaryDeviceTile}
              />

              <EmployeeAtWork personId={personId} />
              <EmployeeAssignedDevices devices={devices} employee={person} />
              <EmployeeDataUsageGraph personId={personId} />

              <OnlineActivityGraph
                personId={personId}
                primaryDevice={primaryDevice && primaryDevice[0]}
              />

              <EmployeeShieldGraph personId={personId} />

              <AppTimeSpecificEmployee personId={personId} maxItems={6} />
            </Columns>

            <AlertModal
              isOpen={isRemovePersonAlertOpen}
              onClose={closeRemovePersonAlert}
            >
              <Alert
                topProps={{
                  label: t('employeeZone.removePersonModal.title', {
                    personName: person.nickname,
                  }),
                  paragraph: removeEmployeeModalParagraph(),
                  className: styles.modalHeader,
                }}
                bottomProps={{
                  button1Props: {
                    label: t('common.remove'),
                    onClick: handleRemovePerson,
                  },
                  button2Props: {
                    label: t('common.cancel'),
                    onClick: closeRemovePersonAlert,
                    theme: BUTTON_THEMES.white,
                  },
                }}
              />
            </AlertModal>
          </Background>
        </>
      )}
    </Page>
  );
};

export default Employee;
