import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { PERIODS } from 'Consts/defintions';

import {
  Categories,
  DataDailyToday,
  DataLast30Days,
  Period,
} from 'Consts/types';

import CategoryDataUsageGraph from 'UI/Reusable/Data usage graph/Category';
import dataUsageGraphTooltip from 'UI/Reusable/Data usage graph/Components/Data usage graph tooltip';

import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { displayBytes } from 'Utils/mbMath';

import {
  mostUsedCategories,
  generateUsageDataCategoryList,
  getHourlyDataGraph,
  getDailyDataGraph,
  getCategoryGraphData,
  getDataAverageLabel,
  formatHourlyStats,
  formatDailyStats,
} from '../../utils';

import { DataUsageColors } from 'Consts/defintions';

import DataUsageSidePanel from '../Data usage sidepanel';

type DataUsageUIProps = {
  daily: DataDailyToday | null;
  monthly: DataLast30Days | null;
  span?: 2 | 3;
};

const DataUsageGraph: FunctionComponent<DataUsageUIProps> = ({
  daily,
  monthly,
  span,
}) => {
  const { content, setContent } = useSidepanel();
  const { t } = useTranslation();

  const [period, setPeriod] = useState<Period>(PERIODS.day);
  const [displaySidePanel, setDisplaySidePanel] = useState(false);

  const prevPeriod = useRef<Period>(period);

  const { hourlyStats, overallStats } = daily || {};
  const { dailyStats, monthlyStats } = monthly || {};

  const statsByHour = useMemo(
    () => formatHourlyStats(hourlyStats),
    [hourlyStats]
  );
  const statsByDay = useMemo(
    () => formatDailyStats(dailyStats, period),
    [dailyStats, period]
  );

  const sortedCategoryData = useMemo(() => {
    return generateUsageDataCategoryList(
      period === PERIODS.day ? statsByHour : statsByDay
    );
  }, [period, statsByDay, statsByHour]);

  const mostUsed = useMemo(
    () => mostUsedCategories(sortedCategoryData),
    [sortedCategoryData]
  );

  const itemsToShow = useMemo(() => {
    return mostUsed.reduce((acc, cur) => {
      return { ...acc, [cur.name]: 0 };
    }, {});
  }, [mostUsed]);

  const colors = useMemo(() => {
    return mostUsed.reduce((acc, cur, index) => {
      acc[cur.name] = DataUsageColors[index];

      return acc;
    }, {} as Record<Categories, string>);
  }, [mostUsed]);

  const graphData = useMemo(() => {
    if (period === PERIODS.day) {
      return getHourlyDataGraph(statsByHour, itemsToShow);
    }

    return getDailyDataGraph(statsByDay, itemsToShow, period);
  }, [itemsToShow, period, statsByDay, statsByHour]);

  const displayUsageSidePanel = () => {
    setDisplaySidePanel(true);
    handleUsageSidebar();
  };

  const handleUsageSidebar = useCallback(() => {
    setContent(<DataUsageSidePanel statistics={sortedCategoryData} />);
  }, [sortedCategoryData, setContent]);

  useEffect(() => {
    if (!content && displaySidePanel) {
      setDisplaySidePanel(false);
    }
    if (displaySidePanel && content && period !== prevPeriod.current) {
      handleUsageSidebar();

      prevPeriod.current = period;
    }
  }, [content, handleUsageSidebar, period]);

  const { data: displayGraphData, unit } = useMemo(() => {
    return getCategoryGraphData(graphData);
  }, [graphData]);

  const totalData = sortedCategoryData.reduce(
    (acc, [, number]) => acc + number,
    0
  );

  const secondLabel = totalData ? displayBytes(totalData) : t('common.noData');

  const { i18key, value } = getDataAverageLabel({
    period,
    dailyStats: statsByDay,
    dailyAverage: overallStats?.hourlyAvgTotalBytes,
    monthlyAverage: monthlyStats?.dailyAvgTotalBytes,
  });

  return (
    <CategoryDataUsageGraph
      span={span}
      label={t('common.dataUsage')}
      secondLabel={secondLabel}
      graphProps={{
        data: displayGraphData,
        customTooltip: dataUsageGraphTooltip(unit),
        colors,
        filters: mostUsed,
        period,
        setPeriod,
        averageLabel: t(i18key, { value }),
        onCategoryIconClick: displayUsageSidePanel,
      }}
    />
  );
};

export default DataUsageGraph;
