import React, { FunctionComponent } from 'react';
import Card from 'UI/Elements/Card';
import cn from 'classnames';
import styles from '../style.module.css';
import { ZoneDevice } from 'Consts/types';
import StandardListItem from 'UI/Components/Lists/List standard';
import { makeTextExcerpt } from 'Utils/formatters/string';
import { useTranslation } from 'react-i18next';

type PrimaryDeviceTileProps = {
  primaryDevice: ZoneDevice[] | null;
  onClick: () => void;
  shouldDisable?: boolean;
};

const PrimaryDeviceTile: FunctionComponent<PrimaryDeviceTileProps> = ({
  primaryDevice,
  onClick,
  shouldDisable,
}) => {
  const { t } = useTranslation();
  const device = primaryDevice ?? [];

  return (
    <Card
      className={cn(styles.primaryDeviceTileCard, {
        [styles.primaryDevice]: !primaryDevice,
      })}
      noBottomPadding
    >
      <StandardListItem
        L2Props={{
          label: t('homepage.primaryDevice'),
          className: cn({
            [styles.deviceTitle]: primaryDevice,
          }),
        }}
        RProps={{
          className: cn({
            [styles.deviceName]: primaryDevice,
            [styles.assign]: !primaryDevice,
          }),
          label: makeTextExcerpt(device[0]?.name, 20) ?? t('homepage.assign'),
        }}
        onClick={onClick}
        className={cn({
          [styles.listTile]: primaryDevice,
          [styles.assignTile]: !primaryDevice,
          [styles.disable]: shouldDisable,
        })}
        ariaLabel={t('homepage.primaryDevice')}
      />
    </Card>
  );
};

export default PrimaryDeviceTile;
