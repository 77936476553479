import cn from 'classnames';
import React, { FunctionComponent, HTMLProps } from 'react';

import L1, { L1Props } from 'UI/Elements/L1';
import R, { RProps } from 'UI/Elements/R';
import { Body1, Body2, Heading4 } from 'UI/Elements/Typography';

import colorStyles from 'Styles/colors.module.css';

import { onEnterKeydown } from 'Utils/keyboardEvents';
import { Nullable } from '../../../../Consts/types';
import styles from './style.module.css';

export type L2Props = {
  label?: Nullable<string>;
  headingLabel?: Nullable<string>;
  smallLabel?: Nullable<string>;
  paragraph?: Nullable<string>;
  secondParagraph?: Nullable<string>;
  thirdParagraph?: Nullable<string>;
  eyebrow?: Nullable<string>;
  className?: string;
  withLeftPadding?: boolean;
};

const L2: FunctionComponent<L2Props> = ({
  label,
  headingLabel,
  smallLabel,
  paragraph,
  secondParagraph,
  thirdParagraph,
  eyebrow,
  className,
  withLeftPadding,
}) => {
  const containerClassName = cn(
    styles.l2,
    { [styles.l2l40]: withLeftPadding },
    className
  );

  if (!label && smallLabel && !paragraph && !eyebrow) {
    return (
      <div className={containerClassName}>
        <Body2 className={colorStyles.still400}>{smallLabel}</Body2>
      </div>
    );
  }

  if (
    !smallLabel &&
    (label || headingLabel || paragraph || secondParagraph || eyebrow)
  ) {
    return (
      <div className={containerClassName}>
        {eyebrow && <Body2 className={colorStyles.still400}>{eyebrow}</Body2>}
        {label && <Body1 className={styles.label}>{label}</Body1>}
        {headingLabel && (
          <Heading4 className={styles.headingLabel}>{headingLabel}</Heading4>
        )}
        {paragraph && (
          <Body2 className={colorStyles.still400}>{paragraph}</Body2>
        )}
        {secondParagraph && (
          <Body2 className={colorStyles.still400}>{secondParagraph}</Body2>
        )}
        {thirdParagraph && (
          <Body2 className={colorStyles.still400}>{thirdParagraph}</Body2>
        )}
      </div>
    );
  }

  return null;
};

type ListProps = {
  className?: string;
  inline?: boolean;
  L1Props?: L1Props;
  L2Props?: L2Props;
  RProps?: RProps;
  navItemTheme?: string;
  ariaLabel: string;
  onClick?: React.MouseEventHandler;
  role?: string;
  tabIndex?: number;
};

const StandardListItem: FunctionComponent<
  ListProps & HTMLProps<HTMLDivElement>
> = ({
  className,
  inline = false,
  L1Props = {},
  L2Props = {},
  RProps = {},
  navItemTheme,
  ariaLabel,
  onClick,
  role,
  ...rest
}) => {
  return (
    <div
      className={cn(
        {
          [styles.interactive]: !!onClick,
          [styles.blockStandardListItem]: !inline,
          [styles.inlineStandardListItem]: inline,
        },
        className
      )}
      onMouseDown={onClick}
      data-testid="list"
      color-theme={navItemTheme}
      role={role || (onClick ? 'button' : '')}
      tabIndex={onClick ? 0 : undefined}
      aria-label={ariaLabel}
      onKeyDown={(e) => onEnterKeydown(e, onClick)}
      {...rest}
    >
      <L1 {...L1Props} />
      <L2 {...L2Props} />
      <R {...RProps} />
    </div>
  );
};

export default StandardListItem;
