import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AppCountPerCategory, Period } from 'Consts/types';

import PillWeb from 'UI/Elements/Pill web';
import { IconNames } from 'UI/Elements/Icon';

import styles from './style.module.css';
import { MenuItemProps } from 'UI/Components/Menu';
import Filters from 'UI/Reusable/Filters/Filters';

type PeriodProps = {
  key: Period;
  i18labelKey: string;
};

const PERIOD_LIST: Partial<Record<Period, PeriodProps>> = {
  day: {
    key: 'day',
    i18labelKey: 'common.today',
  },
  month: {
    key: 'month',
    i18labelKey: 'common.30days',
  },
  year: {
    key: 'year',
    i18labelKey: 'common.year',
  },
};

type AppTimeFilterProps = {
  period: Period;
  setPeriod: Dispatch<SetStateAction<Period>>;
  category: string;
  setCategory: Dispatch<SetStateAction<string>>;
  categorySet: AppCountPerCategory[];
};

export const MobileAppTimeFilters = ({
  period,
  setPeriod,
  category,
  setCategory,
}: AppTimeFilterProps) => {
  const { t } = useTranslation();
  const parentRef = useRef<Element | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = useCallback((ev: React.MouseEvent) => {
    setIsMenuOpen(true);
    parentRef.current = ev.currentTarget;
  }, []);

  const handleOnClose = useCallback(() => setIsMenuOpen(false), []);

  const commonFilter = { filterKey: 'Filter', label: t('common.filter') };

  const periodList = useMemo(() => {
    return Object.values(PERIOD_LIST).map((item, i) => {
      return {
        label: t(item.i18labelKey),
        onClick: () => setPeriod(item.key),
        iconName: period === item.key ? IconNames.Check : undefined,
      };
    });
  }, [period, setPeriod, t]);

  return (
    <Filters
      className={styles.filterBlock}
      handleOnClose={handleOnClose}
      menuItems={[...periodList]}
      parent={parentRef.current}
      isMenuOpen={isMenuOpen}
      handleClick={handleClick}
      filters={[commonFilter]}
    />
  );
};

export const AppTimeFilters = ({
  period,
  setPeriod,
  category,
  setCategory,
  categorySet,
}: AppTimeFilterProps) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const parentRef = useRef<Element | null>(null);

  const menuItems: MenuItemProps[] = categorySet.map((categoryItem) => {
    return {
      label: t(`categories.${categoryItem.category}`),
      iconName:
        category === (categoryItem.category as string)
          ? IconNames.Check
          : undefined,
      theme: categoryItem.appCount === 0 ? 'notAvailable' : undefined,
      onClick: () => setCategory(categoryItem.category as string),
    };
  });

  const handleOnClose = useCallback(() => {
    setIsMenuOpen(false);
    parentRef.current = null;
  }, []);

  const setReferenceClick = useCallback(
    (ev: React.MouseEvent, item: string) => {
      parentRef.current = ev.currentTarget;
      setIsMenuOpen(true);
    },
    []
  );

  const CATEGORY_FILTERS = [
    { label: t(`categories.${category}`), filterKey: 'category' },
  ];

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.filterBlock}>
        {Object.keys(PERIOD_LIST).map((item) => {
          const currentPeriod = PERIOD_LIST[item as Period];
          const isActive = currentPeriod?.key === period;

          return (
            <PillWeb
              isSelected={isActive}
              key={item}
              label={t(currentPeriod?.i18labelKey || '')}
              onClick={() => {
                if (currentPeriod?.key) setPeriod(currentPeriod?.key);
              }}
            />
          );
        })}
      </div>
      <Filters
        parent={parentRef.current}
        isMenuOpen={isMenuOpen}
        menuItems={menuItems}
        handleClick={setReferenceClick}
        handleOnClose={handleOnClose}
        filters={CATEGORY_FILTERS}
        maxHeight={420}
      />
    </div>
  );
};
