import React, { FunctionComponent, useCallback } from 'react';
import cn from 'classnames';
import { Person } from 'Consts/types';
import Icon, { IconNames } from 'UI/Elements/Icon';
import { Body2Medium } from 'UI/Elements/Typography';
import { profilePhoto } from 'Consts/defintions';

import styles from './style.module.css';
import { getConfigurationFromDomain } from 'subDomainConfiguration';

const BELL = 'bell';

type AvatarProps = {
  person: Person;
  isChecked?: Boolean;
  isDesaturated?: Boolean;
  onClick: (person: Person, assigned: Boolean) => void;
};

const Avatar: FunctionComponent<AvatarProps> = ({
  person,
  isChecked = false,
  isDesaturated = false,
  onClick,
}) => {
  const environment = getConfigurationFromDomain();
  const handleOnClick = useCallback(
    () => onClick(person, isChecked!),
    [person, onClick, isChecked]
  );

  const { nickname, imageId } = person;
  const firstName = nickname.split(' ');

  return (
    <div
      className={cn({
        [styles.avatar]: !isDesaturated,
        [styles.avatarDesaturated]: isDesaturated,
      })}
      onClick={handleOnClick}
    >
      <Icon name={profilePhoto[imageId] || IconNames.Profile56Generic04} />
      {isChecked && (
        <Icon
          className={styles.checkIcon}
          name={
            environment.id === BELL
              ? IconNames.CheckListOnBell
              : IconNames.CheckListOn
          }
        />
      )}

      <Body2Medium className={styles.personName}>{firstName[0]}</Body2Medium>
    </div>
  );
};

export default Avatar;
