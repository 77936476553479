import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { NetworkType } from 'Consts/types';
import { NETWORK_IDS } from 'Consts/defintions';

import ScreenHeader from 'UI/Components/Headers/Header screen web';

import { useSidepanel } from 'Utils/hooks/useSidepanel';

import { ALL_SETTINGS, SettingsKey, SETTINGS_LIST } from '../definitions';

import { ProtectedWifiConfig, GuestWifiConfig } from './Components/Wifi config';

import {
  WifiNameEditSidepanel,
  WifiPasswordEditSidepanel,
} from './Components/Wifi config/Components/Edit';

import Pods from './Components/Pods';
import Support from './Components/Support';
import Account from './Components/Account';

import styles from './style.module.css';
import ShieldSettings from '../Left/Components/Shield settings';
import useLocations from 'State/hooks/useLocations';
import useSecurityPolicy from 'State/hooks/useSecurityPolicy';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { useTrackEvent } from 'trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from 'trackingAnalytics/types';
import EmployeeLoginSidePanel from './Components/Wifi config/Components/EmployeeLogin/EmployeeLoginSidePanel';
import EmployeeLogin from './Components/Wifi config/Components/EmployeeLogin/EmployeeLogin';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'Consts/routes';
import BlockedEmployeesSidePanel from './Components/Wifi config/Components/EmployeeLogin/BlockedEmployeesSidePanel';

export type SidepanelKeys =
  | 'closed'
  | 'editWifiName'
  | 'editWifiPassword'
  | 'editEmployeeLogin'
  | 'blockedEmployees';

type SettingsContentProps = {
  active: SettingsKey;
  openSidepanel: (key: SidepanelKeys) => void;
  setActive?: (index: SettingsKey) => void | undefined;
};

type SettingsContentConfigProps = {
  active: SettingsKey;
  setActive?: (index: SettingsKey) => void | undefined;
};

export const SIDEPANELS: Record<SidepanelKeys, SidepanelKeys> = {
  closed: 'closed',
  editWifiName: 'editWifiName',
  editWifiPassword: 'editWifiPassword',
  editEmployeeLogin: 'editEmployeeLogin',
  blockedEmployees: 'blockedEmployees',
};

const LookupMixPanelScreen: Record<SettingsKey, AvailableScreens> = {
  secure: AvailableScreens.SettingsSecure,
  employee: AvailableScreens.SettingsEmployee,
  employeeLogin: AvailableScreens.SettingsEmployeeLogin,
  guest: AvailableScreens.SettingsGuest,
  pods: AvailableScreens.SettingsPods,
  account: AvailableScreens.SettingsAccount,
  support: AvailableScreens.SettingsSupport,
  shield: AvailableScreens.SettingsShield,
  index: AvailableScreens.Settings,
};

const SettingsContent: FunctionComponent<SettingsContentProps> = ({
  active,
  openSidepanel,
  setActive,
}) => {
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const { activeLocation } = useLocations();
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();
  const {
    securityPolicy,
    shieldSettings,
    locationSecurityAppliesToAllDevices,
  } = useSecurityPolicy();
  const openEditNameSidepanel = useCallback(() => {
    openSidepanel(SIDEPANELS.editWifiName);
  }, [openSidepanel]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: LookupMixPanelScreen[active],
      },
    });
  }, [active]);

  const openEditPasswordSidepanel = useCallback(() => {
    openSidepanel(SIDEPANELS.editWifiPassword);
  }, [openSidepanel]);

  const handleSetEmployeeLogin = useCallback(() => {
    navigate(ROUTES.settings.employeeLogin);
    setActive?.(ALL_SETTINGS.employee.uniqueKey);
  }, [setActive]);

  const openBlockedEmployeeSidepanel = useCallback(() => {
    openSidepanel(SIDEPANELS.blockedEmployees);
  }, [openSidepanel]);

  switch (active) {
    case ALL_SETTINGS.shield.uniqueKey:
      return (
        <ShieldSettings
          active={active}
          locationName={activeLocation.data?.name || ''}
          shieldSettingsData={shieldSettings}
          securityPolicyData={securityPolicy}
          locationSecurityAppliesToAllDevices={
            locationSecurityAppliesToAllDevices
          }
          security="general"
        />
      );

    case ALL_SETTINGS.secure.uniqueKey:
      return (
        <ProtectedWifiConfig
          label={t('settings.secure', { ns: namespace })}
          uniqueKey={SETTINGS_LIST.secure}
          networkId={NETWORK_IDS.default}
          onEditName={openEditNameSidepanel}
          onEditPassword={openEditPasswordSidepanel}
        />
      );

    case ALL_SETTINGS.employee.uniqueKey:
      return (
        <ProtectedWifiConfig
          label={t('settings.employee', { ns: namespace })}
          uniqueKey={SETTINGS_LIST.employee}
          networkId={NETWORK_IDS.employee}
          onEditName={openEditNameSidepanel}
          onEditPassword={openEditPasswordSidepanel}
          onEditEmployeeLogin={handleSetEmployeeLogin}
          onOpenBlockedEmployees={openBlockedEmployeeSidepanel}
        />
      );

    case ALL_SETTINGS.employeeLogin.uniqueKey:
      return <EmployeeLogin openSidepanel={openSidepanel} />;

    case ALL_SETTINGS.guest.uniqueKey:
      return <GuestWifiConfig onEditName={openEditNameSidepanel} />;

    case ALL_SETTINGS.pods.uniqueKey:
      return <Pods />;

    case ALL_SETTINGS.support.uniqueKey:
      return <Support />;

    case ALL_SETTINGS.account.uniqueKey:
      return <Account />;

    default:
      return null;
  }
};

const SettingsContentConfig: FunctionComponent<SettingsContentConfigProps> = ({
  active,
}) => {
  const { setContent, closeSidepanel } = useSidepanel();
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();

  const openSidePanel = useCallback(
    (newActive: SidepanelKeys) => {
      let networkId: NetworkType = NETWORK_IDS.default;

      if (active === ALL_SETTINGS.secure.uniqueKey) {
        networkId = NETWORK_IDS.default;
      } else if (active === ALL_SETTINGS.employee.uniqueKey) {
        networkId = NETWORK_IDS.employee;
      } else if (active === ALL_SETTINGS.employeeLogin.uniqueKey) {
        networkId = NETWORK_IDS.employee;
      } else if (active === ALL_SETTINGS.guest.uniqueKey) {
        networkId = NETWORK_IDS.guest;
      }

      if (newActive === SIDEPANELS.closed) {
        closeSidepanel();
      } else if (newActive === SIDEPANELS.editWifiName) {
        setContent(<WifiNameEditSidepanel networkId={networkId} />);
      } else if (newActive === SIDEPANELS.editWifiPassword) {
        setContent(<WifiPasswordEditSidepanel networkId={networkId} />);
      } else if (newActive === SIDEPANELS.editEmployeeLogin) {
        setContent(<EmployeeLoginSidePanel />);
      } else if (newActive === SIDEPANELS.blockedEmployees) {
        setContent(<BlockedEmployeesSidePanel />);
      } else {
        closeSidepanel();
      }
    },
    [active, closeSidepanel, setContent]
  );

  if (active === SETTINGS_LIST.index) {
    return null;
  }

  const label = t(ALL_SETTINGS[active].i18labelKey, {
    ns: namespace,
  });

  if (active === SETTINGS_LIST.employeeLogin) {
    return (
      <div className={styles.employeeLoginRightWrapper}>
        <EmployeeLogin openSidepanel={openSidePanel} />
      </div>
    );
  }

  return (
    <div className={styles.rightWrapper}>
      <ScreenHeader LProps={{ label }} />

      <SettingsContent active={active} openSidepanel={openSidePanel} />
    </div>
  );
};

export default SettingsContentConfig;
