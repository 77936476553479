import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'Consts/routes';
import { EMAIL_REGEX } from 'Consts/defintions';

import InputListItem from 'UI/Components/Lists/List input';

import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import Icon, { IconNames } from 'UI/Elements/Icon';

import * as api from 'Api/endpoints';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';

import {
  OnboardingPageWrapper,
  OnboardingPageTopContent,
  OnboardingPageBottomContent,
} from '../Components/Onboarding page wrapper';
import CloudSelector from '../Components/CloudSelector';
import ResetResendButton from '../Components/Reset password button';

import onboardingStyles from '../style.module.css';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import { cloudLookupFromGlobalAuth } from 'Consts/environments';

type VerificationEmailProps = {
  email: string;
  resendEmail: () => void;
  onBack: React.MouseEventHandler;
};

const VerificationEmail: FunctionComponent<VerificationEmailProps> = ({
  email,
  resendEmail,
  onBack,
}) => {
  const [emailSent, setEmailSent] = useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false);
  const { t } = useTranslation();

  const handleOpenEmail = useCallback(() => {
    if (email) {
      window.open(`mailto:${email}`);
    }
  }, [email]);

  const handleResendEmail = useCallback(() => {
    resendEmail();
    setEmailSent(true);
  }, [resendEmail]);

  useEffect(() => {
    if (emailSent) {
      setShowResendEmail(false);
    }

    const timer = setTimeout(() => {
      setEmailSent(false);
      setShowResendEmail(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [emailSent]);

  return (
    <OnboardingPageWrapper
      label={t('onboarding.checkYourEmail')}
      subLabel={t('onboarding.sentMagicLinkText', { email })}
    >
      <OnboardingPageTopContent>
        <Icon name={IconNames.Group} />
      </OnboardingPageTopContent>

      <OnboardingPageBottomContent>
        <ResetResendButton
          label={t('onboarding.resendEmail')}
          emailSent={!showResendEmail}
          theme={BUTTON_THEMES.white}
          onClick={handleResendEmail}
        />

        <Button label={t('onboarding.openEmail')} onClick={handleOpenEmail} />

        <Button
          theme={BUTTON_THEMES.noborder}
          label={t('common.back')}
          onClick={onBack}
        />
      </OnboardingPageBottomContent>
    </OnboardingPageWrapper>
  );
};

export default function MagicLinkPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();

  const cloud = useSelector(selectors.auth.cloud);
  const authError = useSelector(selectors.auth.authError);
  const { data, errorMessage: magicLinkError } = useSelector(
    selectors.magicLink
  );

  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem('customerEmail') || ''
  );
  const [isCloudMenuOpen, setIsCloudMenuOpen] = useState(false);
  const [error, setError] = useState('');
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const { data: globalAuthData } = useSelector(
    selectors.globalAuthLoginOptions
  );

  const cloudListFromGlobalAuth = globalAuthData?.legacyLoopbackAuth
    ?.filter((loopbackauth) => loopbackauth.loginType === 'MagicLink')
    .map((loopback) => cloudLookupFromGlobalAuth[loopback.deploymentName]);

  const enableUsernamePasswordLink =
    !globalAuthData ||
    globalAuthData?.legacyLoopbackAuth?.some((element) => {
      return element.loginType === 'UsernamePassword';
    });

  const reset = useCallback(() => {
    setError('');
    dispatch(actions.auth.clear());
    dispatch(actions.magicLink.clear());
    intervalId && clearInterval(intervalId);
    setIntervalId(null);
  }, [dispatch, intervalId]);

  const handleChange = useCallback(
    (value: string) => {
      const trimmedEmail = value.trim();
      localStorage.setItem('customerEmail', trimmedEmail);
      reset();

      setEmail(trimmedEmail);
    },
    [reset]
  );

  const handleBack = useCallback(() => {
    setEmailSent(false);
    reset();

    intervalId && clearInterval(intervalId);
  }, [intervalId, reset]);

  const handleCloudMenuClose = useCallback(() => setIsCloudMenuOpen(false), []);
  const handleCloudMenuOpen = useCallback((ev: React.MouseEvent) => {
    setIsCloudMenuOpen(true);
  }, []);

  const handleLogin = useCallback(async () => {
    if (!email) {
      setError(() => t('onboarding.errorEmptyField'));
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      setError(() => t('onboarding.errorInvalidEmail'));
      return;
    }
    intervalId && clearInterval(intervalId);
    setIntervalId(null);
    dispatch(actions.magicLink.sendMagicLink(email.toLowerCase()));
  }, [dispatch, email, t, intervalId]);

  const polling = useCallback(async () => {
    if (!data || intervalId) {
      return;
    }
    const id = setInterval(async () => {
      const response = await api.getCustomer({
        token: data?.token,
        customerId: data?.customerId,
        cloud,
      });

      if (response.data) {
        clearInterval(id);
        dispatch(
          actions.auth.loginWithMagicLink({
            token: data?.token,
            customerId: data?.customerId,
          })
        );
        dispatch(actions.magicLink.clear());
      }
    }, 1000);

    setIntervalId(id);
  }, [cloud, data, dispatch, intervalId]);

  useEffect(() => {
    if (data) {
      setEmailSent(true);
      polling();
    }
  }, [data, polling]);

  useEffect(() => {
    reset();
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.MagicLinkPage,
      },
    });
    if (cloudListFromGlobalAuth) {
      dispatch(actions.auth.setCloud(cloudListFromGlobalAuth[0]));
    }
  }, []);

  useEffect(() => {
    if (authError) {
      const errorMessage = capitalize(authError);
      setError(errorMessage);
    }
  }, [authError]);

  useEffect(() => {
    if (magicLinkError) {
      const errorMessage = magicLinkError;
      setError(errorMessage);
    } else {
      setError('');
    }
  }, [magicLinkError]);

  const paragraph = email ? t('onboarding.sendMagicLinkText') : '';

  if (emailSent) {
    return (
      <VerificationEmail
        email={email}
        resendEmail={handleLogin}
        onBack={handleBack}
      />
    );
  }

  return (
    <OnboardingPageWrapper
      label={t('onboarding.signInWithMagicLink')}
      subLabel={t('onboarding.signInSubLabel')}
    >
      <OnboardingPageTopContent>
        <InputListItem
          className={onboardingStyles.stretch}
          prefixIcon={IconNames.EmailIcon}
          placeholder={t('onboarding.yourEmail')}
          smallLabel={email ? (t('onboarding.yourEmail') as string) : ''}
          paragraph={error ? error : paragraph}
          value={email}
          onChange={handleChange}
          onSubmit={handleLogin}
          hasError={Boolean(error)}
          disabled={Boolean(globalAuthData)}
        />

        {(!cloudListFromGlobalAuth || cloudListFromGlobalAuth?.length > 1) && (
          <CloudSelector
            isOpen={isCloudMenuOpen}
            onSelect={handleCloudMenuOpen}
            onClose={handleCloudMenuClose}
            onSubmit={handleLogin}
            enabledCloudList={cloudListFromGlobalAuth}
          />
        )}
      </OnboardingPageTopContent>

      <OnboardingPageBottomContent>
        {enableUsernamePasswordLink && (
          <Button
            theme={BUTTON_THEMES.white}
            label={t('onboarding.signInWithPassword')}
            onClick={() => navigate(ROUTES.onboarding.login)}
          />
        )}

        <Button label={t('onboarding.next')} onClick={handleLogin} />

        <Button
          theme={BUTTON_THEMES.noborder}
          label={t('common.back')}
          onClick={() => {
            navigate(-1);
          }}
        />
      </OnboardingPageBottomContent>
    </OnboardingPageWrapper>
  );
}
