import cn from 'classnames';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { isSettingsRoute, isZonesRoute } from 'Consts/routes';

import TopBar from 'UI/Layout/Top bar';
import { DesktopNavPanel, MobileNavPanel } from 'UI/Layout/Nav panel';
import QuestionButton from 'UI/Layout/Question button';

import Loader from 'UI/Elements/Loader';
import { MiniBannerWrapper } from 'UI/Elements/Mini banner';
import SidepanelModal from 'UI/Elements/Modals';

import * as selectors from 'State/selectors';

import { useSidepanel } from 'Utils/hooks/useSidepanel';
import useWindowDimensions from 'Utils/hooks/useWindowSize';

import styles from './style.module.css';
import { Nullable } from '../../../Consts/types';
import { isMobileIos } from 'Utils/isMobileIos';

type PageProps = {
  noHorizontalScroll?: boolean;
  noVerticalScroll?: boolean;
} & JSX.IntrinsicElements['div'];

const Page: FunctionComponent<PropsWithChildren<PageProps>> = ({
  noHorizontalScroll = false,
  noVerticalScroll = false,
  className,
  children,
}) => {
  const { content: sidepanelContent, closeSidepanel } = useSidepanel();
  const routerLocation = useLocation();
  const { width } = useWindowDimensions();
  const navPanelHidden = useSelector(selectors.ui.page.navPanelHidden);
  const sidepanelOpen = useSelector(selectors.ui.page.sidepanelOpen);
  const sidepanelIsClosing = useSelector(selectors.ui.page.sidepanelIsClosing);

  const pageWrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const sidepanelRef = useRef<HTMLDivElement>(null);
  const sidepanelOverlayRef = useRef<HTMLDivElement>(null);

  const currentRoute = routerLocation.pathname;
  const handleNavigation = useCallback(() => {
    closeSidepanel();
  }, [closeSidepanel]);

  const withTimeout = width > 472;
  const sidePanelOverlay =
    withTimeout && (navPanelHidden ? width < 1137 : width < 1417);

  return (
    <div
      className={cn(styles.page, className, {
        [styles.pageMobileIos]: isMobileIos(),
      })}
    >
      <TopBar
        className={cn({
          [styles.topBarWithSidepanelOverlay]: sidePanelOverlay,
        })}
      />

      <div ref={pageWrapperRef} className={styles.pageWrapper}>
        <DesktopNavPanel onNavigate={handleNavigation} />

        <div
          className={cn(styles.pageContent, {
            [styles.pageContentZonesMobileNav]: isZonesRoute(currentRoute),
            [styles.pageContentNoHorizontalScroll]: noHorizontalScroll,
            [styles.pageContentNoVerticalScroll]: noVerticalScroll,
          })}
          tabIndex={0}
          role="main"
        >
          {children}
        </div>

        <CSSTransition
          nodeRef={sidePanelOverlay ? sidepanelOverlayRef : sidepanelRef}
          in={sidepanelOpen && !sidepanelIsClosing}
          className={cn(
            { [styles.sidepanelWithOverlayRoot]: sidePanelOverlay },
            { [styles.sidePanelRoot]: !sidePanelOverlay },
            className
          )}
          classNames={{
            enter: styles.sidePanelRootEnter,
            exitActive: styles.sidePanelRootExitActive,
            exit: styles.sidePanelRootExit,
          }}
          unmountOnExit
          timeout={withTimeout ? 500 : 0}
        >
          {sidePanelOverlay ? (
            <SidepanelModal
              ref={sidepanelOverlayRef}
              parent={pageWrapperRef.current}
              isOpen={sidepanelOpen}
              onClose={closeSidepanel}
            >
              {sidepanelContent}
            </SidepanelModal>
          ) : (
            <div ref={sidepanelRef}>{sidepanelContent}</div>
          )}
        </CSSTransition>

        <MiniBannerWrapper />
      </div>

      <QuestionButton
        className={cn(styles.question, {
          [styles.questionZonesMobileNav]:
            isZonesRoute(currentRoute) || isSettingsRoute(currentRoute),
        })}
      />

      <MobileNavPanel onNavigate={handleNavigation} />
    </div>
  );
};

export const PageLoading: FunctionComponent = () => (
  <Page>
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  </Page>
);

export default Page;
