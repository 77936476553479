import cn from 'classnames';
import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './style.module.css';

type TooltipProps = {
  className?: string;
  style?: React.CSSProperties;
};

const Tooltip = forwardRef<HTMLDivElement, PropsWithChildren<TooltipProps>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn(styles.tooltip, className)} {...props}>
        {children}
      </div>
    );
  }
);

export default Tooltip;
