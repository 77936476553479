export type CustomerId = string;
export type LocationId = string;
export type AccountId = string;
export type NetworkType = 'default' | 'employee' | 'guest';
export type DeviceId = string;
export type Context = 'employees';
export type Period = 'day' | 'week' | 'month' | 'year';
export type SpeedDimension = 'download' | 'upload';
export type BandwidthUnits = 'mb' | 'gb';
export type DataUnits = BandwidthUnits | 'bytes' | 'kb';
export type SecurityContentTypes =
  | 'kids'
  | 'teenagers'
  | 'adBlocking'
  | 'adultAndSensitive'
  | 'workAppropriate';
export type GroupingTypes = 'perTimeSlot' | 'overall';
export type ConnectionStateTypes = 'connected' | 'disconnected';

export type Unixtime = number; // 1666138699357
export type Time = string; // "2022-10-19T00:18:11.367Z"
export type Date = string; // "2022-10-19"
export type UIVersion = string; // "1.105.0 (15eb5ce)"
export type Version = '1.0.0' | '1.0.1' | '1.1.0' | '2.0.0';
export type Mode = 'auto' | 'disable' | 'enable';

export type Token = string;

export type Mac = string;

export type VapType = 'home' | 'fronthaul' | 'captivePortal';

export type SecureNetworkZone = 'default' | 'employee';
export type NetworkZone = SecureNetworkZone | 'guest';

export type CloudBackend =
  | 'CI'
  | 'DOGFOOD'
  | 'BETA'
  | 'GAMMA'
  | 'KAPPA'
  | 'DELTA'
  | 'OSYNC'
  | 'OPENSYNC'
  | 'ETNA'
  | 'THETA'
  | 'THETASTAGE'
  | 'THETADEV'
  | 'PHI';

export type EnvironmentSettings = {
  apiUrl: string;
  metasaurusUrl: string;
  globalAuthUrl?: string;
  auth0Domain?: string;
  auth0ClientId?: string;
};

export type ConnectionState = ConnectionStateTypes | null;

type DaysOfWeek = (1 | 2 | 3 | 4 | 5 | 6 | 7)[];

export type Schedule = {
  startTime?: string;
  endTime?: string;
  times?: [
    {
      start: string; // "20:00",
      end: string; // "06:00",
    }
  ];
  daysOfWeek?: DaysOfWeek; //[7, 1, 2, 3, 4,],
  macs?: Mac[];
};

export type TimeTemplate = {
  id: string; // "schoolNights",
  name: string; // "School Nights",
  enable: boolean;
  schedules?: Schedule[];
};
export type LeafToRoot = {
  id: string; // node (pod) id
  nickname: string;
  parentId?: string;
  radio?: FreqBand;
  channel?: Channel;
  medium?: 'wifi' | 'ethernet';
};

export type Freeze = {
  frozen: boolean;
  suspended: {
    id: 'suspend';
    name: 'Suspend';
    enable: boolean;
  };
  timeTemplates: TimeTemplate[];
  autoExpire: {
    id: 'autoExpire';
    enable: boolean;
    expiresAt: null;
  };
  schedules: [];
  forever: {
    id: 'forever';
    name: 'Indefinitely';
    enable: boolean;
  };
};

export type FreezeTemplate = {
  id: string; //"untilMidnight",
  startTime?: string; //"00:00"
  endTime?: string; //"00:00",
  macs?: Mac[];
  endDate?: Date; //"2022-03-17"
  daysOfWeek?: DaysOfWeek;
  createdAt?: Time;
  freeze?: boolean;
  priority?: number;
  schedules?: Schedule | Schedule[];
};

export type Customer = {
  id: CustomerId;
  accountId: AccountId;
  anonymous: boolean;
  autoProvisioned: boolean;
  name: string;
  locked?: boolean;
  partnerId: string | null;
  acceptLanguage: string; // "en-US",
  preferredLanguage?: string; // "en",
  source: string; // "customCreate",
  createdAt: Time; // "2021-08-05T08:40:46.323Z",
  _version: Version; // "1.0.0",
  email: string; // "dvrecer+lte@plume.com",
  emailVerified: boolean;
  provisioningSsoAuditTrail?: boolean;
  termsAndPrivacy?: {
    termsDocumentId: string; // 1,
    termsAcceptedAt: string; // "2021-10-08T14:57:45.799Z",
    privacyDocumentId: string; // 0,
    privacyAcceptedAt: string; // "2021-10-08T14:57:45.799Z"
  };
  firstKnownLoginTimestamp?: string; // "2021-08-05T08:41:14.818Z",
  loginFailure?: Unixtime[];
  partnerName?: string; // "mocha-partner"
};

export type Channel = 1 | 11 | 44 | 60 | 108 | 124 | 157;

type Pcs = {
  status: 'succeeded';
  channels: Channel[];
};

export type FreqBand = '5GU' | '2.4G' | '5GL' | '5G' | '6G';

type Priority = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

type Optimization = {
  id: string; // "d1fdf8f7d398495a934f61abe2c61069",
  state: 'optimized';
  priority: Priority;
  triggers: ['Scheduled'];
  deviatedNodes: [];
  stateChangedAt: Unixtime; // 1666138699357,
  createdAt: Time; // "2022-10-19T00:18:11.367Z",
  autoOptimization: boolean;
  pcs?: Pcs;
  uuid: string; // "d1fdf8f7d398495a934f61abe2c61069"
};

export type ApplicationInfo = {
  id: string;
  name: { en: string; fr?: string };
  display: boolean;
};

export type DeviceGroup = {
  devices: Mac[];
  groupId: string;
  networkId: NetworkType;
  standalone: boolean;
  name: string;
  groupShares: string[];
};

export type NetworkAccessMode = 'auto' | 'approved' | 'blocked' | 'quarantined';

export type UseDevicesType = {
  allDevices: {
    data: ZoneDevice[] | null;
    isLoading: boolean;
    lastUpdated?: number;
    errorMessage: string;
  };
  defaultDevices: {
    auto: ZoneDevice[];
    approved: ZoneDevice[];
    blocked: ZoneDevice[];
    quarantined: ZoneDevice[];
  } | null;
  employeeDevices: {
    auto: ZoneDevice[];
    approved: ZoneDevice[];
    blocked: ZoneDevice[];
    quarantined: ZoneDevice[];
  } | null;
  guestDevices: {
    auto: ZoneDevice[];
    approved: ZoneDevice[];
    blocked: ZoneDevice[];
    quarantined: ZoneDevice[];
  } | null;
};

export type ZoneDevice = {
  connectionStateChangeAt: Time | null;
  name: string;
  nickname: string | null;
  connectionState: ConnectionState;
  id?: DeviceId;
  mac: Mac; // "56:8a:ff:ac:a9:0b",
  icon: string; // "smartphone-android",
  networkAccessMode: NetworkAccessMode;
  networkId: NetworkType;
  personId?: string | null;
  ip?: string;
  health: {
    score: number;
    status: string;
    details: null;
  } | null;
  leafToRoot?: LeafToRoot[];
  channel?: Channel;
  freqBand?: FreqBand;
  category?: DeviceCategory;
  medium?: 'ethernet' | 'wifi';
  appTime: boolean;
  quarantineAnomalyBlacklistFqdn?: string;
};

export type DeviceCategory =
  | 'Laptop'
  | 'Smart Phone'
  | 'Tablet'
  | 'Game Console'
  | 'Car'; // not an exhaustive list

export type FullDevice = {
  connectionStateChangeAt: Time | null;
  name: string;
  connectionState: ConnectionState;
  id?: DeviceId;
  mac: Mac; // "56:8a:ff:ac:a9:0b",
  icon: string; // "smartphone-android",
  networkAccess: { mode: NetworkAccessMode };
  networkId: NetworkType;
  personId?: string | null;

  keyId?: 0 | -2;
  hostName?: string;
  medium?: 'ethernet' | 'wifi';
  vapType?: VapType; // "home",
  favorite: boolean;
  hiddenAt?: Time;
  appTime: boolean;
  bandSteering: {
    _version: Version;
    enable: boolean;
    auto: boolean;
  };
  clientSteering: ClientSteering;
  qos: {
    prioritization: {
      mode: 'auto';
      realizedState?: 'ignore' | 'video';
    };
  };
  quarantine?: {
    enable: boolean;
    anomalyBlacklist?: [{ fqdn: string }];
  };
  ip?: string;
  ipv6?: string[];
  channel?: Channel;
  freqBand?: FreqBand;
  steering: Record<string, unknown>;
  iconV2?: string;
  iconV3?: string;
  category?: DeviceCategory; //"Laptop",
  brand?: string; //"Microsoft",
  osName?: string; //"Windows",
  model?: string;
  health: {
    score: number;
    status: string;
    details: null;
  } | null;
  leafToRoot?: LeafToRoot[];
  alerts: [];
  freeze: Freeze;
  locallyAdministeredShifty: boolean;
  locallyAdministeredShiftyExpired: boolean;
  locallyAdministeredMac: boolean;
  accessZone: 'home' | 'guests';
  firstConnectedAt?: Time;
  capabilities: {
    radio24: boolean;
    radio50: boolean;
    radio60: boolean;
  };
  features: Record<string, unknown>;
  isPartnerComponent: boolean;
  kind?: {
    id: string;
    typeIdentified: boolean;
    type?: {
      id: string; // "samsungGalaxy",
      category?: DeviceCategory; // "Smart Phone",
      brand?: string; // "Samsung",
      name?: string; // "Galaxy",
      model?: string; // "S20+",
      modelNumber?: string; //"SM-G985F",
      icon: string; // "smartphone-android",
      iconV2: string; // "smartphone-samsung-galaxy",
      iconV3?: string;
      osName?: string;
      osVersion?: string;
      source?: 'rules';
      confidence: number; // 0 | 320 | 375 | 400 | 600 | 700,
      stationary: boolean;
    };
    category?: DeviceCategory; // "Smart Phone",
    brand?: string; // "Samsung",
    name?: string; // "David-Mobile",
    model?: string; // "S20+",
    modelNumber?: string; // "SM-G985F",
    icon?: string; // "smartphone-android",
    iconV2?: string; // "smartphone-samsung-galaxy",
    iconV3?: string;
    osName?: string;
    osVersion?: string;
    source?: 'rules';
    confidence?: number;
    stationary?: boolean;
    ohpCapable?: boolean;
  };
  nickname: string | null;
  roomId?: null;
  plumeTypeIdentified: boolean;
  customerTypeIdentified: boolean;
  ohp: {
    capable: boolean;
    updatedAt?: Time;
    disableMobilizeSdk?: boolean;
  };
  networkHistory?: {
    networkId: NetworkType;
    connectionStateChangedAt: Time;
  }[];
  groupOfUnassignedDevices: boolean;
};

type ClientSteering = {
  _version: Version;
  auto: boolean;
  mode?: Mode;
  updatedAt?: Time;
  enable?: boolean;
  configSource?: 'default' | 'windows';
};

type GeoIp = {
  _version: Version;
  updatedAt: Time;
  city: string | null; // "Bucharest",
  wifiIsoCode: string | null; // "RO",
  country: string | null; // "Romania",
  latitude: number;
  longitude: number;
  timezone: string | null; // "Europe/Bucharest",
  ISP: string | null; // "Digi Romania",
  zip: string | null;
  ip?: string | null;
};

export type LocationSelection = {
  id: LocationId; // "610ba40ed577437faf2be78e",
  name: string; // "Home",
  coAdmin?: boolean;
};

export type ConfigAndStateBackhaul = {
  wpaKey: {
    value: string;
  };
  mode: string;
  dynamicBeacon: string;
  wds: string;
  wdsRealized?: string;
  wpaMode: string;
  wpaModeRealized?: string;
  createdAt?: string;
  updatedAt?: string;
  encrypted?: boolean;
  encryptedAt?: string;
  enable?: boolean;
  dynamicBeaconEnable?: boolean;
  wdsEnable?: boolean;
  hitlessTopology?: string;
  hitlessTopologyEnable?: boolean;
};

export type BandSteering = {
  _version: Version;
  auto: boolean;
  mode: Mode;
  updatedAt: Time;
  enable?: boolean;
};

export type Locale = {
  _version: Version;
  region: 'EU' | 'US';
};

export type Location = {
  id: LocationId; // "610ba40ed577437faf2be78e",
  accessedAsCoAdminLocation?: boolean; // coAdmin
  ipv6?: {
    mode: Mode;
    addressingConfig: 'auto';
    enable?: boolean;
    addressingRealized?: 'statefulDhcpv6';
    updatedAt?: Time; // "2021-09-14T09:20:07.039Z",
    wan?: Record<string, unknown>;
  };
  networkMode: 'auto' | 'router' | 'bridge';
  name: string; // "Home",
  accountId: AccountId;
  partnerId: string | null; // "mocha-partner",
  groupIds: string[]; // [ "583c96f428c4ea8a35601930",    "591a3bc5ce47db27e0ae90a9"     ],
  serviceId: string; // "b303e038-1d7d-4bc1-b9b1-180a5f76110b",
  profile: 'auto' | 'smallbusiness';
  mDNSUniqueIdentifier: string; // "39c7c41f-dbff-42a0-b327-2a0770eb1f27",
  freezeTemplates: FreezeTemplate[];
  serviceLevel: {
    status: 'fullService'; // "fullService",
    createdAt?: Time; // "2022-08-11T11:40:26.466Z",
    updatedAt?: Time; // "2022-08-11T11:52:23.267Z",
    auto: boolean;
  };
  homeAwayActive?: boolean;
  appTime?: boolean;
  createdAt: Time; // "2021-08-05T08:40:46.330Z",
  updatedAt?: Time; // "2022-10-19T13:15:30.609Z",
  stepFunctionTimers: [];
  _version: Version; // "1.0.0",
  groupOfUnassignedDevicesFreezeTemplates: [];
  groupOfUnassignedDevicesFreezeSchedules: [];
  isUtilizingSharedLocationFreezeSchedules: boolean;
  uprise: boolean;
  flex: boolean;
  customerId: CustomerId;
  ispSpeedTestConfiguration: {
    _version: Version; // "1.0.1",
    enable: boolean;
    enableAllNodes: boolean;
    createdAt: Time;
    updatedAt?: Time;
  };
  bandSteering: BandSteering;
  clientSteering: ClientSteering;
  controlMode:
    | 'full'
    | {
        mode: 'full';
        updatedAt: Time;
      };
  monitorMode: {
    _version: Version;
    enable: boolean;
    updatedAt: Time;
  };
  backhaul: {
    _version: Version;
    mode: Mode;
    dynamicBeacon: 'auto';
    wds: 'auto';
    wpaMode: 'auto' | 'psk2';
    createdAt: Time;
    updatedAt: Time; // "2021-08-05T08:40:46.329Z",
    encrypted: boolean;
    encryptedAt: Time; // "2022-10-12T08:49:56.634Z",
    enable?: boolean;
    dynamicBeaconEnable?: boolean;
    wdsEnable?: boolean;
  };
  wifiMotion?: {
    auto: boolean;
    createdAt: Time; // "2022-09-27T09:21:54.762Z"
  };
  geoIp?: GeoIp;
  // {
  //   country: "Slovenia",
  //   city: "Ljubljana",
  //   latitude: 46.0517,
  //   longitude: 14.5133,
  //   timezone: "Europe/Ljubljana",
  //   ISP: "Starlink",
  //   _version: Version,
  //   updatedAt: Time, // "2022-09-30T03:11:16.313Z",
  //   wifiIsoCode: "SI",
  //   zip: "1501"
  // },
  optimizations: {
    _version: Version;
    auto: boolean;
    enable: boolean;
    dfsMode: 'auto';
    prefer160MhzMode: 'auto';
    hopPenalty: 'auto';
    preCACScheduler: 'auto' | 'enable';
    updatedAt: Time; // "2022-06-17T08:43:59.390Z",
    zeroWaitDfsMode: 'auto';
    prefer160MhzModeRealized?: 'auto' | 'enable';
  };
  locale?: Locale;
  dppConfiguration: {
    mode: Mode;
    enrollments: [];
    modeRealized: 'disable';
  };
  haahsConfiguration: {
    mode: Mode;
    subscription: {
      status: 'Active' | 'Not supported';
      createdAt: Time; // "2021-08-05T08:40:46.330Z"
    };
  };
  onboardedAt?: Time; // "2021-08-05T08:41:55.571Z",
  featureConfigIds: { id: string; priority: Priority }[];
  securityConfiguration?: {
    preferredIntelligence: 'auto';
    wcHealthTelemetry: 'auto';
    wcHeroTelemetry: 'auto';
    ipThreatProtect: 'auto';
    dpiContentFiltering: 'auto';
    inlineDpi: 'auto';
    ipThreatProvider: 'auto';
    ipThreatIntelligence: 'gatekeeper';
    dosProtection: {
      mode: Mode;
      action: 'auto';
    };
    updatedAt: Time; // "2022-03-09T17:54:55.216Z"
  };
  partnerName: string | null;
  optimization: Optimization | Record<string, unknown>;
  capabilities?: {
    networkConfiguration: {
      networkMode: boolean;
      upnp: boolean;
      dnsServers: boolean;
      dhcpReservations: boolean;
      portForward: boolean;
      dhcp: boolean;
      fastTransition: boolean;
      minWifiMode24: boolean;
      igmpSnooping: boolean;
      igmpProxy: boolean;
      mldProxy: boolean;
      multicastToUnicast: boolean;
      mapT?: boolean;
      persistConfigurationOnGateway?: boolean;
      vlanServices?: boolean;
      mdns?: boolean;
      igmpQuerier?: boolean;
      mldSnooping?: boolean;
      mldQuerier?: boolean;
    };
    ispSpeedTest: boolean;
    isRadioDisableSupported: boolean;
    nodeIspSpeedTest: boolean;
    bandSteering: boolean;
    backhaul: {
      dynamicBeacon: boolean;
      wds: boolean;
    };
    ipv6: {
      mode: boolean;
      addressingTypes: ['slaac', 'statelessDhcpv6', 'statefulDhcpv6', 'all'];
    };
    clientSteering: boolean;
    deviceFreeze: boolean;
    multiPasswordSSID: boolean;
    wifiMotion: boolean;
    kvConfig: boolean;
    onboardNetwork: boolean;
    security: {
      dnsCategoryBlocking: boolean;
      anomaly: boolean;
      ipThreatProviders: ['norton', 'plume'];
    };
    version: '1.0.4';
    wps: boolean;
    controlMode: {
      full: boolean;
      monitor: boolean;
      reduced: boolean;
    };
    appMetadata: boolean;
    macPrioritization: boolean;
    dpp: boolean;
    wifi: {
      wpaModes: ['psk-mixed', 'sae-mixed', 'sae', 'psk2', 'psk', 'open'];
    };
    haahs: boolean;
    zeroWaitDfs: boolean;
  };
  fastTransition?: boolean;
  groupRekey?: boolean;
  minWifiMode24?: '11b';
  networkModeRealized?: 'router' | 'bridge';
  dhcp?: {
    subnet: string;
    subnetMask: string;
    startIp: string;
    endIp: string;
    version: Version;
  };
  upstreamDnsServers?: {
    primaryDns: string; // "193.189.160.23",
    secondaryDns: string;
  };
  regulatoryCompliance?: {
    region: string; // "EU",
    version: Version;
  };
  firmwareUpgrade?: {
    batchFirmwareUpgradeId?: string; // "624ed0b5c656ed764f4e37ac",
    updatedAt: Time; // "2022-04-07T11:53:25.989Z"
  };
  targetTopology?: {
    id: string; // "HC849001F5",
    ipv6: [];
    wifiConfig: {
      freqBand: FreqBand;
      channel: Channel;
      channelWidth: 20 | 80 | 160;
      parentId: string;
      devices: [];
      vaps: [];
    }[];
    ethernetDevices: [];
    mocaDevices: [];
  }[];
  speedTest?: PodSpeedTest;
  igmpSnooping?: boolean;
  dfsMode?: 'usDfs';
  version?: Version;
  multicast?: {
    igmpSnooping: boolean;
    igmpProxy: 'igmpv2' | 'disable';
    mldProxy: 'mldv2' | 'disable';
    multicastToUnicast: boolean;
  };
  zeroWaitDfsMode?: 'disable';
  ethernetLan?: {
    enable: boolean;
  };
  prefer160MhzMode?: 'enable';
  upnp?: boolean;
  persistConfigurationOnGateway?: boolean;
  dpp?: boolean;
  vlanServices?: [
    {
      name: 'iptv';
      enable: boolean;
      serviceNetworkMode?: 'routed';
    }
  ];
  security?: {
    ipThreatProvider: 'plume';
    dpiContentFiltering: boolean;
    ipThreatEnable: boolean;
    contentFilters: ('adBlocking' | 'spamPhishMalware')[];
    inlineDpi: boolean;
    intelligence: 'gatekeeper';
    ipThreatIntelligence: 'gatekeeper';
    wcHealthTelemetry: boolean;
    wcHeroTelemetry: boolean;
  };
  haahs?: {
    vapEnabled: boolean;
  };
  flowCollection?: {
    lanStats: boolean;
    interfaceStats: boolean;
    iotDeviceIpStats: boolean;
    screenDeviceIpStats: boolean;
  };
  pcsMode?: 'enable' | 'disable';
  lowPowerMode?: Record<string, unknown>;
  busy?: boolean;
  blacklistedLinks?: [];
  ssid: string | null; // "lte test"
  summary: {
    gatewayConnectionState: ConnectionState;
    nodeConnectedCount: number;
    nodeClaimedCount: number;
    connectedDeviceCount: number;
    _version: Version;
    gatewayConnectionStateChangeAt?: Time;
    wifiNetworkProvisioned: boolean;
    onboardingStatus: 'OnboardingComplete' | 'unknown';
    monitorMode: boolean;
    onboardedAt?: Time;
    poorHealthNodeCount: number;
    poorHealthDeviceCount: number;
    hasResidentialGateway: boolean;
    networkModeProvisioned: 'auto';
    networkModeRealized?: 'router' | 'bridge';
    upstreamDnsServers: {
      primaryDns?: string;
      secondaryDns?: string;
      enabled: boolean;
    };
    geoIp?: {
      _version: Version;
      updatedAt: Time;
      city: string | null; // "Bucharest",
      wifiIsoCode: string | null; // "RO",
      country: string | null; // "Romania",
      latitude: number;
      longitude: number;
      timezone: string | null; // "Europe/Bucharest",
      ISP: string | null; // "Digi Romania",
      zip: string | null;
      ip: string | null;
    };
  };
};

export type EntitledAccess = {
  accessId: string;
  accessType: 'admin';
  createdAt: Time;
  email: string;
  location: { id: string; name: string };
  name: string;
  ownerId: string;
  status: 'pending' | 'active';
};

export type atWorkEvent = {
  personId: string;
  devices: Mac[];
  atHome: boolean;
  atHomeChangedAt: Time;
};

export type serviceLinking = {
  active: boolean;
  providerId: string;
  linkedEmployeeId: string;
  lastUpdatedOn: Time;
};

export type Person = {
  id: string;
  nickname: string;
  imageId: string | 'PROFILE_MAN';
  primaryDevice: Mac | null;
  homeAwayNotification: boolean;
  appTime: boolean;
  permission: {
    accessType: string; // "readonly" | "owner",
    status?: string; // "active",
    createdAt?: Time; // "2022-02-17T07:41:34.315Z",
  };
  serviceLinking?: serviceLinking;
  email?: string; // "dvrecer+readonly3392@plume.com",
  createdAt: Time; // "2022-02-17T07:41:34.008Z",
  locationId: LocationId; //"610ba40ed577437faf2be78e",
  freeze: Freeze;
  connectionState: ConnectionState;
  connectionStateChangeAt: Time | null;
  atHome?: boolean;
  atHomeChangedAt?: Time;
  securityPolicy?: SecurityPolicy;
};

export type BandwidthConsumption = {
  download: number; //1.5,
  upload: number; //3.92,
  downloadUnits: BandwidthUnits;
  uploadUnits: BandwidthUnits;
};

export type SpeedTestDevice = BandwidthConsumption & {
  mac: Mac; // "56:8a:ff:ac:a9:0b",
  name: string; // "David-Mobile",
  icon: string; // "smartphone-android",
  iconV2: string; // "smartphone-samsung-galaxy"
  iconV3?: string; // "smartphone-samsung-galaxy"
};

export type InternetSpeed = {
  timestamp: Unixtime; // 1666245600000,
  download: number | null;
  upload: number | null;
  manual?: boolean;
};

export type SpeedTestGraphData = {
  chart: InternetSpeed[];
  hasData: boolean;
  average?: {
    download: number | null;
    upload: number | null;
    resultCount: number;
  };
  devices: {
    totalConsumption: BandwidthConsumption;
    mostActive: SpeedTestDevice[];
  };
};

export type AutoRun = {
  _version: string; // "1.0.1",
  enable: boolean;
  enableAllNodes: boolean;
  createdAt: Time; // "2021-08-05T08:40:46.330Z"
  updatedAt?: Time;
};

export type SpeedTestResults = {
  dashboard: {
    autoRun: AutoRun;
    isp: string; // "Starlink",
    mostRecent: InternetSpeed & { speedLevel: 'blazing' };
    hasBeenOptimized: boolean;
    optimization: Optimization;
  };
  day: SpeedTestGraphData;
  week: SpeedTestGraphData;
  month: SpeedTestGraphData;
  year?: SpeedTestGraphData;
};

export type FronthaulNetwork = {
  networkId: 'employee';
  vapType: 'fronthaul';
  enable: boolean;
  encryptionKey: string;
  ssid: string;
  accessZone: string;
  wpaMode: 'open' | 'psk2';
  ssidBroadcast: boolean;
};

export type CaptivePortalNetwork = {
  networkId: 'guest';
  vapType: 'captivePortal';
  enable: boolean;
  ssid: string;
  wpaMode: 'open' | 'psk2';
  bandwidthLimit: BandwidthLimit;
  encryptionKey?: string;
};

export type WifiNetwork = {
  encryptionKey: string;
  ssid: string;
  masterKeyIndex: number;
  encrypted: boolean;
  encryptedAt: Time;
  encryption?: string;
  wpaMode: 'open' | 'psk2';
  encryptionMode?: string;
  radiusServerIp?: string;
  radiusServerPort?: number;
  ssidBroadcast?: boolean;
  uapsd: boolean;
  enabled: boolean;
  disableDefaultServiceNetwork: boolean;
  groupRekey: string;
  fastTransition: string;
  minWifiMode24: string;
  privateMode: boolean;
  privateModeUpdatedAt: Time;
  devicesVisibleToGuests: [];
  defaultSecurityPolicy: {
    id: string;
    content: string[];
    iotProtect: boolean;
    iotProtectUpdatedAt?: Time;
    iotProtectReason?: string;
    websites: object;
    secureWebsites: object;
    createdAt?: Time;
    updatedAt?: Time;
  };
  content: string[] | null;
  appliesToAllDevicesSecurityPolicy: {
    id: string;
    content: string[];
    iotProtect: boolean;
    iotProtectUpdatedAt: Time;
    iotProtectReason?: string;
    websites: object;
    secureWebsites: object;
    createdAt?: Time;
    updatedAt?: Time;
  };
  createdAt: Time;
  updatedAt: Time;
  _version: Version;
  wpaModeDeferredExpiresAt: string | null;
  id: string;
  locationId: string;
  keys: [
    {
      id: string;
      encryptionKey: string;
      accessZone: string;
      accessZoneId: number;
      format: string;
      enable: boolean;
      content: string[] | null;
      createdAt: Time;
      updatedAt: Time;
      expiresAt?: Time;
      _version: Version;
    }
  ];
  persons: Person[];
  devices: SpeedTestDevice[];
  accessZones: [];
};

export type NetworkAccessData = {
  networkId: NetworkType;
  purgatory: boolean;
  heaven: string[];
};

export type BandwidthLimit = {
  enabled: boolean;
  type: 'percentage' | 'absolute';
  upload: number;
  download: number;
};

export type LoginWithPasswordResponse = {
  id: Token;
  ttl: number; // 31536000,
  created: Time; // "2022-11-21T13:35:15.487Z",
  userId: CustomerId; //  "637b7935cc6389002419d979",
  expireAt: Time; // "2023-11-21T13:35:15.487Z",
  user: Customer;
};

export type CoAdminAccessTokenResponse = {
  id: Token;
  ttl: number; // 31536000,
  created: Time; // "2022-11-21T13:35:15.487Z",
  userId: CustomerId; //  "637b7935cc6389002419d979",
  expireAt: Time; // "2023-11-21T13:35:15.487Z",
  user: Customer;
};

export type LoginWithSSOResponse = {
  ssoUri: string; // URL
};

export type SSOCallbackResponse = {
  refreshToken: string;
  ttl: number;
  accountId: string;
  customerId: string;
  sessionToken: string;
};

export type EthernetLan = {
  enable?: boolean;
  default: {
    mode: Mode;
  };
};

export type PodSpeedTest = {
  download: number; // 340.357656
  downloadDuration: number; // 10.016
  duration: number; // 20.008
  endedAt: Time; // "2022-11-08T03:35:22.414Z"
  gateway: boolean;
  id: string; // "9fcd2bbb-564a-4ce4-b02a-82b10b8a7c11"
  isp: string; // "Starlink"
  rtt: number; // 46.443
  serverName: string; // "35092"
  startedAt: Time; // "2022-11-08T03:34:58.462Z"
  status: 'succeeded'; // "succeeded"
  trigger: 'scheduled'; // "scheduled"
  upload: number; // 31.573576
  uploadDuration: number; // 9.992
  testType?: string; // "OOKLA"
};

export type AppFacadeCustomerAndNodeList = {
  customer: Customer;
  nodes: Node[];
};

export type Node = {
  '2gChannel': Channel;
  '5glChannel': Channel;
  '5guChannel': Channel;
  alerts: [];
  backhaulType: 'ethernet';
  bluetoothMac: string;
  bootAt: Time;
  capabilities?: NodeCapabilities;
  certificates: {
    clientAfter: Time;
    clientBefore: Time;
    rootAfter: Time;
    rootBefore: Time;
  };
  claimedAt: Time;
  connectedDeviceCount: number;
  connectionState: ConnectionState;
  connectionStateChangeAt: Time;
  defaultName: string;
  ethernet1Mac: string;
  ethernetLan: {
    default: {
      mode: 'auto';
    };
  };
  firmwareVersion: string;
  health: {
    status: string;
    score: number;
    details: null;
  };
  id: string;
  ip: string;
  wanIp?: string;
  ipv6: string[];
  leafToRoot: [];
  linkStates: {
    ifName: string;
    duplex: 'full' | 'half';
    linkSpeed: number;
    isUplink: boolean;
    hasEthClient: boolean;
  }[];
  mac: string;
  manufacturerSerialNumber: string;
  model: string;
  networkMode: 'bridge' | 'router';
  nickname: string;
  openSyncVersion?: string;
  packId: string;
  partNumber: string;
  platformVersion: '1';
  publicIp: string;
  radioMac24: string;
  radioMac50L: string;
  radioMac50U: Time;
  residentialGateway: false;
  serialNumber: string;
  shipDate: string;
  speedTest: {
    id: string;
    startedAt: Time;
    endedAt: Time;
    gateway: boolean;
    status: 'succeeded';
    trigger: 'mobile' | 'scheduled';
    serverId?: number;
    serverIp?: string;
    serverName: string;
    serverHost?: string;
    isp: string;
    download: number;
    upload: number;
    rtt: number;
    downloadDuration: number;
    uploadDuration: number;
    duration: number;
    testType: 'OOKLA';
  };
  subscriptionRequired: boolean;
  vendorName?: string;
  vendor: {
    name: string;
    partNumber: string;
    manufacturer: string;
    factory: string;
    mfgDate: string;
  };
};
export type NodeCapabilities = {
  lte?: boolean;
  disablePorts?: boolean;
  captivePortalV2?: boolean;
};

export type RawPod = {
  alerts: string[];
  backhaulType: string; // "unknown"
  bluetoothMac: Mac;
  certificates: {
    clientAfter: Time;
    clientBefore: Time;
    rootAfter: Time;
    rootBefore: Time;
  };
  claimedAt: Time;
  connectedDeviceCount: number; // 0
  connectionState: ConnectionState;
  connectionStateChangeAt: Time | null;
  defaultName: string; // "ROOM 1"
  nickname?: string; // "Office"
  ethernet1Mac: Mac;
  ethernetLan: EthernetLan;
  firmwareVersion: string; // "4.4.0-61-g05606e-dev-debug"
  health: null;
  id: string;
  ipv6: [];
  leafToRoot: [];
  linkStates: [];
  mac: Mac;
  model: string; // "aa6a821a-7c1f-4849-8751-3c16ba7cc044"
  packId: string; // "aa6a821a-7c1f-4849-8751-3c16ba7cc044"
  partNumber: string; // "NIKOLA-2021-US"
  radioMac24: Mac;
  radioMac50L: Mac;
  radioMac50U: Mac;
  residentialGateway: boolean;
  serialNumber: string; // "HC849001F5"
  shipDate: Date; // "2021/07/15"
  speedTest: PodSpeedTest;
  subscriptionRequired: boolean;
  vendor: {
    name: string; // "Plume"
    partNumber: string; // "PP513Z"
    manufacturer: string; //"CIG"
    factory: string; //"Shanghai-1"
    mfgDate: string; //"2021/21"
  };
};

export type Pod = {
  id: string;
  connectionState: ConnectionState;
  defaultName: string;
  nickname?: string;
  model: string;
  firmwareVersion: string;
  mac: Mac;
  ethernet1Mac: Mac;
  radioMac24: Mac;
  radioMac50L: Mac;
  radioMac50U: Mac;
  serialNumber: string;
  healthStatus?: string;
  ethernetLan?: EthernetLan;
  capabilities?: NodeCapabilities;
};

export type Categories =
  | 'productivity'
  | 'networking'
  | 'messaging'
  | 'shopping'
  | 'email'
  | 'entertainment'
  | 'gaming'
  | 'social'
  | 'other'
  | 'news'
  | 'vpn'
  | 'health_fitness'
  | 'education'
  | 'file_sharing'
  | 'network_security';

type DataUsageCategories = {
  rxBytes: number;
  txBytes: number;
  category: Categories;
};

export type HourlyStats<T> = {
  localStartTime: Time;
  categories: T[];
};

export type DailyStats<T> = {
  localStartDate: Time;
  categories: T[];
};

export type HourlyAppStats<T> = {
  localStartTime: Time;
  apps: T[];
};

export type DailyAppStats<T> = {
  localStartDate: Time;
  apps: T[];
};
export type MonthlyAppStats<T> = {
  localStartMonth: string;
  apps: T[];
};

export type DataHourlyStats = HourlyStats<DataUsageCategories>;
export type DataDailyStats = DailyStats<DataUsageCategories>;

export type DataDailyToday = {
  hourlyStats: DataHourlyStats[];
  overallStats?: {
    localStartDate: Date;
    totalBytes: number;
    hourlyAvgTotalBytes: number;
  };
};

export type DataLast30Days = {
  dailyStats: DataDailyStats[];
  monthlyStats?: {
    totalBytes: number;
    dailyAvgTotalBytes: number;
  };
};

export type DataUsageCategoriesDaily = {
  dailyToday: DataDailyToday;
};

// export type DataUsageCategoriesWeekly = {
//   weekly: {
//     dailyStats?: {
//       localStartDate: Date,
//       totalBytes: number,
//       hourlyAvgTotalBytes: number,
//     }[],
//     hourlyStats: HourlyStats[]
//   }
// }

export type DataUsageCategoriesMonthly = {
  last30Days: DataLast30Days;
};

export type TimeCategories = {
  onlineSeconds: number;
  category: Categories;
};

export type AppOnlineTime = {
  appId: string;
  onlineSeconds: number;
  category?: string;
};

export type AppCountPerCategory = {
  appCount: number;
  category: Categories;
};

export type TimeHourlyStats = HourlyStats<TimeCategories>;
export type TimeDailyStats = DailyStats<TimeCategories>;

export type TimeHourlyAppStats = HourlyAppStats<AppOnlineTime>;
export type TimeDailyAppStats = DailyAppStats<AppOnlineTime>;

export type TimeMonthlyAppStats = MonthlyAppStats<AppOnlineTime>;

// export type OnlineTimeWeeklyStats = {
//   localStartDate: Date,
//   hourlyAvgOnlineMinutes: number,
//   totalOnlineMinutes: number,
// }

export type TimeDailyToday = {
  hourlyStats: TimeHourlyStats[];
  overallStats?: {
    localStartDate: Date;
    totalOnlineMinutes: number;
    hourlyAvgOnlineMinutes: number;
  };
};

export type TimeCategoriesDaily = {
  dailyToday: TimeDailyToday;
};

export type OnlineTimeAppDailyToday = {
  hourlyStats: TimeHourlyAppStats[];
  overallStats?: {
    localStartDate: Date;
    totalOnlineMinutes: number;
    hourlyAvgOnlineMinutes: number;
  };
};

export type OnlineTimeAppDaily = {
  dailyToday: OnlineTimeAppDailyToday;
};

// export type OnlineTimeCategoriesWeekly = {
//   weekly: {
//     hourlyStats: TimeHourlyStats[],
//     dailyStats: OnlineTimeWeeklyStats[],
//   },
// }

export type TimeLast30Days = {
  dailyStats: TimeDailyStats[];
  monthlyStats: {
    totalOnlineMinutes: number;
    dailyAvgOnlineMinutes: number;
  };
};

export type TimeCategoriesMonthly = {
  last30Days: TimeLast30Days;
};

export type OnlineTimeAppLast30Days = {
  dailyStats: TimeDailyAppStats[];
  monthlyStats: {
    totalOnlineMinutes: number;
    dailyAvgOnlineMinutes: number;
  };
};

export type OnlineTimeAppMonthly = {
  last30Days: OnlineTimeAppLast30Days;
};

export type OnlineTimeAppLast12Months = {
  monthlyStats: TimeMonthlyAppStats[];
  recentLocalDate: string;
  yearlyStats: {
    totalOnlineMinutes: number;
    monthlyAvgOnlineMinutes: number;
  };
};

//Last12Months

export type OnlineTimeAppYearly = {
  last12Months: OnlineTimeAppLast12Months;
};

export type Guest = {
  socialNetworkId: string; // "8a:c8:2a:66:b9:48",
  fullName?: string; // "Free WiFi User",
  mac: string; // "8a:c8:2a:66:b9:48",
  loginType?: string; // "Free WiFi",
  profilePhoto?: string; // "https://captiveportal-staging.plume.me/cdn/assets/images/no_photo.png",
  thirtyDayVisitCount: number;
  deviceType: {
    category?: DeviceCategory;
    name: string;
  };
  connectionState: ConnectionState;
  connectionStateChangeAt: Time;
  txBytes?: number;
  rxBytes?: number;
};

type DeviceCategories = {
  category: string;
  connectedCount: number;
  rxBytes: number;
  txBytes: number;
};

type GuestVisits = {
  localStartTime: Time;
  startTime: Time;
  visitCount: number;
  is_repeat?: number;
};

export type GuestNetworkUsageHourStats = {
  connectedDevices: {
    deviceCount: number;
    localStartTime: Time;
    startTime: Time;
  }[];
  newGuestVisits: GuestVisits[];
  repeatGuestVisits: GuestVisits[];
};

type GuestSessionStats = {
  avgSessionLength: number;
  avgSessionTxRxBytes: number;
  deviceCount: number;
  guestCount: number;
};

export type GuestNetworkUsage = {
  deviceCategories: DeviceCategories[];
  hourlyStats: GuestNetworkUsageHourStats;
  sessionStats: GuestSessionStats;
};

export type Whitelist = {
  createdAt: Time; // "2021-08-05T08:40:46.330Z"
  direction: string; // bidirectional
  type: string; // fqdn
  value: string; // "www.plume.com"
};

export type Blacklist = {
  createdAt: Time; // "2021-08-05T08:40:46.330Z"
  direction: string; // bidirectional
  type: string; // fqdn
  value: string; // "www.plume.com"
};

export type AppliesToAllDevices = {
  adBlocking: Maybe<boolean>;
  adultAndSensitive?: boolean;
  iotProtect: Maybe<boolean>;
  kids?: boolean;
  secureAndProtect: Maybe<boolean>;
  teenagers?: boolean;
  websitesBlacklist?: boolean;
  websitesWhitelist?: boolean;
  workAppropriate: Maybe<boolean>;
  appTime: Maybe<boolean>;
};

export type AppliesToAllDevicesLabel =
  | 'adBlocking'
  | 'adultAndSensitive'
  | 'iotProtect'
  | 'kids'
  | 'secureAndProtect'
  | 'teenagers'
  | 'websitesBlacklist'
  | 'websitesWhitelist'
  | 'workAppropriate';

export type SecurityPolicy = {
  anomalyBlacklist: string[]; // ["Anything"]
  anomalyWhitelist: string[]; // ["Anything"]
  appliesToAllDevices: AppliesToAllDevices;
  content: SecurityContentTypes[]; // ["content"]
  createdAt: Time; // "2021-08-05T08:40:46.330Z"
  iotProtectUpdatedAt: Time; // "2021-08-05T08:40:46.330Z"
  iotProtect: boolean;
  secureAndProtect: boolean;
  websites: {
    autoBlocked: string[];
    blacklist: string[];
    blacklisted: Blacklist[];
    whitelist: string[]; // ["www.plume.com"]
    whitelisted: Whitelist[];
  };
};

export type LocationAppTime = {
  enable: boolean;
  enableRealized: boolean;
  appliesToAllDevices: boolean;
};

export type WebsiteType = 'fqdn' | 'ipv4' | 'ipv6' | null;

export type Website = {
  value: string;
  type: WebsiteType;
};

export type DeviceSecurityPolicy = {
  mac: Mac;
  securityPolicy: SecurityPolicy;
};

export type PersonSecurityPolicy = {
  content: SecurityContentTypes[]; // ["content"]
  createdAt: Time; // "2021-08-05T08:40:46.330Z"
  iotProtectUpdatedAt: Time; // "2021-08-05T08:40:46.330Z"
  iotProtect: boolean;
  secureAndProtect: boolean;
  websites: {
    autoBlocked: string[];
    blacklist: string[];
    blacklisted: string[];
    whitelist: string[]; // ["www.plume.com"]
    whitelisted: Whitelist[];
  };
};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type EventData = {
  url: string | null;
  mac: Mac;
  sourceCategoryId: number;
  source: 'gatekeeper';
  protectionType: 'ihp';
  queryType: 'SNI' | 'DNS';
  eventCount: number;
  topLevelDomain?: string;
  fqdn?: string;
  ipaddress?: string;
  categoryId?: number;
};

export type SecureAndProtectEventData = {
  ipaddress: string;
  city?: string | null;
  country?: string | null;
  latitude?: string;
  longitude?: string;
  mac: Mac;
  sourceCategoryId: number;
  categoryId?: number;
  source: 'gatekeeper';
  protectionType: 'ihp';
  protocol: number;
  port?: number;
  topLevelDomain?: string;
  fqdn?: string;
};

export type SecurityEventType =
  | 'IoTProtect.blacklistedAnomaly'
  | 'IoTProtect.rolledbackAnomaly'
  | 'IoTProtect.whitelistedAnomaly'
  | 'IoTProtect.blacklistedAnomalyRollback'
  | 'adBlocking'
  | 'kids'
  | 'teenagers'
  | 'workAppropriate'
  | 'adultAndSensitive'
  | 'secureAndProtect'
  | 'SecureAndProtect.outboundIpBlocked'
  | 'SecureAndProtect.inboundIpBlocked'
  | 'ipThreat'
  | 'anomaly';

export type SecurityEvent = {
  eventType: SecurityEventType;
  createdAt: Time;
  eventData: EventData | SecureAndProtectEventData;
};

export type SecurityEventHandling = 'Approved' | 'Blocked' | 'Protected';

export const Events: Record<SecurityEventHandling, SecurityEventHandling> = {
  Approved: 'Approved',
  Blocked: 'Blocked',
  Protected: 'Protected',
};

export type Topology = {
  vertices: TopologyVertice[];
  edges: TopologyEdge[];
};

export type PubnubSubscribe = {
  authKey: string;
  subscribeKey: string;
};

export type PubnubConfiguration = {
  locationId: string;
  pubNub?: PubnubSubscribe;
};

export type TopologyVertice = TopologyPod | TopologyDevice;

export type TopologyEdge = {
  source: string;
  target: string;
  direction: string;
  medium: string;
  metadata: {
    parentVapType?: string;
    freqBand?: string;
    channel?: number;
    channelWidth?: number;
  };
};

export type TopologyPod = {
  connectionState: 'connected' | 'disconnected' | 'unavailable';
  health: { status: string };
  alerts: [];
  type: 'pod';
  label: string;
  metadata: {
    networkMode: string;
    backhaulType: string;
    connectedDeviceCount: number;
    firmwareVersion: string;
    platformVersion: string;
    model: string;
    serialNumber: string;
    mac: string;
    radio24?: { channel: number; vaps: string[] };
    radio50L?: { channel: number; vaps: string[] };
    radio50U?: { channel: number; vaps: string[] };
  };
  id: string;
};

export type TopologyDevice = {
  connectionState: 'connected' | 'disconnected';
  health: { status: string };
  alerts: [];
  isPartnerComponent: boolean;
  id: string;
  type: 'device';
  label: string;
  metadata: {
    icon: string;
    iconV2: string;
    mac: string;
    vapType?: string;
    networkId: string;
  };
};

// Utility types
export type Nullable<T> = T | null;

export type Maybe<T> = T | undefined;

// guestEmailCollectionInfo
export type GuestEmailCollectionInfo = {
  downloadAvailable?: boolean; // TODO this is deprecated
  emailLoginAvailable: boolean;
  consentEnabledOrNotRequired: boolean;
  emailCollectionEnabled?: boolean;
  emailCount?: number;
};

// Customer Support Configuration
export type CustomerSupportConfiguration = {
  customerFeatureEnablement: {
    addPodEnabled?: boolean;
    autorunSpeedtestToggleEnabled?: boolean;
    buyPodEnabled?: boolean;
    callUsEnabled?: boolean;
    captivePortalGuestEmailDownloadDisabled?: boolean;
    chatSupportEnabled?: boolean;
    contactUsEnabled?: boolean;
    displayCustomLogoEnabled?: boolean;
    faqEnabled?: boolean;
    hideIspNameEnabled?: boolean;
    manageAccountEnabled?: boolean;
    manageYourDataEnabled?: boolean; // UI calls this - "yourPrivacyChoices"
    mobileCustomDeeplinkEnabled?: boolean; // needed for co-managers
    multilocationEnabled?: boolean;
    partnerAccountEnabled?: boolean;
    partnerBuyPodUrlEnabled?: boolean;
    privacyEnabled?: boolean;
    removeGatewayEnabled?: boolean;
    removePodEnabled?: boolean;
    termsEnabled?: boolean;
    workPassWebappDisabled?: boolean;
    employeeOnboardingCaptivePortalEnabled?: boolean;
    hideSpeedTestUiEnabled?: boolean;
    protectInsuranceEnabled?: boolean;
    nexusLoyaltyEnabled?: boolean;
    boostFinanceEnabled?: boolean;
    businessInfoEnabled?: boolean;
  };
  customerConfigurations: {
    privacyUrl?: string;
    termsUrl?: string;
    faqUrl?: string;
    plumeBuyPodUrl?: string;
    partnerBuyPodUrl?: string;
    contactUsEmail?: string;
    callUsPhoneNumber?: string;
    callUsDisplayPhoneNumber?: string;
    manageYourDataUrl?: string;
    plumeAccountUrl?: string; // Assumed string with tokens - "https://$userId:$authToken@$baseEnvironmentUrl/provisioning/oauth/authorize?client_id=plumeWebStore&response_type=code&redirect_uri=$redirectUrl/$locationId&userInfo"
    partnerAccountUrl?: string;
    chatSupportUrl?: string;
    mobileCustomDeeplinkUrl?: string;
    androidStoreApplicationUrl?: string;
    iosStoreApplicationUrl?: string;
    webappUrl?: string;
  };
  customerBrandingFiles: {
    webBrandingFiles: {
      logoResource?: string; // future use
    };
  };
  updatedAt?: string;
};

export type ExchangeTokenAccount = {
  id: 'string';
  organization: 'string';
  plume: {
    accessToken: 'string';
    customerId: 'string';
    locationId: 'string';
    accountId: 'string';
    residentialGatewayId: 'string';
    reason: 'string';
  };
};

export type ExchangeTokenResponse = { accounts: ExchangeTokenAccount[] };

export type ContextListItem<T extends Context> = {
  key: T;
  i18key: string;
};

export type ContextProps = ContextListItem<Context>;

export const CONTEXT_LIST: Record<Context, ContextProps> = {
  employees: {
    key: 'employees',
    i18key: 'context.employees',
  },
};

export type EmployeeLoginData = {
  locationId: string;
  partnerId: string;
  companyName: string;
  terms: {
    id: string;
    termsAndConditions: string;
    privacyPolicy: string;
    liabilityDisclaimer?: string;
  };
  requireConsent: boolean;
  enabled: boolean;
};

export type BlockedEmployeeData = {
  idpUserId: string;
  locationId: string;
  name: string;
  blockedAt: string;
};

export type AppTime = {
  appQoe: 'AUTO' | 'ENABLE' | 'DISABLE';
};

export type ModeStringType = {
  mode: string;
};

export type FeatureConfigIds = {
  id: string;
  priority: number;
};

export type NetworkAccessNetworksType = {
  networkId: 'default' | 'employee';
  purgatory: boolean;
  heaven: string[];
  captivePortal: boolean;
};

export type SecondaryNetworks = {
  config: string;
  dppConfigurator: null;
  enable: boolean;
  encryptionKey: {
    value: string;
  };
  fronthaulConfig: { accessZone: string };
  networkId: string;
  nodeEnablement: {
    excludedNodes: any[];
    gateways: boolean;
    includedNodes: any[];
    leafs: boolean;
    masterGateway: boolean;
  };
  ssid: string;
  ssidBroadcast: { value: boolean };
  wpaMode: { value: string };
  zone: any;
};

export type ConfigAndStateNodeType = {
  backhaulDhcpPoolIdx: {
    value: number;
  };
  certificates: Node['certificates'];
  ethernet1Mac: Node['ethernet1Mac'];
  ethernetLan: Node['ethernetLan'];
  ethernetMac: {
    value: string;
  };
  factoryResetCounter: any;
  id: Node['id'];
  kvConfigs: any[];
  macs: any[];
  model: {
    value: string;
  };
  nickname: Node['nickname'];
  radioMac24: {
    value: Node['radioMac24'];
  };
  radioMac50: any;
  radioMac50L: {
    value: Node['radioMac50L'];
  };
  radioMac60: any;
  residentialGateway: Node['residentialGateway'];
  synchronizedKey: any;
  vendor: {
    factory: {
      value: Node['vendor']['factory'];
    };
    manufacturer: {
      value: Node['vendor']['manufacturer'];
    };
    mfgDate: {
      value: Node['vendor']['mfgDate'];
    };
    name: {
      value: Node['vendor']['name'];
    };
    partNumber: {
      value: Node['vendor']['partNumber'];
    };
  };
};

export type SniffingType = {
  dns: 'AUTO' | 'ENABLE' | 'DISABLE';
  http: 'AUTO' | 'ENABLE' | 'DISABLE';
  upnp: 'AUTO' | 'ENABLE' | 'DISABLE';
  mdns: 'AUTO' | 'ENABLE' | 'DISABLE';
};

export type DNSType = {
  anomalyBlacklist: any[];
  anomalyWhitelist: any[];
  blacklist: any[];
  ipThreatBlacklist: any[];
  ipThreatWhitelist: any[];
  whitelist: any[];
};

export type ConfigAndStateMapTeType = {
  bmrEaLength?: number | { value: number } | null;
  bmrIpv4Prefix?: string | { value: string } | null;
  bmrIpv6Prefix?: string | { value: string } | null;
  bmrPsidOffset?: number | { value: number } | null;
  dmr?: string | { value: string } | null;
  enable: boolean | null;
  endUserPrefix: string | null;
  ifName?: string | { value: string } | null;
  mapIpv4Addr?: string | { value: string } | null;
  mapIpv6Addr?: string | { value: string } | null;
  mapLegacyDraft?: boolean | { value: boolean } | null;
  mapRulesDhcp?: boolean | { value: boolean } | null;
  mapRulesUrl?: string | { value: string } | null;
  mapType?: 'MAP_TYPE_E' | 'MAP_TYPE_T';
  portSets?: string | { value: string } | null;
  psid?: number | { value: number } | null;
  psidLen?: number | { value: number } | null;
  v6plusHgwStatus: string;
  v6plusRulesStatus: string;
};

export type PowerStateType = {
  enable: boolean;
  status:
    | 'PM_STATUS_UNKNOWN'
    | 'PM_STATUS_ACTIVE'
    | 'PM_STATUS_INACTIVE'
    | 'PM_STATUS_ACTIVATING'
    | 'PM_STATUS_DEACTIVATING';
  forcePowerManagementExpiresAt: null | { value: number };
  transitionTrigger:
    | 'PM_TRIGGER_UNKNOWN'
    | 'PM_TRIGGER_SCHEDULED'
    | 'PM_TRIGGER_CUSTOMER_FORCED'
    | 'PM_TRIGGER_SUPPORT_FORCED'
    | 'PM_TRIGGER_BANDWIDTH_THRESHOLD_EXCEEDED'
    | 'PM_TRIGGER_CONFIG_CHANGED'
    | 'PM_TRIGGER_NETWORK_ACTIVITY'
    | 'PM_TRIGGER_INTERFERENCE'
    | 'PM_TRIGGER_TOPOLOGY_FAILED'
    | 'PM_TRIGGER_EXPIRED'
    | 'PM_TRIGGER_TOPOLOGY_DEVIATED'
    | 'PM_TRIGGER_LINK_DISCOVERY'
    | 'PM_TRIGGER_LOCATION_OFFLINE';
  error: 'PM_NO_ERROR' | 'PM_ERROR_UNKNOWN';
};

export type ConfigAndStateType = {
  config: {
    appTime?: {
      appQoe: AppTime['appQoe'];
      deviceOverrides: any[];
      location: {
        enable: boolean;
      };
      sandboxSizeMb: any;
    };
    backhaul?: ConfigAndStateBackhaul;
    bandSteering?: {
      mode: string;
    };
    captivePortalClientAuths?: any;
    clientSteering?: {
      mode: string;
    };
    controlMode?: string | ModeStringType;
    customer?: {
      deviceNicknames: any;
    };
    deviceFreezeSchedules?: {
      schedules: any[];
    };
    devices?: {
      devices: any[];
    };
    dpp?: {
      enrollments: any[];
      mode: string;
    };
    featureConfigId?: {
      featureConfigIds: FeatureConfigIds[];
    };
    flex?: {
      employeePolicyId: any;
      enable: any;
      flexPolicyId: string;
      locationId: any;
      network: any;
      primaryTunnel: any;
      secondaryTunnel: any;
      wifi: any;
    };
    flowCache?: {
      enable: boolean;
    };
    flowCollection?: {
      interfaceStats: string;
      ipIotDeviceFlow: string;
      ipScreenDeviceFlow: string;
      lanIotDeviceFlow: string;
    };
    geoIp?: {
      ip: {
        value: string;
      };
      timezone: {
        value: string;
      };
    };
    haahs?: {
      mode: string;
      subscription: string;
    };
    ipv6?: {
      addressingConfig: string;
      dns: any;
      mode: string;
    };
    locale?: {
      region: {
        value: Locale['region'];
      };
    };
    locationId?: LocationId;
    lte?: any;
    mapTe?: {
      config: any;
      mode: string;
    };
    mdns?: {
      keyValuePairs: {};
      mode: string;
    };
    networkAccess?: {
      deviceGroups: any[];
      groupShares: any[];
      hell: any[];
      networks: NetworkAccessNetworksType[];
    };
    networkConfiguration?: {
      dhcp: any;
      dhcpReservations: any[];
      dnsServers: any;
      ethernetLan: any;
      mapT: any;
      multicast: any;
      natLoopback: any;
      persistConfigurationOnGateway: any;
      upnp: any;
    };
    networkMode?: {
      mode: string;
    };
    networks?: {
      secondaryNetworks: SecondaryNetworks[];
    };
    nodeLogging?: {
      forceDestination: string;
    };
    nodes?: {}[];
    optimization?: {
      dfsMode?: string | null;
      enable: {
        value: boolean;
      };
      hopPenalty: string;
      pcsMode: any;
      prefer160MhzMode?: string | null;
      zeroWaitDfsMode: string | null;
    };
    powerManagement?: {
      mode: string;
    };
    puncturing?: {
      mode: string;
    };
    ripv2?: {
      configs: any[];
    };
    samKnows?: {
      mode: string;
    };
    security?: {
      dpiContentFiltering: string;
      inboundIpThreat: string;
      inlineDpi: string;
      ipThreat: string;
      ipThreatIntelligence: string;
      ipThreatProvider: string;
      preferredIntelligence: string;
      wcHealthTelemetry: string;
      wcHeroTelemetry: string;
    };
    sipAlg?: {
      mode: string;
    };
    sniffing?: SniffingType;
    speedTest?: {
      enable: boolean;
      enableAllNodes: boolean;
    };
    stats?: {
      clientAuthFails: string;
      offChannelScan24: string;
      offChannelScan50: string;
      offChannelScan60: string;
    };
    thread?: {
      channel: any;
      channelMask: any;
      commissioningPsk: any;
      dataset: any;
      enable: boolean;
      extPanId: any;
      meshLocalPrefix: any;
      networkInterface: any;
      networkKey: any;
      networkName: any;
      panId: any;
      reportingInterval: number;
      threadInterface: string;
    };
    unii?: {
      unii4Mode: string;
    };
    wifiMotion?: {
      auto: boolean;
    };
    wifiNetwork?: {
      defaultSecurityPolicy: {
        content: string[];
        dns: DNSType;
        iotSecure: {
          enable: boolean;
        };
      };
      deviceGroups: {
        content: string[];
        dns: DNSType;
        id: string;
        iotSecure: {
          enable: boolean;
        };
        macs: string[];
      };
      devices: any[];
      devicesVisibleToGuests: any[];
      disableDefaultServiceNetwork: {
        value: boolean;
      };
      dppConfigurator: any;
      enabled: {
        value: boolean;
      };
      encryptionKey: {
        value: string;
      };
      fastTransition: string;
      groupRekey: string;
      keys: {
        accessZone: string;
        accessibleDevices: any[];
        content: any[];
        enable: boolean;
        encryptionKey: string;
        expiresAt: any;
        id: number;
      }[];
      masterKeyIndex: {
        value: number;
      };
      minWifiMode24: string;
      privateMode: {
        value: boolean;
      };
      radiusServerIp: any;
      radiusServerPort: any;
      ssid: string;
      ssidBroadcast: any;
      uapsd: {
        value: boolean;
      };
      wpaMode: string;
      wpaModeDeferredExpiresAt: {
        value: string;
      };
    };
  };
  state: {
    active?: boolean;
    appTime?: {
      appQoe: boolean;
    };
    capabilities?: {
      appPrioritization?: boolean;
      appQoe?: boolean;
      bandwidthThrottling?: boolean;
      captivePortal?: boolean;
      captivePortalFronthaul?: boolean;
      captivePortalV2?: boolean;
      flex?: boolean;
      fronthauls?: boolean;
      lte?: boolean;
      powerManagement?: boolean;
      samKnows?: boolean;
      sipAlg?: boolean;
      thread?: boolean;
    };
    flex?: {
      enable: boolean;
      primaryTunnel: any;
      secondaryTunnel: any;
      wifi: {
        primaryAccounting: any;
        primaryRadius: any;
        secondaryAccountings: any[];
        secondaryRadiuses: any[];
      };
    };
    initialization?: any;
    locationId?: string;
    lte?: {
      active: boolean;
      apn: any;
      disabledReason: string;
      enable: boolean;
      force: boolean;
      iccid: any;
      imei: any;
      linkState: string;
      nodeId: { value: string };
      persist: boolean;
      publicIp: any;
    };
    mapTe?: ConfigAndStateMapTeType;
    nodeState?: {
      capabilities: NodeCapabilities;
      isConnected: boolean;
      isGateway: boolean;
      isMasterGateway: boolean;
      isRouter: boolean;
      nodeId: string;
      wan: any[];
    }[];
    powerManagement?: PowerStateType;
    samKnows?: {
      enable: boolean;
    };
    sipAlg?: {
      enable: boolean;
    };
    stats?: {
      clientAuthFails: boolean;
      offChannelScan24: boolean;
      offChannelScan50: boolean;
      offChannelScan60: boolean;
    };
    thread?: any;
    unii?: {
      unii4Enable: boolean;
    };
  };
};

export type NetworkStatusType = 'connected' | 'disconnected';

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
