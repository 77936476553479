import StandardListItem from 'UI/Components/Lists/List standard';
import { IconNames } from 'UI/Elements/Icon';
import { IconShape } from 'UI/Elements/Icon/icons';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import * as selectors from 'State/selectors';
import { CSVLink } from 'react-csv';
import {
  prepareDataForCSV,
  prepareTimeSeriesDataForCSV,
} from './PrepareDataForCSV';
import {
  DataLast30Days,
  OnlineTimeAppLast12Months,
  SecurityEvent,
  TimeLast30Days,
  ZoneDevice,
  atWorkEvent,
} from 'Consts/types';

type DownloadEmployeeDataProps = {
  personId: string;
};

const DownloadEmployeeData: FunctionComponent<DownloadEmployeeDataProps> = ({
  personId,
}) => {
  const { t } = useTranslation();
  const { closeSidepanel } = useSidepanel();
  const [csvData, setCSVData] = useState([] as any);

  const DOWNLOAD_DATA = {
    assignedDevices: t('employeeZone.downloadData.assignedDevices'),
    dataUsage: t('employeeZone.downloadData.dataUsage'),
    onlineActivity: t('employeeZone.downloadData.onlineActivity'),
    atWork: t('employeeZone.downloadData.atWork'),
    appTime: t('employeeZone.downloadData.appTime'),
    events: t('employeeZone.downloadData.events'),
  };

  const downloadData: Record<
    string,
    | ZoneDevice[]
    | DataLast30Days
    | TimeLast30Days
    | SecurityEvent[]
    | atWorkEvent[]
    | OnlineTimeAppLast12Months
    | undefined
    | null
  > = {
    [DOWNLOAD_DATA.assignedDevices]: useSelector(selectors.zones.devices).data,
    [DOWNLOAD_DATA.dataUsage]: useSelector(selectors.dataUsage.person.monthly)
      .data?.[personId],
    [DOWNLOAD_DATA.onlineActivity]: useSelector(
      selectors.onlineTime.persons.monthly
    ).data?.[personId],
    [DOWNLOAD_DATA.events]: useSelector(selectors.employeeSecurityEvents).data,
    [DOWNLOAD_DATA.atWork]: useSelector(selectors.locationAtWorkEvents).data,
    [DOWNLOAD_DATA.appTime]: useSelector(
      selectors.appsOnlineTime.persons.last12months
    ).data,
  };

  const hasData = (dataItem: string) => {
    switch (dataItem) {
      case DOWNLOAD_DATA.assignedDevices:
        const deviceData = downloadData[dataItem] as ZoneDevice[];
        return deviceData.filter(
          (deviceData: any) => deviceData.personId === personId
        ).length > 0
          ? true
          : false;
      case DOWNLOAD_DATA.dataUsage:
        return (downloadData[dataItem] as DataLast30Days) &&
          (downloadData[dataItem] as DataLast30Days).dailyStats.length > 0
          ? true
          : false;
      case DOWNLOAD_DATA.onlineActivity:
        return (downloadData[dataItem] as TimeLast30Days) &&
          (downloadData[dataItem] as TimeLast30Days).dailyStats.length > 0
          ? true
          : false;
      case DOWNLOAD_DATA.events:
        return (downloadData[dataItem] as SecurityEvent[]) &&
          (downloadData[dataItem] as SecurityEvent[]).length > 0
          ? true
          : false;
      case DOWNLOAD_DATA.appTime:
        return (downloadData[dataItem] as OnlineTimeAppLast12Months) &&
          (downloadData[dataItem] as OnlineTimeAppLast12Months).monthlyStats
            .length > 0
          ? true
          : false;
      case DOWNLOAD_DATA.atWork:
        const atWorkData = downloadData[dataItem] as atWorkEvent[];
        return atWorkData.filter((person) => person.personId === personId)
          .length > 0
          ? true
          : false;
      default:
        return false;
    }
  };

  const handleDownloadData = (dataItem: string) => {
    let data:
      | ZoneDevice[]
      | DataLast30Days
      | TimeLast30Days
      | SecurityEvent[]
      | atWorkEvent[]
      | OnlineTimeAppLast12Months
      | string = '';

    switch (dataItem) {
      case DOWNLOAD_DATA.assignedDevices:
        const deviceData = downloadData[dataItem] as ZoneDevice[];
        data = prepareDataForCSV(
          deviceData.filter(
            (deviceData: any) => deviceData.personId === personId
          )
        );
        break;
      case DOWNLOAD_DATA.dataUsage:
        data = prepareTimeSeriesDataForCSV(
          downloadData[dataItem] as DataLast30Days,
          dataItem as 'Data usage'
        );
        break;
      case DOWNLOAD_DATA.onlineActivity:
        data = prepareTimeSeriesDataForCSV(
          downloadData[dataItem] as TimeLast30Days,
          dataItem as 'Online activity'
        );
        break;
      case DOWNLOAD_DATA.events:
        data = prepareDataForCSV(downloadData[dataItem]);
        break;
      case DOWNLOAD_DATA.appTime:
        data = prepareTimeSeriesDataForCSV(
          downloadData[dataItem] as OnlineTimeAppLast12Months,
          dataItem as 'App time'
        );
        break;
      case DOWNLOAD_DATA.atWork:
        const atWorkData = downloadData[dataItem] as atWorkEvent[];
        data = prepareDataForCSV(
          atWorkData.filter((person) => person.personId === personId)
        );
        break;
      default:
        data = [];
    }

    const preparedData = data.length ? data : t('common.noData');
    setCSVData(preparedData);
  };

  const downloadFileName = (dataItem: string) => {
    if (
      [DOWNLOAD_DATA.dataUsage, DOWNLOAD_DATA.onlineActivity].includes(dataItem)
    ) {
      return `${dataItem}-last30Days.csv`;
    } else {
      return `${dataItem}.csv`;
    }
  };

  const csvHeaders = useCallback(
    (dataItem: string) => {
      if (
        [DOWNLOAD_DATA.dataUsage, DOWNLOAD_DATA.onlineActivity].includes(
          dataItem
        )
      ) {
        return csvData === t('common.noData')
          ? undefined
          : [
              { label: 'Date', key: 'localStartDate' },
              { label: 'Categories', key: 'categories' },
            ];
      } else if (dataItem === DOWNLOAD_DATA.appTime) {
        return csvData === t('common.noData')
          ? undefined
          : [
              { label: 'Month', key: 'localStartMonth' },
              { label: 'Apps', key: 'apps' },
            ];
      } else {
        return undefined;
      }
    },
    [csvData]
  );

  return (
    <SimpleSidePanel
      onClose={() => closeSidepanel()}
      title={t('employeeZone.downloadEmployeeDataTitle')}
    >
      <SidePanelContentWrapper className={styles.downloadEmployeeDataContent}>
        {Object.values(DOWNLOAD_DATA).map(
          (dataItem, index) =>
            hasData(dataItem) && (
              <CSVLink
                data={csvData}
                filename={downloadFileName(dataItem)}
                target="_blank"
                style={{ textDecoration: 'none' }}
                className={styles.downloadEmployeeDataLink}
                onClick={() => handleDownloadData(dataItem)}
                headers={csvHeaders(dataItem)}
                key={`${dataItem}-${index}`}
              >
                <StandardListItem
                  className={styles.downloadEmployeeDataItem}
                  onClick={() => {}}
                  L2Props={{
                    label: dataItem,
                  }}
                  RProps={{
                    icon3Props: {
                      name: IconNames.ArrowDownStop,
                      shape: IconShape.square,
                    },
                  }}
                  ariaLabel={dataItem}
                />
              </CSVLink>
            )
        )}
        <StandardListItem
          className={styles.noData}
          L2Props={{
            label: t('employeeZone.downloadData.noDataRecordedYet'),
          }}
          ariaLabel={t('employeeZone.downloadData.noDataRecordedYet')}
        />
      </SidePanelContentWrapper>
    </SimpleSidePanel>
  );
};

export default DownloadEmployeeData;
